//hitNow 2.0 當日hit - Home

import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import {ContactPart, BirthdayContactPart, TodayHistory } from '../components';
import {  Content,Modal } from 'rsuite';

//function 
import loginCheck from '../function/loginCheck.js'//確認是否登入中
import getDay from '../function/getDay.js'//計算取得日期
import BirthdayContactPartSingleDay from '../function/birthdayContactPartSingleDay'
import TodayCounting from "../pages/todayCounting.js"
//Icon

import makeLog from '../function/makeLog.js'
import starIcon from '../icon/watermelon.svg'
import urlIcon from '../icon/url.svg'
import clickingIcon from '../icon/clicking.svg'
import NoteForA00 from '../pages/noteForA00'
const $=window.$
var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}


//Home
const Home = (props) => {
  const [showFlag,setShowFlag] = useState(false)
  function getTodayPic(){
    let picFlag=sessionStorage.getItem("picFlag")
    console.log("picFlag",picFlag)
    if (!picFlag){
      let token=sessionStorage.getItem('token')
      fetch(`${url}/getTodayPic?token=${token}`).then(res=>res.json()).then(pic=>{
        console.log(pic.length)
        if (pic.length > 0){
          sessionStorage.setItem("todayPic",pic[0].pic)
          sessionStorage.setItem("picFlag",true)
          setTodayPic(pic[0].pic)
          setShowFlag(true)
        }
       
      })
    }
  }
  //確認是否已登入
  let result = loginCheck();
  if (result !== 200) {
    window.location.href = "/login"
  }

  //取得已定聯Set 編碼為 orderID＋contactType
  
  let contactDate = JSON.parse(sessionStorage.getItem('contact'))
  const contactSet = new Set(Object.keys(contactDate))
  let clientData = JSON.parse(sessionStorage.getItem('client'))
  //let shouldHitDaily = JSON.parse(sessionStorage.getItem('shouldHitDaily'))
  let shouldHitDaily = JSON.parse(sessionStorage.getItem('todayShouldHitList'))

  //本日圖片

  const [todayPic,setTodayPic]=useState(null)

  // birthday
  const [birthdayList, setBirthdayList] = useState([])

  // follow 關心追蹤
  const [followList, setFollowList] = useState([])
  const [followCount, setFollowCount] = useState(0)
  // reservation 預約定聯
  const [reservationList, setReservationList] = useState([])
  const [reservationCount, setReservationCount] = useState(0)
  //  circles 超過周期
  const [cycleList, setCycleList] = useState([])
  const [cycleCount, setCycleCount] = useState(0)
  // refollow 再追踪
  const [notActiveList, setNotActiveList] = useState([])
  const [notActiveCount, setNotActiveCount] = useState(0)
  // refollow 再追踪
  const [refollowList, setRefollowList] = useState([])
  const [refollowCount, setRefollowCount] = useState(0)

  useEffect(() => {
    getTodayPic()
    let userID = sessionStorage.getItem('userID')
    makeLog(userID, "home")
    //生日名單
    let birthdayList = []
    let followList = []
    let fCount = 0
    let reservationList = []
    let rCount = 0
    let notActiveList = []
    let naCount = 0
    let cycleList = []
    let cCount = 0
    let refollowList = []
    let rfCount = 0
    let today = getDay(0, "-")
    let todayNew = getDay(0, "/")
    let todayForBirthday = todayNew.substr(5, 5)
    //console.log(clientData.length)
    clientData.map(item=>{
      if (item['birthdayMonth']+"/"+item['birthdayDay']==todayForBirthday){
        birthdayList.push(item)
        
      }
      
    })

    console.log('birthdayList',birthdayList)
    for (let x in shouldHitDaily) {
      console.log(shouldHitDaily[x])
      let clientID = shouldHitDaily[x].clientID
      let orderID= shouldHitDaily[x].orderID
      console.log(orderID)
      switch (shouldHitDaily[x].contactType) {
        case "Follow":
          followList.push(orderID)
          fCount+=1
          break;
        case "Cycle":
          cycleList.push(orderID)
          cCount+=1
          break;

        case "Reservation":
          reservationList.push(orderID)
          rCount+=1
          break;
        case "Refollow":
          refollowList.push(orderID)
          rfCount+=1
          break;
      }
    



    };




    setNotActiveList(notActiveList)
    setBirthdayList(birthdayList);
    setFollowList(followList);
    setReservationList(reservationList);
    setCycleList(cycleList)
    setRefollowList(refollowList)
    //計算總筆數
    setFollowCount(fCount)
    setReservationCount(rCount)
    setCycleCount(cCount)
    setNotActiveCount(naCount)
    setRefollowCount(rfCount)

    //setCloseList(closeList)
  }, [])

  let countDate = getDay(-210, "-")

  //預約元件
  function ReservationPart(props) {
    let unitID = sessionStorage.getItem('unitID')
    let userName = sessionStorage.getItem('name')
    let userID=sessionStorage.getItem('userID')
    let reservationUrl = `https://member.happyhair.com.tw/reservation?storeId=${unitID}&employeeId=${userID}`
    // `https://su.happyhitnow.com/${userName}/${unitID}/0`

    function getUrlToClipboard(){
      const el = document.createElement('textarea');
      el.value = reservationUrl;
      document.body.appendChild(el);
      el.select();
      const copyStatus = document.execCommand('copy');
      document.body.removeChild(el);
      var msg = copyStatus ? `${userName} 的專屬預約連結已複製` : 'failed';
      alert(msg)
    }
    if (unitID=="A00"){
      return null
    }



    return (
      <>

      <div className="p-2  my-2  rounded " onClick={getUrlToClipboard}>
             <h4 ><span className=" badge-pill badge alert-success"> <img src={urlIcon} style={{width:"20px"}} alt="PersonAdd"/>  專屬預約連結 </span> </h4>
             <li className="list-group-item font-weight-lighter p-1 mb-1  border-light  bg-light" tag="button"    >
             <div className="toast-body ">
                
           
                <ul className="list-group">
                <div clasName=" m-3  " id="urlPart"><span className="text-success ml-2">{reservationUrl}</span></div>
                 <small className="text-muted text-center"><img src={clickingIcon} style={{width:"20px"}} alt="PersonAdd"/> 按一下 Copy</small>
                 </ul>
         </div>
         </li>
        </div>

        {/* <div className="card m-2 ">
          <div className="card-body alert-info   border-1 p-2 ">我的專屬預約連結</div>
          <div className="card-body border-1" style={{ width: "100%" }}>
          <div clasName=" m-3 " ><span className="text-info">{reservationUrl}</span></div>

          
        </div>
        </div> */}



      </>
    )

  }

  //功能說明
  function Memo(props) {
    // let deleteCount=JSON.parse( sessionStorage.getItem('deleteOrder')).length //取得應hit數
    return (
      <>

      
        <div className="p-2  my-2  rounded ">
          <div className="toast-body ">
            <h4 ><span className=" text-muted badge-pill badge badge-light">指標說明</span> </h4>

            <div className="alert alert-info p-1 m-1" style={{ fontSize: "9px" }}><span className="ml-2">總客數：自 {countDate.substring(5, 12)} 來店 總客數 : 純洗客數</span></div>
            {/* 
     <div className="alert alert-warning p-1 m-1 " style={{fontSize:"9px"}}><span className="ml-2">H.I.T：{getWeek().substring(5, 12)} 當周Hit數 : {getMonFirstDate().substring(0, 7)} 月Hit數 : 已完成應Hit數 : 應Hit數</span></div>
     */}
            <div className="alert alert-warning p-1 m-1 " style={{ fontSize: "9px" }}><span className="ml-2"> {getMonFirstDate().substring(0, 7)} 月已完成應Hit數 : 應Hit數</span></div>
            <div className="alert alert-danger p-1 m-1" style={{ fontSize: "9px" }}><span className="ml-2">月客次： {getMonFirstDate().substring(5, 7)} 月來店 總客次 : 純洗客次</span></div>
            <div className="alert alert-success p-1 m-1" style={{ fontSize: "9px" }}><span className="ml-2 "><img src={starIcon} className="mr-1" style={{ width: "18px", height: "18px" }} alt="start" /> : 設計師新客戶</span></div>
            {/* <div className="alert alert-dark p-1 m-1" style={{ fontSize: "9px" }}><span className="ml-2 ">周期內刪除訂單數: {deleteCount}</span></div> */}
          </div>
        </div>

    
      </>
    )
  }


const PicShow=(props)=>{


  return <>
   
          {/* <Modal  show={showFlag} style={{"width": "95%","maxWidth":"500px","minWidth":"300px"}}  backdrop={showFlag}> */}
            <Modal  show={showFlag} classNam="modal-dialog" style={{ "width":"95%","height":"90%","width":"95%","height":"90%"}}  backdrop={showFlag} onHide={()=>{
              setShowFlag(false)
            }}> 
          <Modal.Header>
            <Modal.Title className="text-center">Happy in Touch, Now</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
            <img src={todayPic}  height="400" style={{"objectFit":"contain"}}/>
            </div>
         
          </Modal.Body>
          <Modal.Footer>
          <div className="btn alert-info "  style={{ "width": "100pt" }} onClick={() => {
           setShowFlag(false)
           //  return
           }}>進入 HitNow</div>
           
          </Modal.Footer>
        </Modal>
       
  </>
}

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date()
    let m = (today.getMonth() + 1).toString()
    let mon = ""
    let year = (today.getFullYear()).toString()

    if (m.length == 1) {
      mon = '0' + m;
    } else {
      mon = m
    }
    let result = year + "-" + mon + "-01"
    //console.log(result)
    return result
  }

  function getWeek() {
    //計算本周一的日期
    let today = new Date()
    let day = today.getDay()

    let result = 0
    switch (day) {
      case 0:
        result = -6
        break;
      default:
        result = (1 - day)
        break;


    }

    let newData = getDay(result, "-")
    return newData
  }

  return (
    <>

    <div  style={{"maxWidth":"450pt"}}>
      <TodayCounting />  <br /> <br /><br />
      {/* <NoteForA00/> */}
      <PicShow/>
      <ContactPart className="mt-3" title="關心追蹤" note="消費期限內關心" color="dark" bgColor="info" bar="25%" componentList={followList} count={followCount}  />
      <ContactPart className="mt-3" title="安排預約" note="下次消費預約" color="dark" bgColor="info" bar="50%" componentList={reservationList} count={reservationCount}  />
      <ContactPart className="mt-3" title="即將超過周期" note="即將超過消費周期" color="dark" bgColor="info" bar="75%" componentList={cycleList} count={cycleCount}  />
      <ContactPart className="mt-3" title="再次追蹤" note="超過周期再追踪" color="dark" bgColor="info" bar="75%" componentList={refollowList} count={refollowCount}  />
      <ContactPart className="mt-3" title="流失客" note="新客超過120天，舊客180天未消費" color="dark" bgColor="info" bar="100%" componentList={notActiveList} count={notActiveCount}  />
      {/* <BirthdayContactPart className="mt-3" componentList={birthdayList}/> */}
     
      <TodayHistory />
      <BirthdayContactPartSingleDay className="mt-3" componentList={birthdayList}/>
      <ReservationPart />
      <Memo />

      </div>

    </>
  );
}

export default Home;
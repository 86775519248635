import React ,{useState,useEffect}from 'react';
import { Link ,useParams} from 'react-router-dom';

//function
import getDay from './getDay.js'//計算日期
import loginCheck from './loginCheck.js'//loginCheck
import makeHitContact from './makeHitContact.js' //完成定聯Func
import makeLog from './makeLog.js' //製作Log
import makeOrderContactList from './makeOrderContactList.js'//演算客戶消費定單分類
import makeHistoryServiceList from './makeHistoryServiceList.js' //演算history 品項中文化
//icon
//import starIcon from './icon/star.svg'
import starIcon from '../icon/watermelon.svg'


//本日清單 More 項目
function MoreList(){
    let param=useParams()
    let moreType=param.moreType
    let clientData=JSON.parse(sessionStorage.getItem('client'))
    //取得已定聯Set 編碼為 orderID＋contactType
    let contactDate=JSON.parse(sessionStorage.getItem('contact'))
    const contactSet=new Set(Object.keys(contactDate))
    let componentList=[]
    let Testcount=0
    

    let componentTitle="" //定聯名稱
    switch (moreType){
        
        case "reservation":
            componentTitle="安排預約"
            break;
        case "circles":
            componentTitle="超過周期"
            break;
        case "notActive":
            componentTitle="流失客"
            break;
        case "follow":
        default:
            componentTitle="關心追蹤"
        break;
    }

    console.log(clientData.length)
    for (let x in clientData){
        Testcount+=1
        console.log(Testcount)
        console.log(moreType)
        console.log(clientData[x]['contactType'])
        if (clientData[x]['contactType']==moreType)
            
           if (contactSet.has(clientData[x]['orderID']+moreType)==false){
            
           
            componentList.push(x)
                
               }

        }

    
    
  
    
        
       
      

     let clientList=[]
    
    
      function Star(props){
        //新客戶標示
        if (props.newClient=="1"){
            return <img  className="ml-1" src={starIcon} style={{width:"18px",height:"18px"}}  alt="start"/>
        }else{
            return null
        }
        }
    
    
      function GenderIcon(props){
        if (props.gender=='女'){
          let genderDot = 
          <span class="  font-weight-lighter rounded-circle alert-danger "style={{fontSize:"80%"}} >{props.gender} </span>
          return genderDot
        }else if(props.gender=='男'){
          let genderDot = 
          <span class="  font-weight-lighter rounded-circle alert-info "style={{fontSize:"80%"}} >{props.gender} </span>
          return genderDot
        }else{
          return null
        }
      }
    
    
     for (let y in componentList){
      

        let x=String(componentList[y])
    
        let toMakeContact=`/contact/${clientData[x]['clientID']}`
        

     let singleContact=
     <>
     
     <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
            <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={x}   >
            <div >
              <div className="ml-2 "><span className=" text-dark" >{clientData[x]['name']} </span>  
              <span  className="m-1"><GenderIcon gender={clientData[x]['gender']}/>  </span>  
              <span className="text-muted  ml-1 font-weight-light" style={{fontSize:"smaller"}}> 消費日：{clientData[x]['lastDate'].substring(5)} </span>
              <Star newClient={clientData[x]['newClient']}/>
             
              </div>
              {/*
               <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
              */}
              <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
            </div>
          
             </li>
        </Link>
        </>
    
     clientList.push(singleContact)
     }
    
     if (clientList.length==0){
         return null
     }else{
        return (
            <>
    
            <div className="p-2  my-2  rounded ">
                <div className="toast-body ">
                <h4 ><span className=" badge-pill badge alert-dark">{componentTitle}   {componentList.length} 筆</span> </h4>
                        <ul className="list-group ">
                         {clientList}
                         </ul>
                 </div>
                  
            </div>
         
       </>
        )
            
     };
         
   
    };
   
     


export default MoreList;
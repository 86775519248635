import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

//function
import getDay from './function/getDay.js'//計算日期
import loginCheck from './function/loginCheck.js'//loginCheck
import makeHitContact from './function/makeHitContact.js' //完成定聯Func
import makeLog from './function/makeLog.js' //製作Log
import makeOrderContactList from './function/makeOrderContactList.js'//演算客戶消費定單分類
import makeHistoryServiceList from './function/makeHistoryServiceList.js' //演算history 品項中文化
import encodeDecode from './function/encodeDecode.js'//加解密
import manageUnitToDistrictList from './function/manageUnitToDistrictList.js' //整理分區清單

//icon
import targetIcon from './icon/target.svg'
import starIcon from './icon/watermelon.svg'
import checkIcon from './icon/check.svg'
import { setLogLevel } from 'firebase';
var url = ""
let env = process.env.NODE_ENV
if (env == "production") {
    //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
    url = "https://server.happyhitnow.com"
} else {
    url = "http://localhost:8080"
}



//本日統計元件
const TodayCounting = () => {

    let userID=sessionStorage.getItem('userID')
    let token=sessionStorage.getItem('token')
    const [shouldHit,setShouldHit]=useState(0)
    const [Hitcount,setHitcount]=useState(0)

    //取得應hit與hit數
    function getHitCount(){
        fetch(`${url}/countShouldAndHitAmountSingleUser?token=${token}&userID=${userID}`).then(res=>{
            return res.json()
        }).then(json=>{
            // console.log("getHitCount",json)
            setShouldHit(json.shouldAmount)
            setHitcount(json.hitAmount)
        })
    }

    

    function getMonFirstDate() {
        //計算本日第一天
        let today = new Date()
        let m = (today.getMonth() + 1).toString()
        let mon = ""
        let year = (today.getFullYear()).toString()

        if (m.length == 1) {
            mon = '0' + m;
        } else {
            mon = m
        }
        let result = year + "-" + mon + "-01"

        return result
    }

    function getWeek() {
        //計算本周一的日期
        let today = new Date()
        let day = today.getDay()

        let result = 0
        switch (day) {
            case 0:
                result = -6
                break;
            default:
                result = (1 - day)
                break;


        }

        let newData = getDay(result, "-")
        return newData
    }

    let clientData = JSON.parse(sessionStorage.getItem('client'))
    let contactData = JSON.parse(sessionStorage.getItem('contact'))
    let historyData = JSON.parse(sessionStorage.getItem('history'))



    let newData = getWeek()
    let newDatMon = getMonFirstDate()

    const clientCount = (Object.keys(clientData)).length
    let newClient = 0
    let TodayMonth = getDay(0, "").substring(4, 6)
    //let hsCount=0 //純洗客
    let hsCountMon = 0 //本月純洗客


    //以下為計算月客次
    let hsSet = new Set()//純洗客客數 （單人計一次）
    for (let x in historyData) {
        let serviceIDList = historyData[x]['serviceIDList'].split(",")
        if (historyData[x]['orderDate'].substring(4, 6) == TodayMonth) {
            newClient += 1
            if (serviceIDList.length == 1) {
                if (serviceIDList[0].substr(0, 2) == "HS") {
                    hsCountMon += 1
                }
            }
        }


        if (serviceIDList.length == 1) {
            if (serviceIDList[0].substr(0, 2) == "HS") {
                //hsCount+=1
                hsSet.add(historyData[x]['clientID'])
            }
        }
    }
    let newContactMon = 0 //本月定聯數
    let newContact = 0 //本周定聯數



    for (let x in contactData) {


        if (contactData[x]['createDate'] >= newData) {
            //console.log('yes')
            newContact += 1
        }

        if (contactData[x]['createDate'] >= newDatMon) {

            newContactMon += 1


        }


    }

    useEffect(()=>{
        getHitCount()
        // let shouldHitAmount=sessionStorage.getItem('shouldHitAmount')
        //  setShouldHit(shouldHitAmount)
    },[])
    return (
        <>
            <div className=" fixed-top" style={{ marginTop: "50px", backgroundColor: "rgba(255, 255, 255, 0.7)" }} >
                <div className="p-2 container ">
                    <div className="row">
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-info text-center  text-small" style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>總客數</span><span className=" badge-pill badge badge-info">{clientCount} : {hsSet.size}</span></div>
                        </div>
                        {/*
                             <div className="col"  align="center">
                                <div className=" rounded-circle alert-warning text-center  " style={{width:"50px",height:"50px"}}><span style={{fontSize:"12px"}}>H.I.T</span><span className=" badge-pill badge badge-warning ">{newContact} : {newContactMon} : {shouldHitContact} : {shouldHit}</span></div>
                            </div>
                            */}
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-warning text-center  " style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>H.I.T</span><span className=" badge-pill badge badge-warning ">  {Hitcount} : {shouldHit} </span></div>
                        </div>
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-danger text-center " style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>月客次</span><span className=" badge-pill badge badge-danger">{newClient} : {hsCountMon}</span></div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

};


//KitNow連絡記錄下載
const InitContact = (props) => {

    useEffect(() => {
        //設定 參數
        let userID = sessionStorage.getItem('userID');
        let token=sessionStorage.getItem('token')

        function contact() {
            //連絡記錄
            let token = sessionStorage.getItem('token')
            return new Promise((resolve, reject) => {
                //alert(`https://hitnow-v1.df.r.appspot.com/downloadContact?id=${id}`)
                fetch(`${url}/downloadContact?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    sessionStorage.setItem('contact', JSON.stringify(json));

                    resolve('contact ok')
                    //return home()
                }).catch(err => {
                    //console.log('客尸連絡記錄请求错误', err);

                })

            })

        };

        function getMyHit() {
            let token=sessionStorage.getItem('token')
            return new Promise((resolve, reject) => {
                //話題

                //fetch(`${url}/shouldHitDaily?token=${token}`, {
                fetch(`${url}/getMyHit?userID=${userID}&token=${token}`, {
                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    console.log('getMyhit', json);

                    sessionStorage.setItem('getMyHit', JSON.stringify(json));
                    // sessionStorage.setItem('shouldHitAmount', json.shouldHitAmount);
                    // sessionStorage.setItem('shouldHitList', JSON.stringify(json.shouldHitList));
                    sessionStorage.setItem('todayShouldHitList', JSON.stringify(json.todayShouldHitList));

                    resolve('getMyHit ok')
                    //return home()
                }).catch(err => {
                    //console.log('本日推薦请求错误', err);

                })

            })
        };





        Promise.all([contact(), getMyHit()]).then(values => {
            //console.log(values)
            //.return null;
            window.location.href = "/"
        }

        ).catch(reason => {
            window.location.href = "/fail"
        });;




    });

    return (

        <div style={{ textAlign: 'center', width: '100%', marginTop: '150px' }}>
            <h4 className="text-muted"><img src={starIcon} style={{ width: "22px", height: "22px" }} alt="start" />資料載入中...</h4>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    );
}

//初始化
const Init = (props) => {

    let result = loginCheck();
    if (result !== 200) {
        window.location.href = "./login"
    }

    useEffect(() => {
        //設定 參數
        let userID = sessionStorage.getItem('userID');
        let token = sessionStorage.getItem('token')
       
        let formData = new FormData()
        formData.append('userID', userID)

        //當日應Hit名單
        // function shouldHitDaily() {
        //     return new Promise((resolve, reject) => {
        //         //話題

        //         //fetch(`${url}/shouldHitDaily?token=${token}`, {
        //             fetch(`https://report.happyhitnow.com/singleDaily?userID=${userID}`, {
        //             mode: 'cors',
        //         }).then(res => {
        //             return res.json();
        //         }).then(json => {
        //             //console.log('获取的结果', json);

        //             sessionStorage.setItem('shouldHitDaily', JSON.stringify(json.result));
        //             sessionStorage.setItem('deleteOrder', JSON.stringify(json.deleteOrder));
        //             sessionStorage.setItem('hitCountMonth', JSON.stringify(json.hitCountMonth));

        //             resolve('shouldHit ok')
        //             //return home()
        //         }).catch(err => {
        //             //console.log('本日推薦请求错误', err);

        //         })

        //     })
        // };

        //當月Hit資料
        function getMyHit() {
         
            return new Promise((resolve, reject) => {
                //話題

                //fetch(`${url}/shouldHitDaily?token=${token}`, {
                fetch(`${url}/getMyHit?userID=${userID}&token=${token}`, {
                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    console.log('getMyhit', json);

                    sessionStorage.setItem('getMyHit', JSON.stringify(json));
                    // sessionStorage.setItem('shouldHitAmount', json.shouldHitAmount);
                    // sessionStorage.setItem('shouldHitList', JSON.stringify(json.shouldHitList));
                    sessionStorage.setItem('todayShouldHitList', JSON.stringify(json.todayShouldHitList));

                    resolve('getMyHit ok')
                    //return home()
                }).catch(err => {
                    //console.log('本日推薦请求错误', err);

                })

            })
        };

        

        function topic() {
            return new Promise((resolve, reject) => {
                //話題

                fetch(`${url}/topic?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    sessionStorage.setItem('topic', JSON.stringify(json));

                    resolve('topic ok')
                    //return home()
                }).catch(err => {
                    //console.log('本日推薦请求错误', err);

                })

            })
        };

        function unitList() {
            return new Promise((resolve, reject) => {
                //話題

                fetch(`${url}/unitList?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    sessionStorage.setItem('unitList', JSON.stringify(json));

                    resolve('unitList ok')
                    //return home()
                }).catch(err => {
                    //console.log('本日推薦请求错误', err);

                })

            })
        };


        function history() {
            return new Promise((resolve, reject) => {
                //話題

                fetch(`${url}/history?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    for (let x in json){
                        let newPhone = encodeDecode(json[x]['phone'], 'encode')
                        json[x]['phone'] = newPhone
                    }
                  
                    sessionStorage.setItem('history', JSON.stringify(json));

                    resolve('history ok')
                    //return home()
                }).catch(err => {
                    //console.log('本日推薦请求错误', err);

                })

            })
        };


        function client() {
            return new Promise((resolve, reject) => {
                //客戶資料
                //fetch(`https://asia-east2-hitnow-v1.cloudfunctions.net/clientHitNow?id=${id}`,{
                //let key =sessionStorage.getItem('hitnowkey')
                let userID = sessionStorage.getItem('userID')
                //fetch('https://hitnow-v1.df.r.appspot.com/clients?key=${key}',{

                fetch(`${url}/clients?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    //sessionStorage.setItem('client', JSON.stringify(json));

                    //手機加密 & 生日合併
                    for (let x in json) {
                        let newPhone = encodeDecode(json[x]['phone'], 'encode')
                        json[x]['phone'] = newPhone
                        json[x]['birthday'] = json[x]['birthdayMonth'] + "/" + json[x]['birthdayDay']
                        //let deCodePHone=encodeDecode(newPhone,'decode')
                        //json[x]['decodePhone']=deCodePHone
                    }

                    json.sort((a, b) => {
                        var nameA = a.name;
                        var nameB = b.name;
                        if (nameA < nameB) {
                            return -1;
                        }
                        if (nameA > nameB) {
                            return 1;
                        }
                        return 0;
                    });
                    sessionStorage.setItem('client', JSON.stringify(json));


                    resolve('ClientOk')
                    //return home()
                }).catch(err => {
                    //console.log('客戶資料请求错误', err);

                })

            })
        };

        function service() {
            return new Promise((resolve, reject) => {
                // 服務品項
                //fetch(`https://asia-east2-hitnow-v1.cloudfunctions.net/clientHitNow?id=${id}`,{
                //let key =sessionStorage.getItem('hitnowkey')
                let userID = sessionStorage.getItem('userID')
                //fetch('https://hitnow-v1.df.r.appspot.com/clients?key=${key}',{

                fetch(`${url}/service?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    sessionStorage.setItem('service', JSON.stringify(json));


                    resolve('ServiceOk')
                    //return home()
                }).catch(err => {
                    //console.log('客戶資料请求错误', err);

                })

            })
        };


        function contact() {
            //連絡記錄

            return new Promise((resolve, reject) => {

                fetch(`${url}/downloadContact?token=${token}`, {

                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                    //console.log('获取的结果', json);
                    sessionStorage.setItem('contact', JSON.stringify(json));

                    resolve('contact ok')
                    //return home()
                }).catch(err => {
                    //console.log('客尸連絡記錄请求错误', err);

                })

            })

        };


        let dataTime = Number(sessionStorage.getItem("timeCode"))
        let NowTime = new Date().getTime()
        console.log(dataTime, NowTime)



        Promise.all([client(), service(), topic(), contact(), history(), unitList(), getMyHit()]).then(values => {
            //演算history服務品項中文化
            Promise.all([makeHistoryServiceList(), manageUnitToDistrictList()]).then(values => {

                window.location.href = "/"

            }

            );
        }

        );




    });

    return (
        <>
            <div style={{ textAlign: 'center', width: '100%', marginTop: '150px' }}>
                <h4 className="text-muted"><img src={starIcon} style={{ width: "22px", height: "22px" }} alt="start" /> 資料載入中...</h4>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped bg-danger progress-bar-animated" role="progressbar" style={{ width: '100%' }} aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                </div>
            </div>
        </>
    );
}


//定聯顯示元件
const ContactPart = (props) => {
    let today = getDay(0, "-")
    let moreType = props.moreType
    let componentTitle = props.title //定聯名稱
    let count = props.count
    console.log(componentTitle, count, "componentList:",props.componentList.length)
    let componentList = props.componentList //清單
    //取得已定聯Set 編碼為 orderID＋contactType
    let contactDate = JSON.parse(sessionStorage.getItem('contact'))
    const contactSet = new Set(Object.keys(contactDate))

    let clientData = JSON.parse(sessionStorage.getItem('historyChinese'))
    let clientList = []


    function Star(props) {
        //新客戶標示
        if (props.newClient == "1") {
            return <img className="ml-1" src={starIcon} style={{ width: "18px", height: "18px" }} alt="start" />
        } else {
            return null
        }
    }


    function GenderIcon(props) {
        if (props.gender == '女') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-danger " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else if (props.gender == '男') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-info " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else {
            return null
        }
    }

    //console.log(componentList)
    for (let y in componentList) {

        //let userID=clientData[x]['userID']
        //let x = String(componentList[y])
        
        let orderID = componentList[y]
        console.log(orderID)
        let clientID=null
        for (let x in clientData){
            if (clientData[x].orderID==orderID){
                clientID=clientData[x].clientID
            }
        }
        
        
        console.log(clientID)
        let toMakeContact = `/contact/${orderID}`

        for (let x in clientData) {
            console.log("componentList[y]",componentList[y])
            if (componentList[y]==clientData[x].orderID){
                console.log(clientData[x].orderID,componentList[y])
            }
           
            if (clientData[x].orderID == componentList[y]) {
                if (contactSet.has(today + clientData[x]['orderID'])) {
                    // break;

                } else {
                    //消費名單列
                    let singleContact =
                        <>

                            {/* <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
                                <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={x}   >
                                    <div >
                                        <div className="ml-2 "><span className=" text-dark" >{clientData[x]['name']} </span>
                                            <span className="m-1"><GenderIcon gender={clientData[x]['gender']} />  </span>
                                            <span className="text-muted  ml-1 font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{clientData[x]['orderDate'].substring(5)} </span>
                                            <Star newClient={clientData[x]['newClient']} />
                                        </div>
                                        <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {clientData[x]['serviceList'].toString().replaceAll(",", " / ")}<br/>{clientData[x]['serviceIDList'].toString().replaceAll(",", " / ")}</div>
                                    </div>

                                </li>
                            </Link> */}
                        
                           
                                <li className="list-group-item font-weight-lighter p-1 mb-1 btn text-left  " tag="button" key={x}  onClick={()=>{
                                        window.location.href=`${toMakeContact}`}} >
                                   
                                        <div className="ml-2 "><span className=" text-dark" >{clientData[x]['name']} </span>
                                            <span className="m-1"><GenderIcon gender={clientData[x]['gender']} />  </span>
                                            <span className="text-muted  ml-1 font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{clientData[x]['orderDate'].substring(5)} </span>
                                            <Star newClient={clientData[x]['newClient']} />
                                            <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>客單編號:  {clientData[x]['orderID']}</div>
                                        <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {clientData[x]['serviceList'].toString().replaceAll(",", " / ")} <br/>{clientData[x]['serviceIDList'].toString().replaceAll(",", " / ")}</div>
                                    </div>

                                </li>
                       
                            
                        </>

                    clientList.push(singleContact)
                }
            }
        }

    }





    if (clientList.length == 0 && count == 0) {

        return (null)


    } else {
        if (clientList.length > 0) {
            return (
                <>

                    <div className="p-2  my-2  rounded ">
                        <div className="toast-body ">
                            <h4 ><span className=" badge-pill badge alert-dark"><img src={targetIcon} style={{width:"20px"}}/> {componentTitle}   {clientList.length} </span> </h4>
                            <ul className="list-group ">
                                {clientList}

                            </ul>
                        </div>

                    </div>

                </>
            )

        } else {
            return (
                null
            )
        }


    }

};

//生日元件
const BirthdayContactPart = (props) => {

    let componentList = props.componentList //清單
    let clientData = JSON.parse(sessionStorage.getItem('client'))
    let clientList = []


    function GenderIcon(props) {
        if (props.gender == '女') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-danger " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else if (props.gender == '男') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-info " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else {
            return null
        }
    }


    for (let y in componentList) {
        console.log("yes birthday")
        // let x = String(componentList[y])

        let toMakeContact = `/contactBirthday/${componentList[y]['orderID']}`

        //生日
        let singleContact =
            <>

                <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
                    <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={y}   >
                        <div >
                            <div className="ml-2 "><span className=" text-dark" >{componentList[y]['name']} </span>
                                <span className="m-1"><GenderIcon gender={componentList[y]['gender']} />  </span>
                                <span className="text-muted  ml-1 font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{componentList[y]['orderDate'].substring(5)} </span>
                                <span className="text-muted  ml-2" style={{ fontSize: "smaller" }}> 生日：{componentList[y]['birthdayMonth']}/{componentList[y]['birthdayDay']} </span>


                            </div>

                            <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {componentList[y]['serviceList'].toString().replaceAll(",", " / ")}</div>
                        </div>

                    </li>
                </Link>
            </>

        clientList.push(singleContact)
    }

    if (clientList.length == 0) {
        return null
    } else {
        return (
            <>
                <div className="p-2  my-2  rounded ">
                    <div className="toast-body ">
                        <h4 ><span className=" badge-pill badge alert-warning">本日壽星  {clientList.length} </span> </h4>
                        <ul className="list-group ">
                            {clientList}
                        </ul>
                    </div>
                </div>
            </>
        )

    }

};



//本日已定聯名單元件
const TodayHistory = (props) => {
    let clientHistorySet = new Set();
    let clientData = JSON.parse(sessionStorage.getItem('client'))
    let contactData = JSON.parse(sessionStorage.getItem('contact'))

    let clientList = []
    let clientIDList = []

    let today = getDay(0, "-") //今天日期

    // 找出今天定聯的清單

    for (let x in contactData) {

        if (contactData[x]['createDate'] == today) {

            clientIDList.push(contactData[x]['orderID'])
        }
    }


    function Star(props) {
        //新客戶標示
        if (props.newClient == "1") {
            return <img src={starIcon} style={{ width: "22px", height: "22px" }} alt="start" />
        } else {
            return null
        }
    }


    function GenderIcon(props) {
        if (props.gender == '女') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-danger " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else if (props.gender == '男') {
            let genderDot =
                <span className="  font-weight-lighter rounded-circle alert-info " style={{ fontSize: "80%" }} >{props.gender} </span>
            return genderDot
        } else {
            return null
        }
    }

    //console.log("clientIDList.length",clientIDList.length)
    if (clientIDList.length == 0) {
        return null
    } else {
        for (let y in clientIDList) {


            for (let x in clientData) {


                if (clientData[x]['orderID'] == clientIDList[y]) {
                    if (!clientHistorySet.has(clientIDList[y])) {
                        clientHistorySet.add(clientIDList[y])
                        let toMakeContact = `/contact/${clientData[x]['orderID']}`
                        let singleContact =
                            <>
                                <Link to={toMakeContact}>
                                    <li className="list-group-item font-weight-lighter p-1 mb-1  " tag="button" key={x}   >
                                        <div >
                                            <div className="ml-2 "><span><img src={checkIcon} style={{ "width": "20px" }} /></span><span className=" text-dark ml-2" >{clientData[x]['name']} </span>
                                                <span className="m-1"><GenderIcon gender={clientData[x]['gender']} />  </span>
                                                <span className="text-muted  ml-1 font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{clientData[x]['orderDate'].substring(5)} </span><Star newClient={clientData[x]['newClient']} />

                                            </div>
                                            {/*  <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {clientData[x]['serviceList'].toString().replace(",", " / ")}</div>*/}
                                        </div>

                                    </li>
                                </Link>
                            </>

                        clientList.push(singleContact)
                    }
                }
            }

        }
        console.log(clientHistorySet)
        return (
            <>

                <div className="p-2  my-2  rounded  ">
                    <div className="toast-body ">
                        <h4 ><span className="  badge-pill badge alert-info"><img src={checkIcon} style={{ "width": "20px" }} /> 本日 Hit  {clientList.length} </span> </h4>
                        <ul className="list-group ">
                            {clientList}
                        </ul>
                    </div>

                </div>
            </>
        )

    }

};

//客單資料上傳
const UploadERP = (prpos) => {
    //設計 確認是否有足夠等級
    //如果未符合 回到首頁
    return <>
        <div className="card mt-3 alert-info" >
            <div className="card-body">
                <h5 className="card-title">上傳更新ERP資料檔案 </h5>
                <p>請確定格式為 CVS 且含有以下依序14欄位之資料</p>
                <div>
                    <table >
                        <tr >
                            <td >設計師新客</td>
                            <td>顧客編號</td>
                            <td>顧客姓名</td>
                            <td>行動電話</td>
                            <td>生日-年</td>
                            <td>生日-月</td>
                            <td>生日-日</td>
                            <td>性別</td>
                            <td>消費日期</td>
                            <td>帳單單號</td>
                            <td>項目代號</td>
                            <td>員編</td>
                            <td>是否刪除</td>
                            <td>店碼</td>

                        </tr>
                    </table>
                </div>
                <div className="p-3">
                    <form action="/uploadFile" method="post" enctype='multipart/form-data'>
                        <div className="p-1">
                            <input type="file" id="file" name="file" accept=".csv" className="btn alert-dark" />
                        </div>

                        <div className="p-1">
                            <input type="submit" className="btn btn-danger" value="確認上傳資料" />
                        </div>

                    </form>

                </div>

            </div>
        </div>
    </>
}

//使用者資料上傳
const UploadUser = (prpos) => {
    //設計 確認是否有足夠等級
    //如果未符合 回到首頁
    return <>
        <div className="container w-90">

            <div className="card mt-3 alert-info" >
                <div className="card-body">
                    <h5 className="card-title">上傳更新ERP - User 資料檔案 </h5>
                    <p>請確定格式為 CVS 且含有以下依序4欄位之資料</p>
                    <div>
                        <table >
                            <tr >
                                <td >員工編號</td>
                                <td>暱稱</td>
                                <td>手機號碼</td>
                                <td>店碼</td>
                            </tr>
                        </table>
                    </div>
                    <div className="p-3">
                        <form action="/upUserToSql" method="post" enctype='multipart/form-data'>
                            <div className="p-1">
                                <input type="file" id="file" name="file" accept=".csv" className="btn alert-dark" />
                            </div>

                            <div className="p-1">
                                <input type="submit" className="btn btn-danger" value="確認上傳資料" />
                            </div>

                        </form>

                    </div>

                </div>
            </div>

        </div>
    </>
}

//登出
const Logout = () => {
    //確認是否已登入
    useEffect(() => {
        let userID = sessionStorage.getItem('userID')
        makeLog(userID, "logout")
    }, [])

    window.location.href = "./login"
};


export { Logout, Init, InitContact, TodayCounting, ContactPart, BirthdayContactPart, TodayHistory, UploadERP, UploadUser };
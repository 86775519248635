import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";
import getDay from "../function/getDay.js"; //計算取得日期
import xlsx from "xlsx"

var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}


//問卷管理
const SurveyManage = (props) => {
  let param = useParams();
  let level = param.level;
  //處理清單所有店清單
  const title=sessionStorage.getItem('title');
  const surveyManager = sessionStorage.getItem('surveyManager');
  const [surveyManagerList, setSurveyManagerList] = useState([])
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitID = sessionStorage.getItem('unitID')
  let unitAllList = JSON.parse(sessionStorage.getItem('unitList'))
  let unintAllListArray = []
  const [surveyData, setSurveyData] = useState([])
  for (let x in unitAllList) {
    manageUnit.map(item => {
      if (item == unitAllList[x].unitID) {
        unintAllListArray.push(unitAllList[x])
      }
    })

  }

  let date = sessionStorage.getItem("dateStartDate") || getMonFirstDate()
  let endDate = sessionStorage.getItem("dateEndDate") || getDay(0, "-")
  console.log("endDate",endDate)
  // const [dateStateStart, setDateStart] = useState(date);
  // const [dateStateEnd, setDateEnd] = useState(endDate);

  // 取得 Survey問卷資料
  const getSurveyData = async (date , endDate , name = "") => {
    let cmd = null
    sessionStorage.setItem("dateStartDate",date)
    sessionStorage.setItem("dateEndDate",endDate)
    // if (startDate == "" || endDate == "") {
    //   cmd = `${url}/surveyList?token=${token}&name=${name}`;
    // } else {
    //   cmd = `${url}/surveyList?token=${token}&startDate=${startDate}&endDate=${endDate}&name=${name}`;
    // }
     cmd = `${url}/surveyList?token=${token}&startDate=${date}&endDate=${endDate}&name=${name}`;
    let data = await fetch(cmd).then(res => res.json()).then(json => {
      return json
    })
    
    

   
    //比對是否在管理單位內
    console.log("manageUnit",manageUnit)
    let newData=[]
    data.forEach(thisData => {
      if (manageUnit.includes(thisData.unitID)){
        newData.push(thisData)
      }
      
    });
    // const newData=data.filter(thisData=>{manageUnit.includes( thisData.unitID)})

    setSurveyData(newData)


  };



  let token = sessionStorage.getItem("token");



  let unitList = new Set(manageUnit);
  // console.log(unitList);


  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  let manageUnitListNameArray = []
  for (let x in manageUnitListName) {
    let temp = {
      "unitName": x,
      "unitID": manageUnitListName[x]
    }
    manageUnitListNameArray.push(temp)
  }
  manageUnitListNameArray.sort((a, b) => {
    var nameA = a.unitName; // ignore upper and lowercase
    var nameB = b.unitName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  let districtListNameArray = []
  for (let x in districtListName) {
    let temp = {
      "districtName": x,
      "districtID": districtListName[x]
    }
    districtListNameArray.push(temp)
  }
  districtListNameArray.sort((a, b) => {
    var nameA = a.districtName; // ignore upper and lowercase
    var nameB = b.districtName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });



  //  let date = sessionStorage.getItem("dateStartDate") || getMonFirstDate()
  // let endDate = sessionStorage.getItem("dateEndDate") || getDay(0, "-")



  //csv
  const columns = [
    {
      id: "c0",
      displayName: "分區",
    },
    {
      id: "c1",
      displayName: "分店",
    },
    {
      id: "c2",
      displayName: "設計師",
    },
    {
      id: "c3",
      displayName: "顧客",
    },
    {
      id: "c4",
      displayName: "作答日",
    },
    {
      id: "c5",
      displayName: "作答次數",
    }

    ,
    {
      id: "c6",
      displayName: "Q1",
    },
    {
      id: "c7",
      displayName: "C1",
    },
    {
      id: "c8",
      displayName: "Q2",
    },
    {
      id: "c9",
      displayName: "C2",
    },
    {
      id: "c10",
      displayName: "Q3",
    },
    {
      id: "c11",
      displayName: "C3",
    },
    {
      id: "c12",
      displayName: "Q4",
    },
    {
      id: "c13",
      displayName: "C4",
    },
    {
      id: "c14",
      displayName: "Q5",
    },
    {
      id: "c15",
      displayName: "C5",
    },
    {
      id: "c16",
      displayName: "Q6",
    },
    {
      id: "c17",
      displayName: "備註",
    },
    {
      id: "c18",
      displayName: "回覆人員",
    },
    {
      id: "c20",
      displayName: "店主管回覆",
    },
    {
      id: "c21",
      displayName: "顧客回覆",
    },
    {
      id: "c22",
      displayName: "處理方式",
    },
    {
      id: "c19",
      displayName: "狀態",
    }
    ,

  ];
  const [datas, setDatas] = useState();

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    } else {
      mon = m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }


  //let fileName=`${date}-${endDate}統計資料`
  //日期相減天數
  const DateDiff = function (sDate1, sDate2) {
    // sDate1 和 sDate2 是 2016-06-18 格式
    var oDate1 = new Date(sDate1);
    var oDate2 = new Date(sDate2);
    var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
    return iDays + 1;
  };

  const [countState, setcountState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);




  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }


  function arrangeResultSelectUnitName(unitID) {
    let startDate = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    let name = document.getElementById('name').value;
    const getSurveyDataByUnitID = async (startDate = "", endDate = "", unitID, name = "") => {
      let cmd = null
      if (startDate == "" || endDate == "") {
        cmd = `${url}/surveyList?token=${token}&name=${name}`;
      } else {
        cmd = `${url}/surveyList?token=${token}&startDate=${startDate}&endDate=${endDate}&unitID=${unitID}&name=${name}`;
      }
      let data = []
      if (unitID == "全部") {
        data = await fetch(cmd).then(res => res.json()).then(json => {
          return json
        })
      } else {
        data = await fetch(cmd).then(res => res.json()).then(json => {
          return json.filter(item => {
            return item.unitID == unitID
          })
        })
      };

      let newData=[]
      data.forEach(thisData => {
        if (manageUnit.includes(thisData.unitID)){
          newData.push(thisData)
        }
        
      });
      setSurveyData(newData)
    };
    getSurveyDataByUnitID(startDate, endDate, unitID, name)
  }

  function arrangeResultSelectState(stateSelect) {
    let startDate = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    let name = document.getElementById('name').value
    const getSurveyDataByState = async (startDate = "", endDate = "", stateSelect, name = "") => {
      console.log(startDate, endDate)
      let cmd = null
      if (startDate == "" || endDate == "") {
        cmd = `${url}/surveyList?token=${token}&name=${name}`;
      } else {
        cmd = `${url}/surveyList?token=${token}&startDate=${startDate}&endDate=${endDate}&name=${name}`;
      }
      let data = []
      if (unitID == "全部") {
        data = await fetch(cmd).then(res => res.json()).then(json => {
          return json
        })
      } else {

        console.log(stateSelect)
        if (stateSelect == "全部") {
          data = await fetch(cmd).then(res => res.json()).then(json => {
            console.log(json)
            return json
          })
        } else if (stateSelect == "結案" || stateSelect == "追蹤中") {
          data = await fetch(cmd).then(res => res.json()).then(json => {
            return json.filter(item => {
              console.log(item.state, stateSelect)
              return item.state == stateSelect
            })
          })
        } else {
          data = await fetch(cmd).then(res => res.json()).then(json => {
            return json.filter(item => {
              console.log(item.state, stateSelect)
              return item.state == "" || item.state == "待追蹤"
            })
          })
        };

      };
      let newData=[]
      data.forEach(thisData => {
        if (manageUnit.includes(thisData.unitID)){
          newData.push(thisData)
        }
        
      });

      console.log(data)
      setSurveyData(newData)
    };

    getSurveyDataByState(startDate, endDate, stateSelect, name)


  }

  function arrangeResultSelectDistrict(districtID) {
    let startDate = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    let name = document.getElementById('name').value
    const getSurveyDataByDistrictID = async (startDate = "", endDate = "", districtID, name = "") => {
      let cmd = null
      if (startDate == "" || endDate == "") {
        cmd = `${url}/surveyList?token=${token}&name=${name}`;
      } else {
        cmd = `${url}/surveyList?token=${token}&startDate=${startDate}&endDate=${endDate}&name=${name}`;
      }
      let data = []
      if (districtID == "全部") {
        data = await fetch(cmd).then(res => res.json()).then(json => {
          return json
        })
      } else {
        data = await fetch(cmd).then(res => res.json()).then(json => {
          return json.filter(item => {
            return item.districtID == districtID
          })
        })
      };

      let newData=[]
      data.forEach(thisData => {
        if (manageUnit.includes(thisData.unitID)){
          newData.push(thisData)
        }
        
      });

      console.log(data,newData)
      setSurveyData(newData)
    };


    getSurveyDataByDistrictID(startDate, endDate, districtID, name)
  }

  function handleChangeUnit() {
    let selectUnit = document.getElementById("unitNameSelect").value;

    arrangeResultSelectUnitName(selectUnit);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    console.log('selectDistrict', selectDistrict)

    //處理 unit option
    let unitNameSelect = document.getElementById('unitNameSelect')

    //先處理剩下 全部 這一個選項
    unitNameSelect.options.length = 1
    if (selectDistrict == "全部") {
      unintAllListArray.map(item => {

        let opt1 = document.createElement("option")
        opt1.value = item.unitID
        opt1.text = item.unitName
        unitNameSelect.add(opt1, null)


      })
    } else {
      unintAllListArray.map(item => {
        if (item.districtID == selectDistrict) {
          //新增option 項目
          let opt1 = document.createElement("option")
          opt1.value = item.unitID
          opt1.text = item.unitName
          unitNameSelect.add(opt1, null)

        }
      })
    }


    arrangeResultSelectDistrict(selectDistrict);
  }
  //更新查詢
  function hangdleSummit() {
    let startDate = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    let name = document.getElementById('name').value;
    console.log(startDate, endDate)
    getSurveyData(startDate, endDate, name);
    document.getElementById("districtSelect").value = "全部"
    document.getElementById("unitNameSelect").value = "全部"
  }

  const DownloadExcel=(props)=>{
    
    const downloadDateForExcel=()=>{
      let fileName = `${dateStateStart}-${dateStateEnd}統計資料`;
const columns = [
   "分區",
 "分店",
   "設計師",
  "顧客",
  "作答日",
  "作答次數",
  "Q1",
  "C1",
   "Q2",
   "C2",
   "Q3",
   "C3",
   "Q4",
   "C4",
   "Q5",
   "C5",
  "Q6",
   "備註",
   "回覆人員",
   "狀態",
   "店主管回覆",
   "顧客回覆",
  "處理方式",
  
]
  let dataArray=[]
  dataArray.push(columns)
  if (unitID == 'A00' || unitID=='A01' ) {
      
    for ( let x in surveyData){
      let tempDatas = [
      surveyData[x]["districtName"],
       surveyData[x]["unitName"],
       surveyData[x]["userName"],
       surveyData[x]["name"],
       surveyData[x]["createDate"],
       surveyData[x]["count"],
       surveyData[x]["q1"],
       surveyData[x]["c1"],
       surveyData[x]["q2"],
       surveyData[x]["c2"],
       surveyData[x]["q3"],
       surveyData[x]["c3"],
       surveyData[x]["q4"],
       surveyData[x]["c4"],
       surveyData[x]["q5"].replaceAll("1","願意").replaceAll("2","不願意"),
       surveyData[x]["c5"],
       surveyData[x]["q6"],
       surveyData[x]["memo"].replaceAll(",","，"),
       surveyData[x]["operator"],
       surveyData[x]["state"],
      surveyData[x]["managerReply"].replaceAll(",","，"),
      surveyData[x]["clientReply"].replaceAll(",","，"),
      surveyData[x]["reply"].replaceAll(",","，").replace("1","不滿意退費").replace("2","已回覆消基會").replace("3","轉店").replace("4","其他"),
      ]
    dataArray.push(tempDatas)
  };}
let jsonWorkSheet = xlsx.utils.aoa_to_sheet(dataArray);
let workBook = {
  SheetNames: [ 'jsonWorkSheet'],
  Sheets: {
    'jsonWorkSheet': jsonWorkSheet,
  }
};
xlsx.writeFile(workBook, `${fileName}.xlsx`);
         
    }

    return (
      <>
          <div className="btn btn-info" onClick={downloadDateForExcel}> 下載Excel 
            
          </div>
        </>
    )
  }


  const DonwloadCSV = (props) => {
    let dataArray=[]
    let fileName = `${dateStateStart}-${dateStateEnd}統計資料`;
    if (unitID == 'A00' || unitID=='A01') {
      
      for ( let x in surveyData){
        let tempDatas = {
        c0: surveyData[x]["districtName"],
        c1: surveyData[x]["unitName"],
        c2: surveyData[x]["userID"],
        c3: surveyData[x]["userName"],
        c4: surveyData[x]["createDate"],
        c5: surveyData[x]["count"],
        c6: surveyData[x]["q1"],
        c7: surveyData[x]["c1"],
        c8: surveyData[x]["q2"],
        c9: surveyData[x]["c2"],
        c10: surveyData[x]["q3"],
        c11: surveyData[x]["c3"],
        c12: surveyData[x]["q4"],
        c13: surveyData[x]["c4"],
        c14: surveyData[x]["q5"].replaceAll("1","願意").replaceAll("2","不願意"),
        c15: surveyData[x]["c5"],
        c16: surveyData[x]["q6"],
        c17: surveyData[x]["memo"].replaceAll(",","，"),
        c18: surveyData[x]["operator"],
        c19: surveyData[x]["state"],
        c20:surveyData[x]["managerReply"].replaceAll(",","，"),
        c21:surveyData[x]["clientReply"].replaceAll(",","，"),
        c22:surveyData[x]["reply"].replaceAll(",","，").replace("1","不滿意退費").replace("2","已回覆消基會").replace("3","轉店").replace("4","其他"),
      }
      dataArray.push(tempDatas)
    };
      // setDatas(dataArray)
      return (
        <>
          <div className="btn">
            <CsvDownloader
              className="btn btn-info"
              filename={fileName}
              separator=","
              wrapColumnChar=""
              columns={columns}
              // datas={datas}
              datas={dataArray}
              text="統計資料下載"
            />
          </div>
        </>
      );
    } else {
      return null
    }


  };



  useEffect(() => {
     console.log("endDate",endDate)
     
      getSurveyData(date,endDate)
    

  }, []);

  return (
    <>
      <div className="text-center">
        <h5 className="card-title text-muted text-center">顧客滿意度調查</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
            </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
            </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                客戶姓名：
            </span>
            </div>
            <input
              type="text"
              className="form-control"
              id="name"
              placeholder="姓名文字"


              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
          </a>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分區：
            </span>
            </div>
            <select
              class="form-control"
              id="districtSelect"
              onChange={handleChangeDistrict}
            >
             <option>全部</option> 
              {districtListNameArray.map(item => {

                return <option value={item.districtID}>{item.districtName}</option>
              })}
              {/* {districtListState} */}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分店：
            </span>
            </div>
            <select
              class="form-control"
              id="unitNameSelect"
              onChange={handleChangeUnit}
            >
           <option>全部</option>
              {unintAllListArray.map(item => {
                return <option value={item.unitID}>{item.unitName}</option>
              })}
              {/* {unitListState} */}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                狀態
            </span>
            </div>
            <select
              class="form-control"
              id="stateSelect"
              onChange={(e) => {
                let stateSelect = e.target.value

                arrangeResultSelectState(stateSelect);
              }}
            >
              <option>全部</option>
              <option>待追蹤</option>
              <option>追蹤中</option>
              <option>結案</option>

              {/* {unitListState} */}
            </select>
          </div>
          {/* <DonwloadCSV level={level} /> */}
          <DownloadExcel/>
        </div>



        <table className="table table-hover table-condensed table-striped">
          <thead clasName="py-3">
            <tr >

              <th className="px-1  text-center">分區</th>
              <th className="px-1  text-center">分店</th>
              <th className="px-1  text-center">設計師</th>
              <th className="px-1  text-center">顧客</th>
              {/* <th className="px-1 text-center">性別</th> */}

              <th className="px-1  text-center">作答日</th>
              <th className="px-1  text-center">作答次數</th>
              <th className="px-1 text-center">Q1</th>
              <th className="px-1 text-center">Q2</th>
              <th className="px-1 text-center">Q3</th>
              <th className="px-1 text-center">Q4</th>
              <th className="px-1 text-center">Q5</th>
              <th className="px-1 text-center">Q6</th>
              <th className="px-0  text-center">備註內容</th>
              <th className="px-1 text-center">回覆人員</th>
              <th className="px-1 text-center">狀態</th>



            </tr>
          </thead>
          <tbody>{surveyData.map(item => {
            let willing = <span className="text-danger">不願意</span>;
            if (item.q5 == "1") {
              willing = "願意";
            }

            let warning=null
            if (item.warning=="1"){
              warning=<span className="text-danger font-weight-bolder">異常</span>;
            }

            let surveySingleViewUrl = "surveySingleView"
            if (surveyManager != "1") {
              surveySingleViewUrl = "surveySingleViewReadOnly"
            }


            let done = null
            switch (item.state) {
              case "結案":
                done = <span className="text-muted">結案</span>
                break;
              case "追蹤中":
                done = <span className="text-info">追蹤中</span>
                break;
              default:
                done = <span className="text-danger">待追蹤</span>
            }
            // console.log(item.state)

            let count = item.count
            if (item.count > 1) {
              count = <span className="text-danger">{item.count}</span>
            }

            return <>
              <tr onClick={() => { window.location.href = `/${surveySingleViewUrl}/${item.orderID}` }}>
                <td className="p-1 text-center text-muted ">{item.districtName} </td>
                <td className="p-1 text-center text-muted">{item.unitName} </td>
                <td className="p-1 text-center text-muted">{item.userName} </td>
                <td className="p-1 text-center text-muted">{item.name} </td>
                {/* <td className="p-1 text-center text-muted">{item.gender} </td> */}


                <td className="p-1 text-center text-muted">{item.createDate.substring(5, 16)}</td>
                <td className="p-1 text-center text-muted answer">{warning}{count}</td>
                <td className="p-1 text-center text-muted answer">{item.q1} </td>
                <td className="p-1 text-center text-muted answer">{item.q2} </td>
                <td className="p-1 text-center text-muted answer">{item.q3} </td>
                <td className="p-1 text-center text-muted answer">{item.q4} </td>
                <td className="p-1 text-center text-muted">{willing} </td>
                <td className="p-1 text-center text-muted">{item.q6.substring(0, 5)}...</td>
                <td className="p-0 text-center text-muted">{item.memo.substring(0, 5)}...</td>
                <td className="p-1 text-center text-muted">{item.operator}</td>
                <td className="p-1 text-center text-muted">{done}</td>



              </tr>

            </>
          })}</tbody>
        </table>

      </div>
    </>
  );
};


export default SurveyManage;
//編碼整理manageUnit清單成分區清單
function manageUnitToDistrictList(){
	let unitList=JSON.parse(sessionStorage.getItem('unitList'))
	let manageUnit=sessionStorage.getItem('manageUnit').split(",")

	let districtList=new Set()
	let districtListName={}
	let manageUnitListName={}
	for (let i in unitList ){
		for (let y in manageUnit){
			if (manageUnit[y]==unitList[i]["unitID"]){
				districtList.add(unitList[i]["districtID"])
				districtListName[unitList[i]["districtName"]]=unitList[i]["districtID"]
				manageUnitListName[unitList[i]["unitName"]]=unitList[i]["unitID"]
				break;
			}
		}
	}
	let result=Array.from(districtList)
	//manageUnitListName["全部"]="全部"
	//districtListName["全部"]="全部"
	//alert(result)
	sessionStorage.setItem('districtList',JSON.stringify(result) );
	sessionStorage.setItem('districtListName',JSON.stringify(districtListName) );
	sessionStorage.setItem('manageUnitListName',JSON.stringify(manageUnitListName))
	return true
}


export default manageUnitToDistrictList;


import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import Fuse from 'fuse.js'
//picker
import 'rsuite/dist/styles/rsuite-default.css';
import { TagPicker } from 'rsuite';

//Icon
import searchIcon from '../icon/search-circle.svg'
import textSvg from '../icon/text.svg'
import phoneSvg from '../icon/phone-8.svg'
import makeLog from '../function/makeLog.js'
//import starIcon from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import logo from '../icon/HAPPYHAIR_w.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'

const Clients = (props) => {
  const [serviceArray, setServiceArray] = useState([])
  const [tag, setTag] = useState([])
  var imgStyle = {
    width: '25px',
    height: '25px',
    lineHeight: '45px',
  };




  let clientData = JSON.parse(sessionStorage.getItem('client'))
  clientData.sort((a, b) => {

    var nameA = a.orderDate; // ignore upper and lowercase
    var nameB = b.orderDate; // ignore upper and lowercase
    if (nameA < nameB) {
      return 1;
    }
    if (nameA > nameB) {
      return -1;
    }

    // names must be equal
    return 0;
  })

  const [data, setData] = useState(clientData)
  const [tagFilterData, setTagFilterData] = useState(clientData)

  let clientList = []
  const [listState, setList] = useState('')
  const [clientAmount, setAmout] = useState(Object.keys(clientData).length)

  let options = {
    keys: ['name']
  }






  function handleChange(e) {
    let fuse = new Fuse(tagFilterData, options)
    let searchName = document.getElementById('searchName').value
    console.log("Tag", tag, tag.length)
    console.log("searchName", searchName, searchName.length)
    console.log("data", data)
    console.log("searchName.length", searchName.length)
    if (searchName.length != 0) {

      let searchResult = fuse.search(searchName)
      console.log(searchResult)
      let tempArray = []
      for (let x in searchResult) {
        tempArray.push(searchResult[x].item)
      }
      if (tempArray.length != 0) {
        setData(tempArray)
      }



    } else {
      console.log("here")
      console.log("tag.length", tag.length)
      if (tag) {
        if (tag.length > 0) {
          console.log("setData(tagFilterData)")
          console.log(tagFilterData.length)
          setData(tagFilterData)
        } else {
          console.log(" setData(clientData)")
          setData(clientData)
        }
      } else {
        setData(clientData)
      }



    }

  }

  function Star(props) {
    //新客戶標示
    if (props.newClient == "1") {
      return <span> <img className="ml-1" src={starIcon} style={{ width: "18px", height: "18px" }} alt="start" /></span>
    } else {
      return null
    }
  }


  function MakeCall(props) {

    //電話按鈕
    if (props.phone.length > 0) {
      let phoneCall =
        <span className="text-right  ml-2 text-success   font-weight-lighter "><a href={`tel:${props.phone}`}><span className="ml-1 text-muted text-decoration-none  font-weight-lighter " style={{ fontSize: "smaller" }}><img src={phoneSvg} style={imgStyle} alt="edit" /> {props.phone} </span> </a></span>
      return phoneCall
    } else {
      return null
    }
  }



  function GenderIcon(props) {
    if (props.gender == '女') {
      let genderDot =
        <span class="  font-weight-lighter rounded-circle alert-danger " style={{ fontSize: "80%" }} >{props.gender} </span>
      return genderDot
    } else if (props.gender == '男') {
      let genderDot =
        <span class="  font-weight-lighter rounded-circle alert-info " style={{ fontSize: "80%" }} >{props.gender} </span>
      return genderDot
    } else {
      return null
    }
  }


  function handelTag(tag) {
    console.log(tag)
    setTag(tag)
    if (tag) {
      if (tag.length != 0) {
        let tempData = []
        for (let x in clientData) {
          let tagArray = clientData[x]["serviceList"]
          for (let i in tag) {
            for (let y in tagArray) {
              if (tagArray[y] === tag[i]) {
                // console.log(clientData[x]["name"])
                tempData.push(clientData[x])

                break;
              }
            }

          }
        }
        console.log(tempData.length)

        setData(tempData)
        setTagFilterData(tempData)
      } else {
        setData(clientData)
        setTagFilterData(clientData)
      }
    }


    // setTagFilterData(data)

  }

  function makeList() {
    for (let x in clientData) {
      let serviceList = ""
      // if (clientData[x]["serviceList"].length==1){
      //     serviceList=clientData[x]["serviceList"]
      // }else{
      //     serviceList=clientData[x]["serviceList"].toString().replaceAll(","," / ")
      // }

      if (clientData[x]["serviceList"].length == 0) {
        serviceList = clientData[x]["productSale"]
      } else if (clientData[x]["serviceList"].length == 1) {
        serviceList = clientData[x]["serviceList"]
      } else {
        serviceList = clientData[x]["serviceList"].toString().replaceAll(",", " / ")
      }
      let toMakeContact = `/contact/${clientData[x]['orderID']}`
      //let im=clientData[x]['im'].replace(/,/g,"、")


      let singleClient =
        <>
          <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
            <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={x}   >
              <div >
                <div className="ml-2 "><span className=" text-dark" >{clientData[x]['name']} </span>
                  <span className="m-1"><GenderIcon gender={clientData[x]['gender']} />  </span>   <Star newClient={clientData[x]['newClient']} /><br />
                  <span className="text-muted  font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{clientData[x]['orderDate'].substring(5)}</span>
                  <span className="text-muted ml-2" style={{ fontSize: "80%" }}> 生日: {clientData[x]['birthdayMonth']}/{clientData[x]['birthdayDay']}</span>


                </div>

                <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {serviceList}</div>


              </div>

            </li>
          </Link>
        </>

      if (clientData[x]["serviceList"].length != 0 || clientData[x]["productSale"].length != 0) {
        console.log(x, clientData[x]["name"], clientData[x]["serviceList"], clientData[x]["serviceList"].length)
        clientList.push(singleClient)
      }




    }
    setList(clientList)
  }

  useEffect(() => {
    let userID = sessionStorage.getItem('userID')
    makeLog(userID, "clients")
    makeList()
  }, [])

  useEffect(() => {
    let service = JSON.parse(sessionStorage.getItem("service"))
    let tempArray = []
    for (let x in service) {
      if (service[x]["gender"] == "女") {
        let temp = {
          "label": service[x]["service"],
          "value": service[x]["service"],
          "role": "Master"
        }
        tempArray.push(temp)
      }

    }
    // console.log(tempArray)
    setServiceArray(tempArray)
  }, [])



  return (
    <>
      <div style={{ "maxWidth": "450pt" }}>
        <div className="p-2  my-2  rounded ">
          <div className="toast">
            <div className="toast-header">
              <h5>客戶資料: {clientAmount} 筆 : 消費篩選 {data.length} 筆</h5>
            </div>
            <div className="input-group mb-3">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroup-sizing-default"><img src={searchIcon} alt="searchIcon" style={{ weight: "24px", height: '24px' }} />Search</span>
              </div>
              <input type="text" className="form-control" id="searchName" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" onChange={handleChange} />
            </div>
            <div className="row mt-2">
            
              <div className="col-9 " >
                <TagPicker data={serviceArray}  block onChange={handelTag} />
              </div>
              <div className="col-1 p-1">
                <div className="btn btn-secondary btn-sm"  >篩選完成</div>
              </div>
            </div>
<hr/>

            <div className="toast-body  mt-1">
              <ul className="list-group ">
                {data.map((item, index) => {

                  let serviceList = null
                  if (item["serviceList"]) {
                    if (item["serviceList"].length == 0) {
                      serviceList = item["productSale"]
                    } else if (item["serviceList"].length == 1) {
                      serviceList = item["serviceList"]
                    } else {
                      serviceList = item["serviceList"].toString().replaceAll(",", " / ")
                    }
                    let toMakeContact = `/contact/${item['orderID']}`

                    let singleClient =
                      <>
                        <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
                          <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={index}   >
                            <div >
                              <div className="ml-2 "><span className=" text-dark" >{item['name']} </span>
                                <span className="m-1"><GenderIcon gender={item['gender']} />  </span>   <Star newClient={item['newClient']} /><br />
                                <span className="text-muted  font-weight-light" style={{ fontSize: "smaller" }}> 消費日：{item['orderDate'].substring(5)}</span>
                                <span className="text-muted ml-2" style={{ fontSize: "80%" }}> 生日: {item['birthdayMonth']}/{item['birthdayDay']}</span>


                              </div>

                              <div className="ml-2  text-muted font-weight-light" style={{ fontSize: "80%" }}>  {serviceList}</div>


                            </div>

                          </li>
                        </Link>
                      </>
                    if (item["serviceList"].length != 0 || item["productSale"].length != 0) {
                      // console.log(index, item["name"], item["serviceList"], item["serviceList"].length)
                      return singleClient
                    }
                  }


                })}
                {/* {listState} */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Clients;
//Login登入
import React ,{useState,useEffect} from 'react';
import makeLog from '../function/makeLog.js'



const   LoginForAdmin=()=> {
    sessionStorage.clear();
      const [account,setAccount]=useState('');
      const [password,setPassword]=useState('');
      const [error,setError]=useState('')
      
      function haneleAccountChange(e){
        setAccount(e.target.value)
    
        };
    
      function handlePassword(e){
        setPassword(e.target.value)
    
      };
     let loginURL="https://login.happyhitnow.com/loginForAdmin"
      let env=process.env.NODE_ENV
            if (env!="production"){
                loginURL="https://login.happyhitnow.com/loginForAdmin"
            }
        
        function hitknow2Login(e){
           //`https://login.happyhitnow.com/login?account=${account}&password=${password}`
           // fetch(`https://hitnow2-login-hn4c6sz7cq-de.a.run.app/login?account=${account}&password=${password}`,{
                fetch(`${loginURL}?id=${account}`,{
                    mode: 'cors',
                }).then(res => {
                    return res.json();
                }).then(json => {
                        console.log('获取的结果', json);
                        makeLog(json.userID,"login")
                        if (json.state==true){
                            sessionStorage.setItem('token',json.token);
                            sessionStorage.setItem('userID',json.userID)
                            sessionStorage.setItem('name',json.name)
                            sessionStorage.setItem('unitID',json.unitID)
                            sessionStorage.setItem('serviceUnit',json.serviceUnit)
                            sessionStorage.setItem('manageUnit',json.manageUnit)
                            sessionStorage.setItem('brand',json.brand)
                            sessionStorage.setItem('unitName',json.unitName)
                            sessionStorage.setItem('reservationUrl',json.reservationUrl)
                            sessionStorage.setItem('surveyManager',json.surveyManager)
                            sessionStorage.setItem('title',json.title)
                            //sessionStorage.setItem('shouldHit',json.shouldHit)
                            let timeCode=new Date().getTime()+1000*60*60
                             //let timeCode=time.setTime(time+1000*60*60)
                            sessionStorage.setItem('timeCode',timeCode)
                            window.location.href = "/init"
                         
                        }else{
                            setError(json.msg)
                            setAccount('')
                            setPassword('')
                        }
                        
        })
    }
    
        return (
        <>
            <div role="alert" color="warning">
            <div style={{textAlign:'center'}}><h4 className="py-3">Happy In Touch ,Now</h4></div>
           
            <hr/>
    
            
        <div className="mt-3 card text-left" >
                <div className="card-body">
                   
                    <form>
                    <div className="card mb-2">
                        <div className="card-body alert-info  border-1 p-2 text-center">快樂麗康(股)公司<br/>個人資料保護法維護公告</div>
                         <div className="card-body border-1" style={{width:"100%"}}>
                         
    顧客基於對品牌與店內夥伴的信任，於快樂麗康(股)公司旗下相關美髮品牌 〈HAPPYHAIR、GENIC〉所填寫之個人資料，僅供店內消費服務時使用，同時顧客的個資是受到個人資料保護法之保護，因此嚴禁夥伴在未經顧客同意下另做處理或利用，以免違反個人資料保護法。<br/>
    您登入即代表同意本公告之內容，並同意善盡相關約定。
                         </div>
                         </div> 
                         <h5 className="card-title">設計師登入</h5>
                        <div className="formGroup">
                            <label>password
                            <input type="text" name="account" id="account"  className="form-control" style={{width:"100%"}}  value={account}  onChange={haneleAccountChange}  />
                            <small id="emailHelp" className="form-text text-muted">請輸入ERP帳號</small>
                            </label>
                        </div>
                        
                        <br/>
                        <div  className="formGroup ">
                        <button className="btn btn-danger" type="button" style={{width:"100%"}}  onClick={hitknow2Login} > 本人同意個資保護，登入 HitNow</button>
                       
                        </div>
           
                     </form>
                </div>
        </div>
        
        <div className="formGroup text-center"><h5>{error}</h5></div>
  
        </div>
        </>
        );
        }




export default LoginForAdmin;
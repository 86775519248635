import React ,{useState,useEffect} from 'react';
import logo from '../icon/HAPPYHAIR_w.png';
//2021-03-27 ERP異常與手機登入作業完成

const HeaderBar = (props) => {
    console.log(process.env)
    let env=process.env.NODE_ENV
    if (env=="production"){
        env="2"
    }else{
        env="local"
    }
    const version=`V.2.1`

    const [name,setName]=useState('')
    const [userID,setUserID]=useState(sessionStorage.getItem("userID"))
    useEffect(()=>{
        setName(sessionStorage.getItem("name"))
    },[]);
  
    function handleClick(e){
        window.location.href = "/init"
    }
  
   
  
      return (
        /*<nav className="navbar navbar-light  text-light  bg-dark" >*/
        <nav className="navbar navbar-light  text-light  bg-dark" >
        <img src={logo} alt="logo" style={{weight:"35px",height:'35px'}} onClick={handleClick}/> <small>{version}.{userID}</small>
     
        <h5>{name} </h5>
        </nav>
  
              );
    };

    export default HeaderBar;
import React  from 'react';
import { Route ,Routes} from 'react-router-dom';
import {UserActiveLog,NewClientHitCount,ClientHitCount,FootPrint,ContactTypeCount,ContactTypeCountDetail} from './pages';
import { Logout,Init,InitContact,UploadERP,UploadUser} from './components';


import MakeContatctHitnow from './components/MakeContatctHitnow.js'
import MakeContatctHitnowBirthday from './components/MakeContatctHitnowBirthday.js'
import MakeContatctNotActive from './components/MakeContatctHitnowForNotActive.js'
import MoreList from './function/more.js'
import Home from './pages/home-0201.js'
import HeaderBar  from './pages/headerBar.js'
import Navbar  from './pages/navbar.js'
import Login from './pages/login.js'
import LoginProhibit from './pages/loginProhibit.js'
import Survey from './pages/survey.js'
import Clients from './pages/clients.js'
import BirthdayClients from './pages/birthdayClients.js'
import NotActiveClients from './pages/NotActiveClients.js'
import AdminMenu from './pages/adminMenu.js'
import LoginForAdmin from "./pages/loginForAdmin.js"
import SingleUserHitList from "./pages/singleUserHitList.js"
import ContactAmountType from "./pages/contactAmountType.js"
import ContactTimeDiff from "./pages/contactTimeDiff"
import LoginCount from "./pages/loginCount.js"
import loginForErpNotAlive from "./pages/loginForErpNotAlive"
import SurveyManage from "./pages/surveyManage"
import SurveySingleView from "./pages/surveySingleView0502.js"
import SurveySingleViewReadOnly from "./pages/surveySingleViewReadOnly.js"
import SurveyCount from "./pages/surveyCount"
import SingleUserSurveyCount from './pages/singleUserSurveyCount'
import UploadPic from './pages/uploadPic'
import UploadPicList from './pages/uploadPicList'


// import LoginCount from "./pages/loginCountA00.js"
function  App() {

  
    return (
      <div className="container">
         <div  id="headerBar" className="fixed-top" >
          <HeaderBar />
         </div>
         <Routes>
         <Route path="/loginForErpNotAlive" exact element={<loginForErpNotAlive/>} />
         <Route path="/" exact element={<Home/>} />
        <Route path="/UploadPic" exact element={<UploadPic/>} />
        <Route path="/UploadPicList" exact element={<UploadPicList/>} />
        <Route path="/clients" element={<Clients/>} />
        <Route path="/birthdayClients" element={<BirthdayClients/>} />
        <Route path="/NotActive" element={<NotActiveClients/>} />
        <Route path="/login" element={<Login/>} />
        <Route path="/loginForAdmin" element={<LoginForAdmin/>} />
        <Route path="/Init" element={<Init/>} />
        <Route path="/InitContact" element={<InitContact/>} />
        <Route path="/logout" element={<Logout/>} />
        <Route path="/UploadERP" element={<UploadERP/>} />
        <Route path="/UploadUser" element={ <UploadUser/>} />
        <Route path="/contact/:orderID" element={ <MakeContatctHitnow/>} />
        <Route path="/contactNotActive/:orderID" element={ <MakeContatctNotActive/>} />
        <Route path="/contactBirthday/:orderID" element={ <MakeContatctHitnowBirthday/>} />
        <Route path="/footprint/:userID" element={ <FootPrint/>} />
        <Route path="/SingleUserHitList/:userID" element={ <SingleUserHitList/>} />
        <Route path="/SingleUserSurveyCount/:userID" element={ <SingleUserSurveyCount/>} />
        
        <Route path="/NewClientHitCount" element={ <NewClientHitCount/>} />
        <Route path="/ClientHitCount" element={ <ClientHitCount/>} />
        <Route path="/LoginCount" element={ <LoginCount/>} />
        <Route path="/AdminMenu" element={ <AdminMenu/>} />
        <Route path="/ContactTypeCount" element={ <ContactTypeCount/>} />
        <Route path="/SurveyManage" element={ <SurveyManage/>} />
        <Route path="/SurveySingleView/:orderID" element={ <SurveySingleView/>} />
        <Route path="/SurveySingleViewReadOnly/:orderID" element={ <SurveySingleViewReadOnly/>} />
        <Route path="/ContactTypeCountDetail" element={ <ContactTypeCountDetail/>} />
        <Route path="/ContactAmountType" element={ <ContactAmountType/>} />
        <Route path="/ContactTimeDiff" element={ <ContactTimeDiff/>} />
        <Route path="/More/:moreType" element={ <MoreList/>} />
        <Route path="/Survey/:surveyID" element={ <Survey/>} />
        <Route path="/SurveyCount" element={ <SurveyCount/>} />
        </Routes>
        <div  id="footerBar" className="fixed-bottom" >
        <Navbar />
       
        </div>
       
      </div>
    
    );
};

export default App;
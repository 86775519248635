import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";


//function
import getDay from '../function/getDay.js'//計算日期
const $=window.$
var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}



const UploadPic=(e)=>{
    const { register, handleSubmit, watch, formState: { errors } } = useForm();
    const [fileName,setFileName]=useState(null)
    const [imgData, setImgData] = useState(null);
    const [startDate,setStartDate]=useState(getDay(0,"-"))
    const [endDate,setEndDate]=useState(getDay(30,"-"))
    const [subject,setSubject]=useState(null)
    const [imgType,setImgtype]=useState(null)
    const [msg, setMsg] = useState(null)
    const token=sessionStorage.getItem('token')

    function UploadPicToServer(){
      var formData = new FormData();
      formData.append('pic',imgData)
      formData.append('startDate',document.getElementById("startDate").value)
      formData.append('endDate',document.getElementById("endDate").value)
      formData.append('subject',document.getElementById("subject").value)
      formData.append('token',token)
      

      fetch(`${url}/uploadPic`,{method:"POST",body:formData,mode:"cors"}).then(res=>{
        // alert(res.status)

       if (res.status==200){
        window.confirm("圖片上傳完成")
        window.location.href = "/UploadPicList";
        // setMsg("圖片上傳完成")
        // $('#remindPopMsgModal').modal('show')
       }else{
        window.confirm("上傳失敗")
        window.location.href = "/UploadPicList";
        // setMsg("上傳失敗")
        // $('#remindPopMsgModal').modal('show')
       }

 return
       
       
      }).catch(err=>{
        setMsg("上傳失敗")
        $('#remindPopMsgModal').modal('show')
      })
    }
    function showPic(e){
       
       console.log(e.target.files[0])

       if (e.target.files[0]){
           setFileName(e.target.files[0])
           const reader = new FileReader();
           reader.addEventListener("load", () => {
               console.log(reader.result.substring(5,15))
               setImgtype(reader.result.substring(5,15))
            setImgData(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
        }
       
    }

    useEffect(()=>{
      

    },[])
  
return (<>
  <form onSubmit={handleSubmit(UploadPicToServer)}>
    <div className="card mt-3 alert-info" >
    <div className="card-body">
      <h5 className="card-title">上傳 HitNow Login 圖片</h5>
      <p>請選擇圖片</p>
      <div>
         
      </div>
      <div className="p-3">
        {/* <form action="/uploadFile" method="post" enctype='multipart/form-data'> */}
  

            <div className="input-group  border-0 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      上架日期
                    </span>
                  </div>
                  <input type="date" id="startDate"  className="btn " 
                  {...register("startDate", { required: true })}
                  required
                  />
                </div>

            <div className="input-group  border-0 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      下架日期
                    </span>
                  </div>
                  <input type="date" id="endDate" className="btn " 
                  {...register("endDate", { required: true })}
                  required
                  />
            </div>
            <div className="input-group  border-0 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      主題說明
                    </span>
                  </div>
                  <input
                   
                    className=" border-1  form-control"
                    // value={subject}
                    id="subject"
                    // placeholder="描述本圖片之主題相關備註說明"
                    // onChange={(e)=>{
                    //   console.log(subject)
                    //     setSubject(document.getElementById("subject").value)
                    // }}

                    required
                    {...register("subject", { required: true })}
                  />
                </div>
          
            <div className="input-group  border-0 mb-1">
               
                 
                <input type="file" id="fileNamePic " className=" border-1 form-control" name="filePIc" accept=".png,.jpg" className="btn alert-dark" onChange={showPic} required
                  //  {...register("fileNamePic", { required: true })}
                />
            
        
            </div>
            
            <div className="align-center">
                <img src={imgData} width="330px"/>
                {/* <p>file:{fileName}</p> */}
            </div>
            <div className="p-1">
                <input type="submit" className="btn btn-danger" value="確認上傳資料" />
            </div>
           
        {/* </form>	 */}
    
      </div>
        
    </div>
  </div>
  </form>
         {/** ----劃位完成使用 */}
         <div className="modal fade" id="remindPopMsgModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" modal="show">
        <div className="modal-dialog-centered modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">HitNow</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              {msg}
            </div>
            <div className="modal-footer ">

              <div className="btn alert-info ml-auto mr-auto" data-dismiss="modal" style={{ "width": "100pt" }} onClick={() => {
               window.location.href = "/UploadPicList";
              //  return
              }}>確定</div>

            </div>
          </div>
        </div>
      </div>
  </>
)

}

export default UploadPic;
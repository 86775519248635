import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import {TodayCounting,ContactPart,BirthdayContactPart,TodayHistory} from '../components';
import Fuse from 'fuse.js'


//function 
import loginCheck from '../function/loginCheck.js'//確認是否登入中
import getDay from '../function/getDay.js'//計算取得日期
import BirthdayContactPartSingleDay from '../function/birthdayContactPartSingleDay' 

//Icon
import searchIcon from '../icon/search-circle.svg'
import textSvg from '../icon/text.svg'
import phoneSvg from '../icon/phone-8.svg'
import makeLog from '../function/makeLog.js'
//import starIcon from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import logo from '../icon/HAPPYHAIR_w.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'

const AdminMenu=(props)=> {

  let surveyManager=sessionStorage.getItem("surveyManager")
  let surveyShow=null
  if (surveyManager){
    surveyShow=<p> <a href="/surveyManage" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>顧客滿意度調查</a></p>
  }
  
  
    useEffect(()=>{
      let userID=sessionStorage.getItem('userID')
        makeLog(userID,"adminMenu")
    },[])
  
    //區主管 看滿意度
  const DistrictPart=()=>{
    // let districtList=JSON.parse(sessionStorage.getItem('districtList'))
    let title=sessionStorage.getItem('title')
    console.log('title:',title)
    let titleArray=['P92','P91','P82','P81','P96','P61']
    if (titleArray.includes(title)) {
      return (
        <>
        <p> <a href="/surveyCount" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>客戶滿意問卷統計</a></p>
        <p> <a href="/surveyManage" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>顧客滿意度調查</a></p>
        </>
      )
    }else{
      return null
    }

    // console.log("districtList",districtList, districtList.length)
    // return (
    //   <p> <a href="/surveyCount" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>客戶滿意問卷統計</a></p>
    // )
  }

   const A00Part=()=>{
     let unitID=sessionStorage.getItem('unitID')
     if (unitID=="A00"){
       return <>
       <hr className="border-danger"/>
       <h5 className="text-danger">進階管理功能</h5>
       <p> <a href="/ContactTimeDiff" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>Hit時間差統計</a></p>
       <p> <a href="/LoginCount" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>登入統計 / 預約啟用狀態</a></p>
       <p> <a href="/surveyCount" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>客戶滿意問卷統計</a></p>
       <p> <a href="/UploadPicList" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>每日圖片管理</a></p>
       {/* <p> <a href="/surveyManage" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>顧客滿意度調查</a></p> */}
       {surveyShow}
       </>
     }else {
       return null
     }
   }

    let returnMent =""
   


    // returnMent=<div>
    //   <p> <a href="/ContactAmountType" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>HitNow數統計</a></p>
      
     
    //   {/* <p> <a href="/ContactTypeCountDetail" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>全區HitNow類別統計</a></p>
    //   <p> <a href="/ContactTypeCount" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>HitNow類別統計</a></p>
    //  */}
    //   </div>
     
    return (
      <>
        
              <div class="card m-2 p-1" >
                  <div class="card-body text-center">
                    <h5 class="card-title">管理者功能</h5>
                    <p> <a href="/ContactAmountType" class="btn btn-danger m-1" style={{width:"100%",maxWidth:"300px"}}>HitNow數統計</a></p>
              
                    <DistrictPart/>
                  
                    < A00Part/>
                   
              </div>
         </div>
     </>
    )
  }


export default AdminMenu;
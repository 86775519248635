import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";

import getDay from "../function/getDay.js"; //計算取得日期

var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}



//全區登入統計
const LoginCount = (props) => {
  let param = useParams();
  //let level=param.level
  //console.log("level:",level)
  //let level=sessionStorage.getItem('level')
  let unitID = sessionStorage.getItem("unitID");
  let districtID = sessionStorage.getItem("districtID");
  let token = sessionStorage.getItem("token");

  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitList = new Set(manageUnit);
  console.log("unitList", unitList);
  //let districtManager=sessionStorage.getItem('districtManager').split(",") //管理分區清單
  //let districtManager=sessionStorage.getItem('serviceUnit').split(",") //管理分區清單
  let districtList = new Set(sessionStorage.getItem("districtList").split(","));

  let unitAllList=JSON.parse(sessionStorage.getItem('unitList'))
  let unintAllListArray=[]
  for (let x in unitAllList){
    manageUnit.map(item=>{
      if (item==unitAllList[x].unitID ){
        unintAllListArray.push(unitAllList[x])
      }
    })
    
  }
  let HH={
    active:"Yes",
    brand:"HAPPYHAIR",
    districtID:"HH",
    distictName:"總公司",
    unitID:"A01",
    unitName:"總公司(營業處)"
  }
  unintAllListArray.push(HH)
  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  let manageUnitListNameArray=[]
  for (let x in manageUnitListName){
    let temp ={
      "unitName":x,
      "unitID":manageUnitListName[x]
    }
    manageUnitListNameArray.push(temp)
  }
  manageUnitListNameArray.sort((a, b) => {
    var nameA = a.unitName; // ignore upper and lowercase
    var nameB = b.unitName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  let districtListNameArray=[]
  for (let x in districtListName ){
    let temp={
    "districtName":x,
    "districtID":districtListName[x]
  }
    districtListNameArray.push( temp)
  }
  districtListNameArray.sort((a, b) => {
    var nameA = a.districtName; // ignore upper and lowercase
    var nameB = b.districtName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });
  









  let date = sessionStorage.getItem("dateStartDate") || getMonFirstDate()
  let endDate = sessionStorage.getItem("dateEndDate") || getDay(0, "-")


  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }else{
      mon=m
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }

  const [hitCount, setHitState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);

  const [unitListState, setUnitListState] = useState();
  const [districtListState, setDistrictListState] = useState();

  function getUserHitCountArg(toke, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/loginCount?token=${token}&date=${date}&endDate=${endDate}`;
      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          for (let x in json){
            if (json[x]["reservationUrl"]=="1"){
              json[x]["reservationUrl"]="Y"
            }else{
              json[x]["reservationUrl"]="N"
            }
          }
          sessionStorage.setItem("LoginCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //更新查詢
  function hangdleSummit() {

    let date = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    arrangeResult(token, date, endDate);
  }

  //整理出最後畫面
  function arrangeResult(token, date, endDate) {
        sessionStorage.setItem("dateStartDate",date)
    sessionStorage.setItem("dateEndDate",endDate)
    let resultArray = [];
    let unitListForLevel = new Set(); //分店清單，快速選擇使用
    let districtListForLevel = new Set(); //分區清單，快速選擇使用
    getUserHitCountArg(token, date, endDate).then(() => {
      let data = JSON.parse(sessionStorage.getItem("LoginCount"));

      //整理排序 用店多排序
      data.sort((a, b) => {
        var nameA = a.unitName; // ignore upper and lowercase
        var nameB = b.unitName; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        if (data[x]['unitID']!="A00"){
        let url = `/footprint/${data[x]["userID"]}`;
        console.log("here");
        let singleCode = (
          <tr>
            <td>{data[x]["districtName"]}</td>
            <td>{data[x]["unitName"]}</td>
            <td>{data[x]["title"]}</td>
            <td>
              <a
                className="text-dark"
                style={{ textDdecoration: "none" }}
                href={url}
              >
                {data[x]["name"]}
              </a>{" "}
            </td>
            <td>{data[x]["amount"]}</td>
            <td>{data[x]["reservationUrl"]}</td>
          </tr>
        );

        if (unitList.has(data[x]["unitID"])) {
          resultArray.push(singleCode);
          unitListForLevel.add(data[x]["unitName"]);
          districtListForLevel.add(data[x]["districtID"]);
        }
      }
    }
      //處理分店清單
      let unitListArray = [];
      unitListArray.push(<option>全部</option>);
      //let array = Array.from(unitListForLevel)
      for (let x in manageUnitListName) {
        let singleUnit = <option>{x}</option>;
        unitListArray.push(singleUnit);
      }

      //處理分區清單

      let distictListArray = [];
      distictListArray.push(<option>全部</option>);
      distictListArray.push(<option>總公司</option>);
      //let districtNameArray = Array.from(districtListForLevel)
      //console.log("districtNameArray",districtNameArray)
      for (let x in districtListName) {
        let singleUnit = <option>{x}</option>;
        distictListArray.push(singleUnit);
      }

      setDistrictListState("");
      setDistrictListState(distictListArray);
      setUnitListState("");
      setUnitListState(unitListArray);

      setHitState(resultArray);
    });
  }

  function arrangeResultSelectUnitName(unitID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("LoginCount"));

    data.sort((a, b) => {
      var nameA = a.unitName; // ignore upper and lowercase
      var nameB = b.unitName; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    for (let x in data) {
      if (data[x]['unitID']!="A00"){
        
      
      let url = `/footprint/${data[x]["userID"]}`;
      let singleCode = (
        <tr>
          <td>{data[x]["districtName"]}</td>
          <td>{data[x]["unitName"]}</td>
          <td>{data[x]["title"]}</td>
          <td>
            <a
              className="text-dark"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}
            </a>{" "}
          </td>
          <td>{data[x]["amount"]}</td>
          <td>{data[x]["reservationUrl"]}</td>
        </tr>
      );

      if (unitID == "全部") {
        resultArray.push(singleCode);
      } else if (data[x]["unitID"] == unitID) {
        resultArray.push(singleCode);
      }
    }
    };

    setHitState(resultArray);
  }

  function arrangeResultSelectDistrict(districtID) {
    console.log('arrangeResultSelectDistrict districtID',districtID)
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("LoginCount"));

    data.sort((a, b) => {
      var nameA = a.unitName; // ignore upper and lowercase
      var nameB = b.unitName; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    for (let x in data) {
      console.log("userID",data[x].userID)
      if (data[x]['unitID']!="A00"){
      let url = `/footprint/${data[x]["userID"]}`;
      let singleCode = (
        <tr>
          <td>{data[x]["districtName"]}</td>
          <td>{data[x]["unitName"]}</td>
          <td>{data[x]["title"]}</td>
          <td>
            <a
              className="text-dark"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}
            </a>{" "}
          </td>
          <td>{data[x]["amount"]}</td>
          <td>{data[x]["reservationUrl"]}</td>
        </tr>
      );
      if (districtID == "全部" && unitList.has(data[x]["unitID"])) {
        resultArray.push(singleCode);
      } else if (
        // data[x]["districtID"] == districtID &&
        // unitList.has(data[x]["unitID"])
        data[x]["districtID"] == districtID 
      ) {
        resultArray.push(singleCode);
      }
    }
  }

    setHitState(resultArray);
  }

  function handleChangeUnit() {
    let selectUnit = document.getElementById("unitNameSelect").value;
    arrangeResultSelectUnitName(selectUnit);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    console.log('selectDistrict',selectDistrict)

    //處理 unit option
    let unitNameSelect=document.getElementById('unitNameSelect')
   
    //先處理剩下 全部 這一個選項
      unitNameSelect.options.length=1
    if (selectDistrict=="全部"){
      let  opt2 = document.createElement("option")
          opt2.value="A01"
          opt2.text="總公司(營業處)"
          unitNameSelect.add(opt2,null)
      
      unintAllListArray.map(item=>{
      
          let  opt1 = document.createElement("option")
          opt1.value=item.unitID
          opt1.text=item.unitName
          unitNameSelect.add(opt1,null)
         
      
    })

    }else{
      unintAllListArray.map(item=>{
        if (item.districtID==selectDistrict){
          //新增option 項目
          let  opt1 = document.createElement("option")
          opt1.value=item.unitID
          opt1.text=item.unitName
          unitNameSelect.add(opt1,null)
         
      }
    })
    }
      

    arrangeResultSelectDistrict(selectDistrict);
  }

  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
      <div className="card-body text-center p-0">
        <h5 className="card-title text-muted">全區登入次數統計</h5>
        <div className=" p-0">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
            </a>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分區：
              </span>
            </div>
            <select
              class="form-control"
              id="districtSelect"
              onChange={handleChangeDistrict}
            >
              {/* {districtListState} */}
              <option>全部</option>
              <option value="HH">總公司</option>
            {districtListNameArray.map(item=>{
             
              return  <option value={item.districtID}>{item.districtName}</option>
            })}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分店：
              </span>
            </div>
            <select
              class="form-control"
              id="unitNameSelect"
              onChange={handleChangeUnit}
            >
            
              <option>全部</option>
              <option value="A01">總公司(營業處)</option>
            {unintAllListArray.map(item=>{
               return  <option value={item.unitID}>{item.unitName}</option>
            })}
            </select>
          </div>
        </div>

        <table className="table table-hover table-condensed table-striped">
          <thead>
            <tr>
              <th className="p-0 text-center">分區</th>
              <th className="p-0 text-center">分店</th>
              <th className="p-0 text-center">職稱</th>
              <th className="p-0 text-center">姓名</th>
              <th className="p-0 text-center">次數</th>
              <th className="p-0 text-center">預約啟用</th>
            </tr>
          </thead>
          <tbody>{hitCount}</tbody>
        </table>
      </div>
    </>
  );
};



export default LoginCount;
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

//function
import getDay from '../function/getDay.js'//計算日期
import loginCheck from '../function/loginCheck.js'//loginCheck
import makeHitContact from '../function/makeHitContact.js' //完成定聯Func
import makeLog from '../function/makeLog.js' //製作Log
import encodeDecode from '../function/encodeDecode.js'//加解密
import MakeContactHitnowButton from "./MakeContatctHitnowButton.js"
import clickingIcon from '../icon/clicking.svg'
import switchReservationUrl from "../function/switchReservationUrl"
var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
    //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
    url = "https://server.happyhitnow.com";
} else {
    url = "http://localhost:8080";
}

let token = sessionStorage.getItem("token");

//完成定聯元件-超過周期NotActive- 話題為 Not Active-> 目前為空白沒有內容 
//增加 提示語 '客人 XXX距離上次消費已經 213 天，找機會趕緊和客人聯繫囉！'
const MakeContatctHitnow = (props) => {
    function DateDiff(sDate1, sDate2) {
        // sDate1 和 sDate2 是 2016-06-18 格式
        var oDate1 = new Date(sDate1);
        var oDate2 = new Date(sDate2);
        var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
        return iDays + 1;
      };
    
    const $ = window.$
    //hitnow 定聯
    let shouldHitDaily = JSON.parse(sessionStorage.getItem('todayShouldHitList'))
    let clientData = JSON.parse(sessionStorage.getItem('client'))
    let param = useParams() //取得客戶ID
    let userID = sessionStorage.getItem('userID')
    let token = sessionStorage.getItem('token')
    let userName = sessionStorage.getItem('name') //設計師nickname
    let unitName = sessionStorage.getItem('unitName') //分店資訊
    let unitID = sessionStorage.getItem('unitID')
    const [reservationUrlCheckbox, setReservationUrlCheckbox] = useState('true')

    var u = navigator.userAgent;
    console.log("u:", u)
    var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
    var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
    console.log('是否是Android：', isAndroid)
    console.log('是否是iOS：', isiOS)
    let osState = null
    if (isiOS == true) {
        osState = 'iOS'
    }
    if (isAndroid == true) {
        osState = 'Android'
    }

    let clientID = ""
    let orderID = param.orderID
    console.log(orderID)
    //let reservationUrl=`http://203.69.60.193:31480/Reservation?Brand=HAPPYHAIR&storeId=${unitID}&designerName=${userName}&MemberNo=${clientID}&sourceId=3`
    // let reservationUrl = `https://su.happyhitnow.com/${userName}/${unitID}/${clientID}`
    let surveyUrl = `http://survey1.happyhitnow.com/${orderID}/${userID}`
    console.log('surveyUrl', surveyUrl)
    let reservationUrl = ``
    let name = ""
    let phone = ""
    let birthday = ""
    let lastDate = ""
    let serviceList = ""
    let note = ""
    let newClient = ""
    let orderDate=""
    let serviceIDList = ""
    let gender = ""
    let topicContent = "" //話題文字串
    let contactType = "userHit"//判別目前所在的階段
    let state = "自主關心追蹤"//階段顯示文字
    // let orderID = ""
    let birthdayTag = ""
    let today = getDay(0, "/")
    let todayForBirthday = today.substr(5, 5)
    for (let x in clientData) {
        //console.log(clientData[x])
        //console.log(clientData[x]["clientID"],clientID)
        if (clientData[x]["orderID"] == orderID) {
            clientID = clientData[x]["clientID"]
            name = clientData[x]["name"]
            birthdayTag = clientData[x]["birthdayTag"]
            phone = clientData[x]["phone"]
            lastDate = clientData[x]["orderDate"]
            orderDate = clientData[x]['orderDate'].replaceAll('/', '-')
            birthday = clientData[x]["birthdayYear"] + "/" + clientData[x]["birthdayMonth"] + "/" + clientData[x]["birthdayDay"]
            console.log("note", clientData[x]["note"])
            note = clientData[x]["note"]
            console.log('clientData[x]["serviceList"].length', clientData[x]["serviceList"].length)
            if (clientData[x]["serviceList"].length == 0) {
                serviceList = clientData[x]["productSale"]
            } else if (clientData[x]["serviceList"].length == 1) {
                serviceList = clientData[x]["serviceList"]
            } else {
                serviceList = clientData[x]["serviceList"].toString().replaceAll(",", " / ")
            }

            //處理空單時的serviceList 顯示

            serviceIDList = clientData[x]['serviceIDList']
            gender = clientData[x]["gender"]
            orderID = clientData[x]["orderID"]

            newClient = clientData[x]["newClient"]
            console.log('newClient', newClient)
            for (let y in shouldHitDaily) {
                if (shouldHitDaily[y].orderID == orderID) {
                    contactType = shouldHitDaily[y].contactType
                }
            }

            reservationUrl = `https://su.happyhitnow.com/${userName}/${clientData[x]["unitID"]}/${clientID}`
            break
        }
    }



    //處理話題
    let service = new Set(serviceIDList);//服務品項
    let topicData = JSON.parse(sessionStorage.getItem('topic'))

    for (let i in topicData) {
        if (service.has(topicData[i]['serviceID'])) {
            //需判斷為何階段
            switch (contactType) {
                case "Follow":
                    topicContent = topicData[i]['follow']
                    state = "關心追蹤"
                    break;

                case "Reservation":
                    topicContent = topicData[i]['reservation']
                    state = "安排預約"
                    break;
                case "Cycle":
                    topicContent = topicData[i]['cycle']
                    state = "即將超過周期"
                    break;

                case "Refollow":
                    topicContent = topicData[i]['refollow']
                    state = "再次追蹤"
                    break;

                case "NotActive":
                    topicContent = topicData[i]['notActive']
                    state = "流失客"
                    break;
                default:
                    topicContent = topicData[i]['notActive']
                    state = "自主關心追蹤"
                    break;
            }


            break;



        }
        




    }

   

    const [topicState, setTopicState] = useState(topicContent)
    useEffect(() => {
        console.log('newClient',newClient,"contactType",contactType)
        let temp = topicState.replaceAll('[name]', name)
        if (newClient=="1" && contactType=="Follow"){
            //如果是新客 且 在關心時
            temp=`哈囉～${name}這幾天頭髮還好整理嗎？如果有需要調整的地方再跟我說囉，對了！幾天前在店裡有和你提到的服務滿意度問卷，再麻煩幫我填囉。謝謝😉`
        }
        // let temp = topicState.replaceAll('[name]', name)
        temp = temp.replaceAll('[unit]', unitName + "店")
        temp = temp.replace('[nickname]', userName)
        temp = temp.replace('null', ' ')
        temp = temp.replace('[time]', lastDate)
        temp = temp.replaceAll('[service]', serviceList)

        setTopicState(temp)
    }, 0)


    //hit按鍵
    let tempHit = <div className="m-1 btn btn-dark" style={{ width: "100%" }} >請先完成聯絡動作</div>
    const [hitButtonState, setHitButtonState] = useState(tempHit)
    let hitTypeState = ""
    function HitButton(props) {
        return hitButtonState
    }
    //定聯按鍵顯示
    let recordButtonAction = <div className="m-1 btn btn-danger" style={{ width: "100%" }} onClick={makeContactRecord}>完成 {name} H.I.T</div>


    function Survey(props) {

        if (props.newClient == "1" || props.contactType == "Refollow") {

            return (
                <>
                    <div className="card mb-2">
                        <div className="card-body alert-danger  border-1 p-2 ">問卷</div>
                        <div className="card-body border-1" style={{ width: "100%" }}>
                            測試    https://hitnow2-survey-hn4c6sz7cq-de.a.run.app
                        </div>
                    </div>
                </>
            )
        } else {
            return null
        }
    }

    function lineHandler(props) {
        //顯示定聯按鈕
        let recordNote = "line " + clientID
        makeLog(userID, recordNote)
        //makeLog(userID,"line")
        console.log("hitTypeState", hitTypeState)
        hitTypeState = "line"
        let survey = checkNewClientForSurveyUrl(orderID)
        // makeHitContact(userID, clientID, orderID, contactType, "line", newClient)


        fetch(
            `${url}/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&contacttype=${contactType}&hittype=${hitTypeState}&newclient=${newClient}&token=${token}`,
            {
                mode: "cors",
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((json) => {

                setHitButtonState(recordButtonAction)
                let lineURLTxt = topicState + "-你的專屬預約網址:" + reservationUrl
                let inlineCheckbox1 = document.getElementById('inlineCheckbox1').checked
                if (survey) {
                    if (inlineCheckbox1 == true) {
                        lineURLTxt = topicState +survey
                        switchReservationUrl(userID, "1")
                    } else {
                        lineURLTxt = topicState + survey
                        switchReservationUrl(userID, "0")
                    }
                } else {
                    if (inlineCheckbox1 == true) {
                        lineURLTxt = topicState + "-你的專屬預約網址:" + reservationUrl
                        switchReservationUrl(userID, "1")
                    } else {
                        lineURLTxt = topicState
                        switchReservationUrl(userID, "0")
                    }
                }
                
                window.location.href = `http://line.naver.jp/R/msg/text/?${lineURLTxt}`

            
                return true


            })
            .catch((e) => {
                console.log("false:", e)
                alert('伺服器小忙 請稍等再Hit')
                return false

            })


    }

    function smsHandler(props) {
        //顯示定聯按鈕
        var u = navigator.userAgent;
        console.log("u:", u)
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        console.log('是否是Android：', isAndroid)
        console.log('是否是iOS：', isiOS)

        let recordNote = "sms " + clientID
        makeLog(userID, recordNote)
        //makeLog(userID,"sms")
        console.log("hitTypeState", hitTypeState)
        hitTypeState = "sms"
        let decodePhone = encodeDecode(phone, 'decode')
        // let result=makeHitContact(userID, clientID, orderID, contactType, "sms", newClient)
        //    console.log(result)
        let survey = checkNewClientForSurveyUrl(orderID)
        console.log("survey", survey)
        fetch(
            `${url}/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&contacttype=${contactType}&hittype=${hitTypeState}&newclient=${newClient}&token=${token}`,
            {
                mode: "cors",
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((json) => {

                console.log("hitDone", json);
                setHitButtonState(recordButtonAction)

                let URLTxt = null
                let inlineCheckbox1 = document.getElementById('inlineCheckbox1').checked
                console.log('survey inside ', survey)

                if (survey) {

                    if (inlineCheckbox1 == true) {
                        URLTxt = topicState + survey
                        switchReservationUrl(userID, "1")
                    } else {
                        URLTxt = topicState + survey
                        switchReservationUrl(userID, "0")
                    }
                } else {
                    if (inlineCheckbox1 == true) {
                        URLTxt = topicState + "-你的專屬預約網址:" + reservationUrl
                        switchReservationUrl(userID, "1")
                    } else {
                        URLTxt = topicState
                        switchReservationUrl(userID, "0")
                    }
                }

                console.log("URLTxt", URLTxt)
                if (isiOS == true) {
                    //ios
                    window.location.href = `sms:${decodePhone}&body=${URLTxt} `
                } else {
                    //android
                    window.location.href = `sms:${decodePhone}?body=${URLTxt} `
                    //寫入 sms  action  log
                }
                return true


            })
            .catch((e) => {
                console.log("false:", e)
                alert('伺服器小忙 請稍等再Hit')
                return false

            })











    }
    function right(str, num) {
        return str.substring(str.length - num, str.length)
    }


    //預約元件
    function ReservationPart(props) {

        console.log("ReservationPart", props.contactType)

        let newClientFlag = clientData.find(item => {
            return item.orderID == orderID && item.newClient == "1"
        })
        if (props.contactType == "Follow" && newClientFlag) {
            //Follow 且為 newClient時出現 問卷連結

            console.log('inside newClient', newClient)

            return <>
                <div>問卷連結：</div>
                <div className="text-muted" id="surveyUrl">{surveyUrl}</div>
            </>


        } else {
            return (
                <>
                    {/* <br />預約連結:<br /> */}
                    <div>預約連結：</div>
                    <div className="text-muted" id="reservationUrl">  {reservationUrl}</div>
                </>
            )
        }



    }

    function Reminder(props) {
        const today = getDay(0, "-")
        
    
        let diffDays = DateDiff(orderDate, today)
      
            return (

                <div className="card mb-2">
                    <div className="card-body alert-danger  border-1 p-2 ">提醒事項</div>
                    <div className="card-body border-1" style={{ width: "100%" }}>
                    客人 {name}距離上次消費已經 <span className="text-danger"> {diffDays}天</span>，找機會趕緊和客人聯繫囉！
                        
                        </div>
                </div>
            )
        


    }

    function HappyBirthday(props) {
        //生日祝福提示
        if (todayForBirthday == props.birthday.substr(5, 5)) {
            topicContent = "test"
            state = "生日"
            return <div className="card mb-2">
                <div className="card-body alert-danger  border-1 p-2 ">生日祝福提示</div>
                <div className="card-body border-1" style={{ width: "100%" }}>
                    今天是 {name} 生日<br />
                記得祝福她/他 生日快樂哦
                </div>
            </div>
        } else {
            return null
        }
    }

    function getUrlToClipboard() {
        const el = document.createElement('textarea');
        console.log('topicState', document.getElementById('topicInside').innerText)
        el.value = document.getElementById('topicInside').innerText
        document.body.appendChild(el);
        el.select();
        const copyStatus = document.execCommand('copy');
        document.body.removeChild(el);
        var msg = copyStatus ? `給 ${name} 的話題已複製` : 'failed';
        alert(msg)
    }


    function phoneHandler(props) {
        //顯示定聯按鈕
        let recordNote = "phone " + clientID
        makeLog(userID, recordNote)
        //makeLog(userID,"phone")
        hitTypeState = "phone"
        // makeHitContact(userID, clientID, orderID, contactType, "phone", newClient)
        // setHitButtonState(recordButtonAction)

        //寫入 phone  action  log

        fetch(
            `${url}/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&contacttype=${contactType}&hittype=${hitTypeState}&newclient=${newClient}&token=${token}`,
            {
                mode: "cors",
            }
        )
            .then((res) => {
                return res.json();
            })
            .then((json) => {
                setHitButtonState(recordButtonAction)
                let inlineCheckbox1 = document.getElementById('inlineCheckbox1').checked
                if (inlineCheckbox1 = true) {

                    switchReservationUrl(userID, "1")
                } else {

                    switchReservationUrl(userID, "0")
                }
                console.log("hitDone", json);
                let decodePhone = encodeDecode(phone, 'decode')
                window.location.href = `tel:${decodePhone}`
                return true


            })
            .catch((e) => {
                console.log("false:", e)
                alert('伺服器小忙 請稍等再Hit')
                return false

            })



    }



    function makeContactRecord(props) {
        //console.log("user",userID,"client:",clientID,"ordre:",orderID,"hitTypeState:",hitTypeState)
        let recordNote = "makeContactRecord " + clientID
        makeLog(userID, recordNote)
        // makeHitContact(userID, clientID, orderID, contactType, hitTypeState, newClient)
        window.location.href = `/InitContact`
    }

    function History() {
        let history = JSON.parse(sessionStorage.getItem('historyChinese'))
        history.sort((a, b) => {

            var nameA = a.orderDate; // ignore upper and lowercase
            var nameB = b.orderDate; // ignore upper and lowercase
            if (nameA < nameB) {
                return 1;
            }
            if (nameA > nameB) {
                return -1;
            }

            // names must be equal
            return 0;
        })
        let historyResult = []
        let productSales = []//店販

        for (let x in history) {
            if (history[x]['clientID'] == clientID) {

                if (history[x]['serviceList'] != "") {
                    let singleHistory =
                        <div className="text-muted"> {history[x]['orderDate'].substring(4, 6)}/{history[x]['orderDate'].substring(6, 10)}  {history[x]['serviceList']} </div>
                    historyResult.push(singleHistory)
                }

                console.log('productSale', history[x]['productSale'])
                if (history[x]['productSale'] != "") {
                    let singleProduct = <>
                        <div className="text-muted"> {history[x]['orderDate'].substring(4, 6)}/{history[x]['orderDate'].substring(6, 10)}  {history[x]['productSale'].replaceAll(",", " / ").replaceAll("&lt;", "<").replaceAll("&gt;", ">")} </div>
                    </>
                    productSales.push(singleProduct)
                }

            }
        }
        console.log("historyResult.length", historyResult.length)
        historyResult.sort()
        let result = null
        if (historyResult.length > 0 || productSales.length > 0) {
            result = <>
                <div className="card">
                    <div className="card-body alert-warning   border-1 p-2 ">燙染設計師記錄</div>
                    <div className="card-body border-1" style={{ width: "100%" }}>
                        {history.map(item => {
                            if (item.clientID == clientID) {
                                return <p className="text-muted">{item.note}</p>
                            }
                        })}

                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-body alert-dark  border-1 p-2 ">半年內消費記錄</div>
                    <div className="card-body border-1" style={{ width: "100%" }}>
                        {historyResult}
                    </div>
                </div>

                <div className="card mt-2">
                    <div className="card-body alert-dark  border-1 p-2 ">半年內店販記錄</div>
                    <div className="card-body border-1" style={{ width: "100%" }}>
                        {productSales}
                    </div>
                </div>
            </>
        } else {
            result = <>
                <div className="card">
                    <div className="card-body alert-warning   border-1 p-2 ">燙染設計師記錄</div>
                    <div className="card-body border-1" style={{ width: "100%" }}>
                        {history.map(item => {
                            if (item.clientID == clientID) {
                                return <p className="text-muted">{item.note}</p>
                            }
                        })}

                    </div>
                </div>
                <div className="card mt-2">
                    <div className="card-body alert-danger  border-1 p-2 ">半年內消費記錄</div>
                    <div className="card-body border-1 text-danger" style={{ width: "100%" }}>
                        加油！ {name}只有一筆消費記錄！
                    </div>
                </div>
            </>
        }






        return (result)

    }


    function checkNewClientForSurveyUrl(orderID) {
        //Follow 且為 newClient時出現 問卷連結
        let contactTypeInside
        let shouldHitDaily = JSON.parse(sessionStorage.getItem('todayShouldHitList'))
        for (let y in shouldHitDaily) {
            if (shouldHitDaily[y].orderID == orderID) {
                contactTypeInside = shouldHitDaily[y].contactType
                break;
            }
    
        }
        
        console.log("contactTypeInside",contactTypeInside)
        if (contactTypeInside!="Follow"){
            return null
        }


        let newClientData = clientData.find(item => {
            return item.orderID == orderID && item.newClient == "1"
        })
        
        let surveyUrl = null
        if (!newClientData){
            return null
        }
        if (newClientData.newClient=="1") {
            surveyUrl = `-客戶滿意度問卷調查-http://survey1.happyhitnow.com/${newClientData.orderID}/${newClientData.userID}`
        }
        return surveyUrl
    }

    useEffect(() => {

        let reservationUrl = sessionStorage.getItem("reservationUrl")

        if (reservationUrl == "1") {

            $('#inlineCheckbox1').bootstrapToggle('on')
        } else {
            $('#inlineCheckbox1').bootstrapToggle('off')
        }



    }, [])


    return (
        <>
 <div  style={{"maxWidth":"450pt"}}>
            {/* 客戶基本資料 */}
            <div className="card mt-3">
                <div className="card-body">
                    <h5 className="card-title">客戶資料</h5>
                    <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                            <span className="input-group-text alert-info border-1">姓名</span>
                        </div>
                        <div className="form-control border-1" style={{ fontSize: "3" }}>{name} </div>

                    </div>

                    <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                            <span className="input-group-text alert-info border-1">生日</span>
                        </div>
                        <div className="form-control border-1" style={{ fontSize: "3" }}>{birthday} </div>
                    </div>



                    <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                            <span className="input-group-text alert-info  border-1">消費日期</span>
                        </div>
                        <div className="form-control border-1" style={{ fontSize: "3" }}>{lastDate} </div>
                    </div>

                    <div className="card mb-2">
                        <div className="card-body alert-info   border-1 p-2 ">最後消費項目</div>
                        <div className="card-body border-1" style={{ width: "100%" }}>
                            {serviceList}
                        </div>
                    </div>


                    <History />

                </div>
            </div>



            <div className="card mt-3">
                <div className="card-body">
                    <h5 className="card-title">聯絡議題</h5>
                    <span className="card-text">
                        <div className="input-group mb-2 border-0">
                            <div className="input-group-prepend ">
                                <span className="input-group-text alert-warning  border-1">消費階段</span>
                            </div>
                            <div className="form-control border-1" style={{ fontSize: "3" }}>{state}</div>
                        </div>
                        <HappyBirthday birthday={birthday} />
                        {/* 問卷 */}
                        {/**
                        <Survey newClient={newClient} contactType={contactType}/>
                        */}
                        {/* 提醒 */}

                        <Reminder contactType={contactType} />
                        {/* topic */}
                        <div className="card mb-2" onClick={() => { return }}>
                            <div className="card-body alert-warning  border-1 p-2 ">話題內容 </div>

                            <div className="card-body border-1" style={{ width: "100%" }} >
                                <textarea className="shadow-textarea form-control" rows="6" id="topicInside" value={topicState} onChange={(e) => {
                                    let value = e.target.value;
                                    setTopicState(value)
                                }}></textarea>
                                <br />

                                <div className="form-check form-check-inline   ml-0 ">
                                    <label for="inlineCheckbox1" className="form-check-label text-muted font-weight-bold py-1"> 啟用預約連結  </label>
                                    <input id="inlineCheckbox1" className="form-check-input " type="checkbox" data-toggle="toggle" data-style="ml-3" data-size="xs" data-onstyle="warning" data-offstyle="dark" />
                                </div>
                                <hr />
                                <ReservationPart contactType={contactType} />

                            </div>

                        </div>


                    </span>
                </div>
            </div>

            {/* 定聯 */}
            <div className="card mt-3" >
                <div className="card-body text-left">
                    <h5 className="text-info text-left">{osState} 系統</h5>
                    <div className="m-1 btn alert-success" style={{ width: "100%" }} onClick={lineHandler}>Line 聯絡</div>
                    <div className="m-1 btn alert-danger" style={{ width: "100%" }} onClick={smsHandler}>簡訊聯絡</div>
                    <div className="m-1 btn alert-info" style={{ width: "100%" }} onClick={phoneHandler}>電話聯絡</div>

                    <hr />
                    <HitButton />
                    <div className="m-1 btn alert-secondary text-dark active" role="button" style={{ width: "100%" }} aria-pressed="true" onClick={() => { window.location.href = "/" }}>回上一頁</div>

                </div>
            </div>
</div>
            {/* <MakeContactHitnowButton hitType="line"/> */}
        </>
    )

};



export default MakeContatctHitnow;
import React ,{useState,useEffect}from 'react';
import { Link ,useParams} from 'react-router-dom';

//function
import getDay from '../function/getDay.js'//計算日期
import loginCheck from '../function/loginCheck.js'//loginCheck
import makeHitContact from '../function/makeHitContact.js' //完成定聯Func
import makeLog from '../function/makeLog.js' //製作Log
import encodeDecode from '../function/encodeDecode.js'//加解密



//完成生日定聯元件
const MakeContatctHitnowBirthday=(props)=>{
    //hitnow 定聯
    let clientData=JSON.parse(sessionStorage.getItem('client'))
    let param=useParams() //取得客戶ID
    let userID=sessionStorage.getItem('userID')
    let token=sessionStorage.getItem('token')
    let userName=sessionStorage.getItem('name') //設計師nickname
    let unitName=sessionStorage.getItem('unitName') //分店資訊
    let note=""
    let unitID=sessionStorage.getItem('unitID')
    let clientID=""
    let orderID=param.orderID
    //let reservationUrl=`http://203.69.60.193:31480/Reservation?Brand=HAPPYHAIR&storeId=${unitID}&designerName=${userName}&MemberNo=${clientID}&sourceId=3`
    let  reservationUrl=`https://member.happyhair.com.tw/reservation?storeId=${unitID}&employeeId=${userID}`

    let name=""
    let phone=""
    let birthday=""
    let lastDate=""
    let serviceList=""
    let newClient=""
    let serviceIDList=""
    let gender=""
    let topicContent=" Hello [name] 祝你生日快樂唷！" //話題文字串
    let contactType="userHit"//判別目前所在的階段
    let state="自主關心追蹤"//階段顯示文字 -》生日顯示自主關心追蹤
    // let orderID=""
    let birthdayTag=""
    let today=getDay(0,"/")
    let todayForBirthday=today.substr(5,5)
    for (let x in clientData){
        //console.log(clientData[x]["clientID"],clientID)
        if (clientData[x]["orderID"]==orderID){
            name=clientData[x]["name"]
            clientID=clientData[x]["clientID"]
            birthdayTag=clientData[x]["birthdayTag"]
            phone=clientData[x]["phone"]
            lastDate=clientData[x]["orderDate"]
            birthday=clientData[x]["birthdayYear"]+"/"+clientData[x]["birthdayMonth"]+"/"+clientData[x]["birthdayDay"]
            note=clientData[x]["note"]
            if(clientData[x]["serviceList"].length==0 ){
                serviceList=clientData[x]["productSale"]
            }else if (clientData[x]["serviceList"].length == 1) {
                serviceList = clientData[x]["serviceList"]
            } else {
                serviceList = clientData[x]["serviceList"].toString().replaceAll(",", " / ")
            }
            serviceIDList=clientData[x]['serviceIDList']
            gender=clientData[x]["gender"]
            orderID=clientData[x]["orderID"]

            newClient=clientData[x]["newClient"]
        
            
            
            break
        }
    }
    
    
    //處理話題
    let service = new Set(serviceIDList);//服務品項
    let topicData=JSON.parse(sessionStorage.getItem('topic'))
    

    
    const [topicState,setTopicState]=useState(topicContent)
    useEffect(()=>{
        let temp=topicState.replaceAll('[name]',name)
        temp=temp.replaceAll('[unit]',unitName+"店")
        temp=temp.replace('[nickname]',userName)
        temp=temp.replace('null','話題待建立')
        setTopicState(temp)
    },0)
    
    
    //hit按鍵
    let tempHit=<button type="button" className="m-1 btn btn-dark" style={{width:"100%"}} >請先完成聯絡動作</button>
    const [hitButtonState,setHitButtonState]=useState(tempHit)
    let hitTypeState=""
    function HitButton(props){
        return hitButtonState
    }
    //定聯按鍵顯示
    let recordButtonAction= <button type="button" className="m-1 btn btn-danger" style={{width:"100%"}} onClick={makeContactRecord}>完成 {name} H.I.T</button>
    
    
    
    
    function lineHandler(props){
        //顯示定聯按鈕
        let recordNote="line "+clientID
        makeLog(userID,recordNote)
      //makeLog(userID,"line")
      console.log("hitTypeState",hitTypeState)
      hitTypeState="line"
      makeHitContact(userID,clientID,orderID,contactType,"line",newClient)
      setHitButtonState(recordButtonAction)
     
        window.location.href = `http://line.naver.jp/R/msg/text/?${topicState}`
        //寫入 line  action  log
    
        
    }
    
    function smsHandler(props){
        //顯示定聯按鈕
        var u = navigator.userAgent;
        var isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //ios终端
        console.log('是否是Android：',isAndroid)
       console.log('是否是iOS：',isiOS)
      
       let recordNote="sms "+clientID
        makeLog(userID,recordNote)
      //makeLog(userID,"sms")
      console.log("hitTypeState",hitTypeState)
      hitTypeState="sms"
      let decodePhone=encodeDecode(phone,'decode')
      makeHitContact(userID,clientID,orderID,contactType,"sms",newClient)
      setHitButtonState(recordButtonAction)
        if (isiOS==true){
        //ios
        window.location.href = `sms:${decodePhone}&body=${topicState}`
        }else{
        //android
        window.location.href = `sms:${decodePhone}?body=${topicState}`
        //寫入 sms  action  log
        }
        
        
    }
    function right(str, num)
    {
        return str.substring(str.length-num,str.length)
    }
    
    function HappyBirthday(props){
    //生日祝福提示
        if (birthdayTag=="yes"){
            topicContent="test"
            state="生日"
            return  <div className="card mb-2">
            <div className="card-body alert-danger  border-1 p-2 ">生日祝福提示</div>
              <div className="card-body border-1" style={{width:"100%"}}>
              今天是 {name} 生日<br/>
                記得祝福她/他 生日快樂哦
                </div>
        </div> 
        }else{
            return null
        }
    }
    
    
    function phoneHandler(props){
        //顯示定聯按鈕
        let recordNote="phone "+clientID
        makeLog(userID,recordNote)
      //makeLog(userID,"phone")
      hitTypeState="phone"
      makeHitContact(userID,clientID,orderID,contactType,"phone",newClient)
      setHitButtonState(recordButtonAction)
      let decodePhone=encodeDecode(phone,'decode')
        window.location.href = `tel:${decodePhone}`
        //寫入 phone  action  log
    
        
    }

     //預約元件
     function ReservationPart(props){
       
        
        
        if (props.contactType!="follow"){
            return(
                <>
                {/*
                
                     <div className="card mb-2">
                    <div className="card-body alert-info   border-1 p-2 ">預約專屬連結</div>
                      <div className="card-body border-1" style={{width:"100%"}}>
                        <a href={reservationUrl}  className="text-mute" style={{ textDecoration: 'none' }}>{reservationUrl}</a>
                        </div>
                </div> 
                */}
                
                <div className="text-muted"><br/>預約連結:<br/>{reservationUrl}</div>
                </>
            )
            
        }else{
            return null
        }
        

    }

    function HappyBirthday(props){
        //生日祝福提示
            
                return  <div className="card mb-2">
                <div className="card-body alert-danger  border-1 p-2 ">壽星當月優惠提示</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                  記得提醒 {name} 生日當月，可享專屬折扣優惠<br/>
                 詳情請留意公司當月活動，並提早安排預約回店消費。
                    </div>
            </div> 
       
        }
        
    
    function makeContactRecord(props){
        //console.log("user",userID,"client:",clientID,"ordre:",orderID,"hitTypeState:",hitTypeState)
        let recordNote="makeContactRecord "+clientID
        makeLog(userID,recordNote)
        makeHitContact(userID,clientID,orderID,contactType,hitTypeState,newClient)
        window.location.href = `/InitContact`
    }
    
    function History(){
        let history=JSON.parse(sessionStorage.getItem('historyChinese'))
        history.sort((a,b)=>{
    
            var nameA = a.orderDate; // ignore upper and lowercase
            var nameB = b.orderDate; // ignore upper and lowercase
            if (nameA < nameB) {
              return 1;
            }
            if (nameA > nameB) {
              return -1;
            }
      
        // names must be equal
        return 0;
          })
        let historyResult=[]
        let productSales=[]//店販

        for (let x in history){
            if (history[x]['clientID']==clientID){
                
             if (history[x]['serviceList']!="" ){
                let singleHistory=
                <div className="text-muted"> {history[x]['orderDate'].substring(4,6)}/{history[x]['orderDate'].substring(6,10)}  {history[x]['serviceList']} </div>
                historyResult.push(singleHistory)
             }
                
                console.log('productSale',history[x]['productSale'])
                if (history[x]['productSale']!=""){
                    let singleProduct=<>
                    <div className="text-muted"> {history[x]['orderDate'].substring(4,6)}/{history[x]['orderDate'].substring(6,10)}  {history[x]['productSale'].replaceAll(","," / ").replaceAll("&lt;","<").replaceAll("&gt;",">")} </div>
                    </>
                    productSales.push(singleProduct)
                }
                
            }
        }
        historyResult.sort()
        let result=null
        if (historyResult.length >0 ){
            result = <>
    
            <div className="card mt-2">
                <div className="card-body alert-dark  border-1 p-2 ">半年內消費記錄</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                        {historyResult}   
                    </div>
            </div> 

            <div className="card mt-2">
                <div className="card-body alert-dark  border-1 p-2 ">半年內店販記錄</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                        {productSales}   
                    </div>
            </div> 
            </>
        }else{
            result = <>
    
            <div className="card mt-2">
                <div className="card-body alert-danger  border-1 p-2 ">半年內消費記錄</div>
                  <div className="card-body border-1 text-danger" style={{width:"100%"}}>
                       加油！ {name}只有一筆消費記錄！
                    </div>
            </div> 
            </>
        }

        
       
       
        
       
        return (result)
        
    }
       
    
    return (
    <>
    
    {/* 客戶基本資料 */} 
    <div  style={{"maxWidth":"450pt"}}>
        <div className="card mt-3">
            <div className="card-body">
                <h5 className="card-title">客戶資料</h5>
                <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                        <span className="input-group-text alert-info border-1">姓名</span>
                        </div>
                    <div className="form-control border-1" style={{fontSize:"3"}}>{name} </div>
             </div>
                     
             <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                        <span className="input-group-text alert-info border-1">生日</span>
                        </div>
                    <div className="form-control border-1" style={{fontSize:"3"}}>{birthday} </div>
             </div>
    
             
    
             <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                        <span className="input-group-text alert-info  border-1">消費日期</span>
                        </div>
                    <div className="form-control border-1" style={{fontSize:"3"}}>{lastDate} </div>
             </div>
             
             

             <div className="card mb-2">
                <div className="card-body alert-info   border-1 p-2 ">最後消費項目</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                        {serviceList}    
                    </div>
            </div> 
            <div className="card mb-2">
                <div className="card-body alert-warning   border-1 p-2 ">燙染設計師記錄</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                        {note}    
                    </div>
            </div> 

            <History/>
    
             </div>
            </div>
    
           {/* topic */} 
           <div className="card mt-3">
            <div className="card-body">
                <h5 className="card-title">聯絡議題</h5>
                <span className="card-text">
                    <div className="input-group mb-2 border-0">
                        <div className="input-group-prepend ">
                        <span className="input-group-text alert-warning  border-1">消費階段</span>
                        </div>
                    <div className="form-control border-1" style={{fontSize:"3"}}>{state}</div>
                </div>
                <HappyBirthday />
                <div className="card">
                <div className="card-body alert-warning  border-1 p-2 ">話題內容</div>
                  <div className="card-body border-1" style={{width:"100%"}}>
                  <textarea className="shadow-textarea form-control" rows="3" id="topicInside" value={topicState} onChange={(e)=>{
                                   let value=e.target.value;
                                   setTopicState(value)
                               }}></textarea> 
                  <br/>
                  <ReservationPart contactType={contactType}/>
                    </div>
            </div> 
                   
                </span>
            </div>
        </div>
    
         {/* 定聯 */} 
         <div className="card mt-3" >
            <div className="card-body">
                <h5 className="card-title">聯絡動作</h5>
                <div  className="m-1 btn alert-success" style={{width:"100%"}}  onClick={lineHandler}>用 Line 聯絡</div>
                <div  className="m-1 btn alert-danger" style={{width:"100%"}}  onClick={smsHandler}>簡訊聯絡</div>
                <div  className="m-1 btn alert-info" style={{width:"100%"}}  onClick={phoneHandler}>電話 Call 客</div>
               
                <hr/>
                    <HitButton/>
                    <div  className="m-1 btn alert-secondary text-dark active" role="button" style={{ width: "100%" }} aria-pressed="true" onClick={()=>{window.location.href="/"}}>回上一頁</div>
               
            </div>
        </div>
    </div>
        
    </>
    )
    
    };
    


export default MakeContatctHitnowBirthday;
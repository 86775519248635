import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import {TodayCounting,ContactPart,BirthdayContactPart,TodayHistory} from '../components';
import Fuse from 'fuse.js'


//function 
import loginCheck from '../function/loginCheck.js'//確認是否登入中
import getDay from '../function/getDay.js'//計算取得日期
import BirthdayContactPartSingleDay from '../function/birthdayContactPartSingleDay' 

//Icon
import searchIcon from '../icon/search-circle.svg'
import textSvg from '../icon/text.svg'
import phoneSvg from '../icon/phone-8.svg'
import makeLog from '../function/makeLog.js'
//import starIcon from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import logo from '../icon/HAPPYHAIR_w.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'



const BirthdayClients = (props) => {
  
    var imgStyle = {
      width:'25px',
      height:'25px',
      lineHeight:'45px',
    };
  
    let nextMonth=new Date().getMonth()+1
    let nextTwoMoth=0
    if (nextMonth>11){
      nextTwoMoth=1
    }else{
      nextTwoMoth=nextMonth+1
    }
    
    let clientData=JSON.parse(sessionStorage.getItem('client'))

    // clientData.sort((a, b) => {
    //   var nameA = a.birthday;
    //   var nameB = b.birthday;
    //   if (nameA < nameB) {
    //     return -1;
    //   }
    //   if (nameA > nameB) {
    //     return 1;
    //   }
    //   return 0;
    // });
    // console.log("birthday",clientData)
    let data=[] //顯示的資料 提供給 fuse 
    let sortData={}
    for (let x in clientData){
  
      if (clientData[x]['birthdayMonth']==nextMonth || clientData[x]['birthdayMonth']==nextTwoMoth){
        clientData[x]['birthday']=clientData[x]['birthdayMonth']+"/"+clientData[x]['birthdayDay']
        console.log(clientData[x])
        let objectData=clientData[x]
        data.push(objectData)
        let key=(clientData[x]['birthdayMonth']+"/"+clientData[x]['birthdayDay']+x)
        sortData[key]=clientData[x]
      }
      
    }
  console.log(data)
    let clientList=[]
  
    //排序
  
    // var sortable = [];
    // for (var x in data) {
    //   let temp=data[x]['birthday']
    //     sortable.push([data[x]['clientID'], temp.substring(temp.length-5)])
    // }
  
    //   sortable.sort(function(a, b) {
    //     return a[1] - b[1];
    //   });
  
  
    const [listState,setList]=useState('')
    const [clientAmount,setAmout]=useState(Object.keys(data).length)
  

    
    
    
  
    function handleChange(e){
      let options={
        keys:['name']
      }
      let fuse= new Fuse(data, options)
      let serchName=e.target.value
     let searchResult = fuse.search(e.target.value)
     console.log("s.l:",searchResult.length )
     if (serchName.length >0 ){
  
      for (let x=0;x<searchResult.length;x++){
        let orderID=searchResult[x]['item']['orderID']
        let togo=`/contactBirthday/${orderID}`
        let userID=searchResult[x]['item']['userID']
  

        let serviceList =""
        //   if (searchResult[x]['item']['serviceList'].length==1){
        //     serviceList=searchResult[x]['item']['serviceList']
        // }else{
        //     serviceList=searchResult[x]['item']['serviceList'].toString().replaceAll(","," / ")
        // }

        if(searchResult[x]['item']["serviceList"].length==0 ){
          serviceList=searchResult[x]['item']["productSale"]
      }else if (searchResult[x]['item']["serviceList"].length == 1) {
          serviceList = searchResult[x]['item']["serviceList"]
      } else {
          serviceList = clientData[x]['item']["serviceList"].toString().replaceAll(",", " / ")
      }
        //let toEdit=`/edit/${clientID}`
  
        let singleClient=
   
        <>
       
       
          
          <Link to={togo} style={{ textDecoration: 'none' }}>
              <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={x}   >
              <div >
                <div className="ml-2 "><span className=" text-dark" >{searchResult[x]['item']['name']} </span> 
                <span  className="m-1"><GenderIcon gender={searchResult[x]['item']['gender']}/>  </span>   <Star newClient={searchResult[x]['item']['newClient']}/><br/>
                <span className="text-muted  font-weight-light" style={{fontSize:"smaller"}}> 消費日：{searchResult[x]['item']['orderDate'].substring(5)} </span> 
                <span className="text-muted ml-2" style={{fontSize:"80%"}}> 生日: {searchResult[x]['item']['birthday']} </span>
                
               
                </div>
                {/*
                 <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
                */}
                <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {serviceList}</div>
              </div>
            
               </li>
          </Link>
    
         
    </>
    
        
       
    if (searchResult[x]['item']["serviceList"].length !=0 || searchResult[x]['item']["productSale"].length !=0){
      console.log(x,searchResult[x]['item']["name"],searchResult[x]['item']["serviceList"],searchResult[x]['item']["serviceList"].length)
     clientList.push(singleClient)
    }
       
      }
  
      setList(clientList)
      setAmout(searchResult.length)
     }else if (searchResult.length==0){
      // setList()
      makeList()
       setAmout(Object.keys(data).length)
     }
      
    }
  
    function Star(props){
      //新客戶標示
      if (props.newClient=="1"){
          return <img   className="ml-1" src={starIcon} style={{width:"18px",height:"18px"}}  alt="start"/>
      }else{
          return null
      }
        }
      
  
    function MakeCall(props){
  
      //電話按鈕
      if (props.phone.length > 0){
        let phoneCall = 
        <span className="text-right  ml-2 text-success   font-weight-lighter "><a href={`tel:${props.phone}`}><span className="ml-1 text-muted text-decoration-none  font-weight-lighter " style={{fontSize:"smaller"}}><img  src={phoneSvg} style={imgStyle}  alt="edit"/> {props.phone} </span> </a></span>
        return phoneCall 
      }else{
        return null
      }
    }
  
  
  
    function GenderIcon(props){
      if (props.gender=='女'){
        let genderDot = 
        <span class="  font-weight-lighter rounded-circle alert-danger "style={{fontSize:"80%"}} >{props.gender} </span>
        return genderDot
      }else if(props.gender=='男'){
        let genderDot = 
        <span class="  font-weight-lighter rounded-circle alert-info "style={{fontSize:"80%"}} >{props.gender} </span>
        return genderDot
      }else{
        return null
      }
    }
    function makeList(){

        //取生日名單 key來排序
        
        let keyList=Object.keys(sortData)
        keyList.sort()
      console.log(keyList)
      console.log(sortData)
        for (let x in keyList){
          
          let y=keyList[x]
          let serviceList =""
          if(sortData[y]["serviceList"].length==0 ){
            serviceList=sortData[y]["productSale"]
        }else if (sortData[y]["serviceList"].length == 1) {
            serviceList = sortData[y]["serviceList"]
        } else {
            serviceList = sortData[y]["serviceList"].toString().replaceAll(",", " / ")
        }
          let toMakeContact=`/contactBirthday/${sortData[y]['orderID']}`
          //let im=clientData[x]['im'].replace(/,/g,"、")
          
      
          let singleClient=
          <>
          <Link to={toMakeContact} style={{ textDecoration: 'none' }}>
              <li className="list-group-item font-weight-lighter p-1 mb-1   " tag="button" key={y}   >
              <div >
                <div className="ml-2 "><span className=" text-dark" >{sortData[y]['name']} </span> 
                <span  className="m-1"><GenderIcon gender={sortData[y]['gender']}/>  </span>   <Star newClient={sortData[y]['newClient']}/><br/>
                <span className="text-muted  font-weight-light" style={{fontSize:"smaller"}}> 消費日：{sortData[y]['orderDate'].substring(5)}  </span> 
                <span className="text-muted ml-2" style={{fontSize:"80%"}}> 生日: {sortData[y]['birthday']} </span>
                
               
                </div>
                {/*
                 <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
                */}
                <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {serviceList}</div>
              </div>
            
               </li>
          </Link>
          </>
          
          // if (sortData[y]["serviceList"].length !=0 || sortData[y]["productSale"].length){
          //  // console.log(x,clientData[x]["name"],clientData[x]["serviceList"],clientData[x]["serviceList"].length)
          //  clientList.push(singleClient)
          // }
          clientList.push(singleClient)
        }
        setList(clientList)
       }
      
        useEffect(()=>{
                let userID=sessionStorage.getItem('userID')
              makeLog(userID,"birthdayClients")
                makeList()
            },[])
        
       
        
      
        return (
      <>
       <div  style={{"maxWidth":"450pt"}}>
          <div className="p-2  my-2  rounded ">
                      <div className="toast">
                        <div className="toast-header">
                         <h5>{nextMonth}-{nextTwoMoth}月生日客戶名單: {clientAmount} 筆</h5>
                        </div>
                        <div className="input-group mb-3">
                        <div className="input-group-prepend">
                            <span className="input-group-text" id="inputGroup-sizing-default"><img src={searchIcon} alt="searchIcon" style={{weight:"24px",height:'24px'}} />Search</span>
                        </div>
                        <input type="text" className="form-control" aria-label="Sizing example input" aria-describedby="inputGroup-sizing-default" onChange={handleChange}/>
                      </div>
                        <div className="toast-body ">
                          <ul className="list-group ">
                          {listState}
                         </ul>
                       </div>
                      </div>
          </div>
          </div>
          </>
        );
      };
export default BirthdayClients;
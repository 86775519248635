import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useForm } from "react-hook-form";
import {  Content,Modal } from 'rsuite';

//function
import getDay from '../function/getDay.js'//計算日期
const $=window.$
var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}



const UploadPicList=(e)=>{
    const [showFlag,setShowFlag] = useState(false)
    const [picList,setPicList]=useState([])
    const [fileName,setFileName]=useState(null)
    const [imgData, setImgData] = useState(null);
    const [startDate,setStartDate]=useState(getDay(0,"-"))
    const [endDate,setEndDate]=useState(getDay(30,"-"))
    const [subject,setSubject]=useState(null)
    const [imgType,setImgtype]=useState(null)
    const [msg, setMsg] = useState(null)
    const token=sessionStorage.getItem('token')

  function deletePic(id1220showPicDB){

    if (window.confirm(`確定刪除 編號:${id1220showPicDB}`)){
      fetch(`${url}/deleteUploadPic?id1220showPicDB=${id1220showPicDB}&token=${token}`).then(()=>{
        window.location.href="/UploadPicList"
      })
    }
   
  }
    function showPic(e){
       
       console.log(e.target.files[0])

       if (e.target.files[0]){
           setFileName(e.target.files[0])
           const reader = new FileReader();
           reader.addEventListener("load", () => {
               console.log(reader.result.substring(5,15))
               setImgtype(reader.result.substring(5,15))
            setImgData(reader.result);
          });
          reader.readAsDataURL(e.target.files[0]);
        }
       
    }


    const PicShow=(props)=>{


      return <>
             
              {/* <Modal  show={showFlag} style={{"width": "95%","maxWidth":"500px","minWidth":"300px"}}  backdrop={showFlag}> */}
                <Modal  show={showFlag} size="xs" style={{"width": "95%"}}  backdrop={showFlag} onHide={()=>{
                  setShowFlag(false)
                }}> 
              <Modal.Header>
                <Modal.Title className="text-center">Happy in Touch, Now</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <img src={msg} width="100%"/>
              </Modal.Body>
              <Modal.Footer>
              <div className="btn alert-info "  style={{ "width": "100pt" }} onClick={() => {
               setShowFlag(false)
               //  return
               }}>進入 HitNow</div>
               
              </Modal.Footer>
            </Modal>
           
      </>
    }

    const getTodayPicList=async()=>{
      fetch(`${url}/getTodayPicList?token=${token}`).then(res=>res.json()).then(json=>{
        let picArray=[]
        console.log(json)
        for (let x in json){
          let tempData=
           <tr>
             <td>{json[x]["id1220showPicDB"]}</td>
               <td>{json[x]["startDate"]}</td>
               <td>{json[x].endDate}</td>
               <td>{json[x].subject}</td>
               <td><div className="btn alert-light"><img src={json[x].pic} width="25px" onClick={()=>{
                    setMsg(json[x].pic)
                    setShowFlag(true)
                    // $('#remindPopMsgModal').modal('show')
               }}/></div></td>
               <td><div className="btn alert-danger" onClick={()=>{
                 deletePic(json[x]["id1220showPicDB"])
               }}>刪除</div></td>
             </tr>
          

          picArray.push(tempData)
        }
        console.log(picArray.length)
        setPicList(picArray)
      })
    }

    useEffect(async()=>{
     await getTodayPicList()
  

    },[])
  
return (<>
    <PicShow/>
    <div className="card mt-3 alert-info" >
    <div className="card-body">
      <h5 className="card-title"> HitNow Login 圖片 管理</h5>
     <div className="text-right mb-2"><div className="btn btn-danger" onClick={()=>{
       window.location.href="/UploadPic"
     }}>上傳新圖片</div></div>
      <div className="alert-light ">
        
         <table className="table table-striped">
           <thead >
           <th>編號</th>
             <th>上架起日期</th>
             <th>下架迄日期</th>
             <th>主題</th>
             <th>縮圖</th>
             <th>刪除</th>
           </thead>
           <tbody>
             {picList}
             
           </tbody>
         </table>
      </div>
     
        
    </div>
  </div>
 
         
  </>
)

}

export default UploadPicList;
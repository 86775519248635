import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';

//function
import getDay from '../function/getDay.js'//計算日期

var url = null
let env = process.env.NODE_ENV
if (env == "production") {
    //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
    url = "https://server.happyhitnow.com"
} else {
    url = "http://localhost:8080"
}



//本日統計元件
const TodayCounting = () => {

    let userID=sessionStorage.getItem('userID')
    let token=sessionStorage.getItem('token')
    const [shouldHit,setShouldHit]=useState(0)
    const [Hitcount,setHitcount]=useState(0)
    const [reservationCount,setReservationCount]=useState(0)

    //取得應hit與hit數
    function getHitCount(){
        fetch(`${url}/countShouldAndHitAmountSingleUser?token=${token}&userID=${userID}`).then(res=>{
            return res.json()
        }).then(json=>{
            console.log("getHitCount",json)
            setShouldHit(json.shouldAmount)
            setHitcount(json.hitAmount)
            setReservationCount(json.reservationAmount)
        })
    }

    

    function getMonFirstDate() {
        //計算本日第一天
        let today = new Date()
        let m = (today.getMonth() + 1).toString()
        let mon = ""
        let year = (today.getFullYear()).toString()

        if (m.length == 1) {
            mon = '0' + m;
        } else {
            mon = m
        }
        let result = year + "-" + mon + "-01"

        return result
    }

    function getWeek() {
        //計算本周一的日期
        let today = new Date()
        let day = today.getDay()

        let result = 0
        switch (day) {
            case 0:
                result = -6
                break;
            default:
                result = (1 - day)
                break;


        }

        let newData = getDay(result, "-")
        return newData
    }

    let clientData = JSON.parse(sessionStorage.getItem('client'))
    let contactData = JSON.parse(sessionStorage.getItem('contact'))
    let historyData = JSON.parse(sessionStorage.getItem('history'))



    let newData = getWeek()
    let newDatMon = getMonFirstDate()

    const clientCount = (Object.keys(clientData)).length
    let newClient = 0
    let TodayMonth = getDay(0, "").substring(4, 6)
    //let hsCount=0 //純洗客
    let hsCountMon = 0 //本月純洗客


    //以下為計算月客次
    let hsSet = new Set()//純洗客客數 （單人計一次）
    for (let x in historyData) {
        let serviceIDList = historyData[x]['serviceIDList'].split(",")
        if (historyData[x]['orderDate'].substring(4, 6) == TodayMonth) {
            newClient += 1
            if (serviceIDList.length == 1) {
                if (serviceIDList[0].substr(0, 2) == "HS") {
                    hsCountMon += 1
                }
            }
        }


        if (serviceIDList.length == 1) {
            if (serviceIDList[0].substr(0, 2) == "HS") {
                //hsCount+=1
                hsSet.add(historyData[x]['clientID'])
            }
        }
    }
    let newContactMon = 0 //本月定聯數
    let newContact = 0 //本周定聯數



    for (let x in contactData) {


        if (contactData[x]['createDate'] >= newData) {
            //console.log('yes')
            newContact += 1
        }

        if (contactData[x]['createDate'] >= newDatMon) {

            newContactMon += 1


        }


    }

    useEffect(()=>{
        getHitCount()
    },[])
    return (
        <>
            <div className=" fixed-top" style={{ marginTop: "50px", backgroundColor: "rgba(255, 255, 255, 0.7)" }} >
                <div className="p-2 container ">
                    <div className="row">
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-info text-center  text-small" style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>總客數</span><span className=" badge-pill badge badge-info">{clientCount} : {hsSet.size}</span></div>
                        </div>
                        {/*
                             <div className="col"  align="center">
                                <div className=" rounded-circle alert-warning text-center  " style={{width:"50px",height:"50px"}}><span style={{fontSize:"12px"}}>H.I.T</span><span className=" badge-pill badge badge-warning ">{newContact} : {newContactMon} : {shouldHitContact} : {shouldHit}</span></div>
                            </div>
                            */}
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-warning text-center  " style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>H.I.T</span><span className=" badge-pill badge badge-warning ">  {Hitcount} : {shouldHit} </span></div>
                        </div>
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-success text-center  " style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>已預約</span><br/><span className=" badge-pill badge badge-success  ">  {reservationCount} </span></div>
                        </div>
                        <div className="col" align="center">
                            <div className=" rounded-circle alert-danger text-center " style={{ width: "50px", height: "50px" }}><span style={{ fontSize: "12px" }}>月客次</span><span className=" badge-pill badge badge-danger">{newClient} : {hsCountMon}</span></div>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )

};




export default TodayCounting;
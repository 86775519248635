function makeHitContact(
  userID,
  clientID,
  orderID,
  contactType,
  hitType,
  newClient = "0"
) {
  //alert(`https://hitnow-v1.df.r.appspot.com/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&hittype=${hitType}`)

  var url = "";
  let env = process.env.NODE_ENV;
  if (env == "production") {
    //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
    url = "https://server.happyhitnow.com";
  } else {
    url = "http://localhost:8080";
  }

  let token = sessionStorage.getItem("token");
  console.log(
    `${url}/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&contacttype=${contactType}&hittype=${hitType}&newclient=${newClient}&token=${token}`
  );

  
  const goGet = async ()=>{
    let result=await fetch(
      `${url}/makehitcontact?userid=${userID}&clientid=${clientID}&orderid=${orderID}&contacttype=${contactType}&hittype=${hitType}&newclient=${newClient}&token=${token}`,
      {
        mode: "cors",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
  
        console.log("hitDone", json);
        return true
       
  
      })
      .catch((e) => {
        console.log("false:",e)
        alert('伺服器小忙 請稍等再Hit')
        return false
        
      })
      console.log(result)
      return result
  }
  goGet()
 
}
export default makeHitContact;

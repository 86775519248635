//生日元件當日

import React ,{useState,useEffect}from 'react';
import { Link ,useParams} from 'react-router-dom';

//function
import getDay from './getDay.js'//計算日期
import loginCheck from './loginCheck.js'//loginCheck
import makeHitContact from './makeHitContact.js' //完成定聯Func
import makeLog from './makeLog.js' //製作Log
import makeOrderContactList from './makeOrderContactList.js'//演算客戶消費定單分類
import makeHistoryServiceList from './makeHistoryServiceList.js' //演算history 品項中文化
//icon
import egg from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import birthdayCakeBlack from '../icon/birthdaycakeBlack.svg'

const BirthdayContactPartSingleDay=(props)=>{

    let componentList=props.componentList //清單
    let clientData=JSON.parse(sessionStorage.getItem('client'))
    let clientList=[]
 

  function GenderIcon(props){
    if (props.gender=='女'){
      let genderDot = 
      <span class="  font-weight-lighter rounded-circle alert-danger "style={{fontSize:"80%"}} >{props.gender} </span>
      return genderDot
    }else if(props.gender=='男'){
      let genderDot = 
      <span class="  font-weight-lighter rounded-circle alert-info "style={{fontSize:"80%"}} >{props.gender} </span>
      return genderDot
    }else{
      return null
    }
  }


 for (let y in componentList){

    let x=String(componentList[y])

    // let toMakeContact=`/contact/${clientData[x]['clientID']}`
    
 //生日
 let singleContact=
 <>
 
 
        <li className="list-group-item font-weight-lighter p-1 mb-1  border-light  bg-light" tag="button" key={x}   >
        <div >
          <div className="ml-2 "><span className=" text-dark" >{componentList[y]['name']} </span>  
          <span  className="m-1"><GenderIcon gender={componentList[y]['gender']}/>  </span>  
         
          <span className="text-muted  ml-2" style={{fontSize:"smaller"}}>  <img src={birthdayCakeBlack} style={{width:"16px"}} alt="PersonAdd"/> 生日：{componentList[y]['birthday']} </span>
         
        
          </div>
    
        
        </div>
      
         </li>
   
    </>

 clientList.push(singleContact)
 }

 if (clientList.length==0){
     return null
 }else{
    return (
        <>
        
        <div className="p-2  my-2  rounded ">
             <h4 ><span className=" badge-pill badge alert-warning"> <img src={birthdayCakeBlack} style={{width:"20px"}} alt="PersonAdd"/>  本日壽星  {clientList.length} </span> </h4>
            <div className="toast-body ">
                
           
                    <ul className="list-group ">
                    {clientList}
                     </ul>
             </div>
        </div>
   </>
    )
        
 }
     
};

export default BirthdayContactPartSingleDay;
import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';

//Icon

import logo from '../icon/HAPPYHAIR_w.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'
import cycleIcon from '../icon/warning.svg'
//功能列
const Navbar = () => {
    var imgStyle = {
        width:'30px',
        height:'30px',
      };
  
      var fontStyle = {
        fontSize:"0.5em"
      };
  
      
      //login 
      let navbarLogin=(
        
        <nav className="navbar navbar-light text-light bg-dark " >   
        
        <img src={logo} alt="logo" style={{weight:"35px",height:'35px'}}/>
  
        <h5>HitNow②</h5>
        </nav>
      )
      //一般user
    let navbarContext= (<nav className="navbar navbar-light bg-dark ">
    <div className="text-center" onClick={()=>{
      window.location.href="/InitContact"
    }}>
        {/* <Link className="navbar-brand" to="/InitContact" style={{textAlign:"center"}}> */}
        <img src={HomeSVG} style={imgStyle} alt="Home"/>
        <p className="text-light" style={fontStyle} >Home</p>
        {/* </Link> */}
    </div>
    <div className="text-center" onClick={()=>{
      window.location.href="/clients"
    }}>
        {/* <Link className="navbar-brand" to="/clients" style={{textAlign:"center"}}> */}
        <img src={PersonAdd} style={imgStyle} alt="PersonAdd"/>
         <p className="text-light" style={fontStyle}>客戶</p>
        {/* </Link> */}
     </div>
     <div className="text-center" onClick={()=>{
      window.location.href="/birthdayClients"
    }}>
        {/* <Link className="navbar-brand" to="/birthdayClients" style={{textAlign:"center"}}> */}
        <img src={birthdayCake} style={{width:"25px"}} alt="PersonAdd"/>
      <p className="text-light" style={fontStyle}>生日</p>
        {/* </Link> */}
     </div>

     <div className="text-center" onClick={()=>{
      window.location.href="/NotActive"
    }}>
        {/* <Link className="navbar-brand" to="/birthdayClients" style={{textAlign:"center"}}> */}
        <img src={cycleIcon} style={{width:"25px"}} alt="PersonAdd"/>
      <p className="text-light" style={fontStyle}>流失客</p>
        {/* </Link> */}
     </div>

     <div className="text-center" onClick={()=>{
      window.location.href="/logout"
    }}>
        {/* <Link
            className="navbar-brand " style={{textAlign:"center"}} to="/logout"  > */}
            <img src={eject} style={imgStyle} alt="eject"/>
            <p className="text-light" style={fontStyle}>登出</p>
        {/* </Link> */}
    </div> 
  </nav>)
  
    //admin
    let navbarContextAdmin= (<nav className="navbar navbar-light bg-dark ">
     <div className="text-center" onClick={()=>{
      window.location.href="/"
    }}>
        {/* <Link className="navbar-brand" to="/" style={{textAlign:"center"}}> */}
        <img src={HomeSVG} style={imgStyle} alt="Home"/>
        <p className="text-light" style={fontStyle} >Home</p>
        {/* </Link> */}
    </div>
    <div onClick={()=>{
      window.location.href="/clients"
    }}>
        {/* <Link className="navbar-brand" to="/clients" style={{textAlign:"center"}}> */}
        <img src={PersonAdd} style={imgStyle} alt="PersonAdd"/>
         <p className="text-light" style={fontStyle}>客戶</p>
        {/* </Link> */}
     </div>
     <div className="text-center" onClick={()=>{
      window.location.href="/birthdayClients"
    }}>
        {/* <Link className="navbar-brand" to="/birthdayClients" style={{textAlign:"center"}}> */}
        <img src={birthdayCake} style={{width:"25px"}} alt="PersonAdd"/>
         <p className="text-light" style={fontStyle}>生日</p>
        {/* </Link> */}
     </div>

     <div className="text-center" onClick={()=>{
      window.location.href="/NotActive"
    }}>
        {/* <Link className="navbar-brand" to="/birthdayClients" style={{textAlign:"center"}}> */}
        <img src={cycleIcon} style={{width:"25px"}} alt="PersonAdd"/>
      <p className="text-light" style={fontStyle}>流失客</p>
        {/* </Link> */}
     </div>

     <div>
        <div className="text-center" className="navbar-brand" onClick={()=>{
              window.location.href="/AdminMenu"}} style={{textAlign:"center"}}>
        <img src={adminIcon} style={{width:"25px"}} alt="PersonAdd"/>
         <p className="text-light" style={fontStyle}>管理</p>
        </div>
     </div>
    <div className="text-center">
        <Link
            className="navbar-brand " style={{textAlign:"center"}} to="/logout"  >
            <img src={eject} style={imgStyle} alt="eject"/>
            <p className="text-light" style={fontStyle}>登出</p>
        </Link>
    </div>
    
  </nav>


  
    )

    
    function timeOutLogOut(){
      setTimeout(()=>{
        alert("閒置太久，重新登入")
        window.location.href="/login"

      }, 5400000)
    }
  
    const [navContext,setNav]=useState(navbarLogin)
    useEffect(()=>{
      timeOutLogOut()
        let timeCodeNow=Date.now()
        let timeCode=sessionStorage.getItem("timeCode")
        let level=parseInt(sessionStorage.getItem("level"))
        let manageUnit=sessionStorage.getItem("manageUnit")
        if (manageUnit != null) {
          manageUnit=manageUnit.split(",")
        }
        //console.log('manageUnit',manageUnit[0])
       // console.log('manageUnit length',manageUnit.length)
        //console.log("timecode:",timeCode)
        if (timeCode-timeCodeNow > 0){
        //如果逾期則 登出
       
            if (manageUnit[0] ==""){
              setNav(navbarContext);
            }else{
                
                setNav(navbarContextAdmin)
            }
        }else{
          if (timeCode !=null){
            window.location.href="/logout"
          }
          
        }
        
  },[]);
  
    
  
      return (
        navContext
    )
  
  };

  export default Navbar;
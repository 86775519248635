import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";
import getDay from "../function/getDay.js"; //計算取得日期


var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}


//定聯統計
const ContactAmountType = (props) => {
  let param = useParams();
  let level = param.level;
  //處理清單所有店清單
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitID = sessionStorage.getItem('unitID')
  let unitAllList = JSON.parse(sessionStorage.getItem('unitList'))
  let unintAllListArray = []
  for (let x in unitAllList) {
    manageUnit.map(item => {
      if (item == unitAllList[x].unitID) {
        unintAllListArray.push(unitAllList[x])
      }
    })

  }

  let token = sessionStorage.getItem("token");



  let unitList = new Set(manageUnit);
  console.log(unitList);


  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  let manageUnitListNameArray = []
  for (let x in manageUnitListName) {
    let temp = {
      "unitName": x,
      "unitID": manageUnitListName[x]
    }
    manageUnitListNameArray.push(temp)
  }
  manageUnitListNameArray.sort((a, b) => {
    var nameA = a.unitName; // ignore upper and lowercase
    var nameB = b.unitName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  let districtListNameArray = []
  for (let x in districtListName) {
    let temp = {
      "districtName": x,
      "districtID": districtListName[x]
    }
    districtListNameArray.push(temp)
  }
  districtListNameArray.sort((a, b) => {
    var nameA = a.districtName; // ignore upper and lowercase
    var nameB = b.districtName; // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });




  //csv
  const columns = [
    {
      id: "c0",
      displayName: "分區",
    },
    {
      id: "c1",
      displayName: "分店",
    },
    {
      id: "c2",
      displayName: "員編",
    },
    {
      id: "c3",
      displayName: "設計師",
    },
    {
      id: "c4",
      displayName: "職稱代碼",
    },
    {
      id: "c5",
      displayName: "HIT數",
    },
    {
      id: "c6",
      displayName: "應Hit數",
    },
    {
      id: "c7",
      displayName: "達成率",
    },
    {
      id: "c8",
      displayName: "等級",
    },

  ];
  const [datas, setDatas] = useState();

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    } else {
      mon = m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }

  let date = sessionStorage.getItem("dateStartDate") || getMonFirstDate()
  console.log( "dateEndDate",sessionStorage.getItem("dateEndDate"))
  let endDate = sessionStorage.getItem("dateEndDate") || getDay(0, "-")

  //日期相減天數
  const DateDiff = function (sDate1, sDate2) {
    // sDate1 和 sDate2 是 2016-06-18 格式
    var oDate1 = new Date(sDate1);
    var oDate2 = new Date(sDate2);
    var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
    return iDays + 1;
  };

  const [countState, setcountState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);


  function getContactTypeCount(token, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/contactAmountType?token=${token}&date=${date}&endDate=${endDate}`;

      // console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {

          return res.json();
        })
        .then((json) => {
          console.log(json)
          for (let x in json) {
            let result = Math.round(
              (parseInt(json[x]["hitAmount"], "10") /
                parseInt(json[x]["shouldAmount"], "10")) *
              100
            )
            // console.log(json[x]['name'],result)
            if (!result) {
              json[x]["result"] = 0
            } else {
              json[x]["result"] = result
            }
          }

          console.log("//處理 分店unitID:", unitID)
          let finalResult = []
          // if (unitID == 'A00') {
          //   finalResult = json
          // } else {
          //   console.log('here')
            
          //   finalResult = json.filter((item) => {
          //     return item.title=="D01"
          //   })
           
          // }
          finalResult = json.filter((item) => {
            return item.title=="D01" || item.title=="P21" || item.title=="A10"
          })
          sessionStorage.setItem("ContactTypeCount", JSON.stringify(finalResult));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //整理出最後畫面
  function arrangeResult(token, date, endDate) {
    sessionStorage.setItem("dateStartDate",date)
    sessionStorage.setItem("dateEndDate",endDate)
    let datasArray = [];
    let resultArray = [];
    let unitListForLevel = new Set(); //分店清單，快速選擇使用
    let districtListForLevel = new Set(); //分區清單，快速選擇使用

    let totalDays = DateDiff(endDate, date);
    console.log(date, endDate, totalDays);
    getContactTypeCount(token, date, endDate).then(() => {

      let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

      //整理排序 用分區排序

      data.sort((a, b) => {
        var nameA = a.name; // ignore upper and lowercase
        var nameB = b.name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      data.sort((a, b) => {
        var nameA = a.unitName; // ignore upper and lowercase
        var nameB = b.unitName; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      data.sort((a, b) => {
        var nameA = a.result; // ignore upper and lowercase
        var nameB = b.result; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let average = parseInt(data[x]["amount"], 10) / totalDays;

        if (data[x]["shouldAmount"]==""){
          data[x]["shouldAmount"]="0"
        }

        if (data[x]["hitAmount"]==""){
          data[x]["hitAmount"]="0"
        }
        // console.log(parseInt("here average", data[x]['amount'], 10))
        let url = `/singleUserHitList/${data[x]["userID"]}`;
        let tempDatas = {
          c0: data[x]["districtName"],
          c1: data[x]["unitName"],
          c2: data[x]["userID"],
          c3: data[x]["name"],
          c4: data[x]["title"],
          c5: data[x]["hitAmount"],
          c6: data[x]["shouldAmount"],
          c7: data[x]["result"],
          c8: data[x]["userLevel"],
        };
        datasArray.push(tempDatas);
        const levelShow=data[x]["title"]=="A10"?"實":data[x]["userLevel"]
        let singleCode = (
          <tr>
            <td className="p-1 text-center">{data[x]["districtName"]} </td>
            <td className="p-1 text-center">{data[x]["unitName"]} </td>
            <td className="p-1 text-center">
              <a
                className="text-info"
                style={{ textDdecoration: "none" }}
                href={url}
              >
                {data[x]["name"]}{" "}
              </a>
            </td>
            <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
            {/* <td>{data[x]['newAmount']}</td>
       <td>{data[x]['normalAmount']}</td> */}
            <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
            <td className="py-2 text-center">
              {data[x]["result"]}
              %
            </td>
            <td className="py-0 text-center">
              {/* {data[x]["userLevel"]} */}
              {levelShow}
              {/* {data[x]["title"]} */}
              
            </td>
          </tr>
        );

        // 加上 && data[x]['level']=="1" 避免管理者的hitnow數被統計
        if (
          data[x]["districtID"] !== "TOP" &&
          unitList.has(data[x]["unitID"])
        ) {
          resultArray.push(singleCode);
          unitListForLevel.add(data[x]["unitName"]);
          districtListForLevel.add(data[x]["districtID"]);
        }
      }

      //處理分店清單
      let unitListArray = [];
      unitListArray.push(<option>全部</option>);
      //let array = Array.from(unitListForLevel)
      for (let x in manageUnitListName) {
        let singleUnit = <option>{x}</option>;
        unitListArray.push(singleUnit);
      }



      setcountState(resultArray);
      setDatas(datasArray);
    });
  }

  function arrangeResultSelectUnitName(unitID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    data.sort((a, b) => {
      var nameA = a.result; // ignore upper and lowercase
      var nameB = b.result; // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    });

    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;

   
    for (let x in data) {
      let url = `/singleUserHitList/${data[x]["userID"]}`;
      const levelShow=data[x]["title"]=="A10"?"實":data[x]["userLevel"]

      let singleCode = (
        <tr>
          <td className="p-1 text-center">{data[x]["districtName"]} </td>
          <td className="p-1 text-center">{data[x]["unitName"]} </td>
          <td className="p-1 text-center">
            <a
              className="text-info"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}{" "}
            </a>
          </td>
          <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
          <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
          <td className="py-2 text-center">
            {data[x]["result"]}
            %
          </td>
          <td className="py-0 text-center">
            {/* {data[x]["userLevel"]} */}
            {levelShow}
          </td>
        </tr>
      );

      if (unitID == "全部") {
        resultArray.push(singleCode);
      } else if (data[x]["unitID"] == unitID) {
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  function arrangeResultSelectDistrict(districtID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });
    data.sort((a, b) => {
      var nameA = a.result; // ignore upper and lowercase
      var nameB = b.result; // ignore upper and lowercase
      if (nameA < nameB) {
        return 1;
      }
      if (nameA > nameB) {
        return -1;
      }

      // names must be equal
      return 0;
    });


    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    let totalDays = DateDiff(endDateNew, dateNew);
    // console.log(date, endDate, totalDays);
  
    for (let x in data) {
      let url = `/singleUserHitList/${data[x]["userID"]}`;
      let average = parseInt(data[x]["amount"], 10) / totalDays;
      const levelShow=data[x]["title"]=="A10"?"實":data[x]["userLevel"]
      // let levelShow=
      let singleCode = (
        <tr>
          <td className="p-1 text-center">{data[x]["districtName"]} </td>
          <td className="p-1 text-center">{data[x]["unitName"]} </td>
          <td className="p-1 text-center">
            <a
              className="text-info"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}{" "}
            </a>
          </td>
          <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
          <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
          <td className="py-2 text-center">
            {data[x]["result"]}
            %
          </td>
          <td className="p-0 text-center">
            {/* {data[x]["userLevel"]} */}
            {levelShow}
          </td>
        </tr>
      );
      if (districtID == "全部" && unitList.has(data[x]["unitID"])) {
        resultArray.push(singleCode);
      } else if (
        data[x]["districtID"] == districtID &&
        unitList.has(data[x]["unitID"])
      ) {
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  function handleChangeUnit() {
    let selectUnit = document.getElementById("unitNameSelect").value;

    arrangeResultSelectUnitName(selectUnit);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    console.log('selectDistrict', selectDistrict)

    //處理 unit option
    let unitNameSelect = document.getElementById('unitNameSelect')

    //先處理剩下 全部 這一個選項
    unitNameSelect.options.length = 1
    if (selectDistrict == "全部") {
      unintAllListArray.map(item => {

        let opt1 = document.createElement("option")
        opt1.value = item.unitID
        opt1.text = item.unitName
        unitNameSelect.add(opt1, null)


      })
    } else {
      unintAllListArray.map(item => {
        if (item.districtID == selectDistrict) {
          //新增option 項目
          let opt1 = document.createElement("option")
          opt1.value = item.unitID
          opt1.text = item.unitName
          unitNameSelect.add(opt1, null)

        }
      })
    }


    arrangeResultSelectDistrict(selectDistrict);
  }
  //更新查詢
  function hangdleSummit() {
    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    arrangeResult(token, dateNew, endDateNew);
  }

  const DonwloadCSV = (props) => {

    let fileName = `${dateStateStart}-${dateStateEnd}統計資料`;
    if (unitID == 'A00') {
      return (
        <>
          <div className="btn">
            <CsvDownloader
              className="btn btn-info"
              filename={fileName}
              separator=","
              wrapColumnChar=""
              columns={columns}
              datas={datas}
              text="統計資料下載"
            />
          </div>
        </>
      );
    } else {
      return null
    }


  };

  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
      <div className="text-center">
        <h5 className="card-title text-muted text-center">HitNow數統計</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
            </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
            </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
          </a>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分區：
            </span>
            </div>
            <select
              class="form-control"
              id="districtSelect"
              onChange={handleChangeDistrict}
            >
              <option>全部</option>
              {districtListNameArray.map(item => {

                return <option value={item.districtID}>{item.districtName}</option>
              })}
              {/* {districtListState} */}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分店：
            </span>
            </div>
            <select
              class="form-control"
              id="unitNameSelect"
              onChange={handleChangeUnit}
            >
              <option>全部</option>
              {unintAllListArray.map(item => {
                return <option value={item.unitID}>{item.unitName}</option>
              })}
              {/* {unitListState} */}
            </select>
          </div>
          <DonwloadCSV level={level} />
        </div>

        <table className="table table-hover table-condensed table-striped">
          <thead clasName="py-3">
            <tr>
              <th className="p-0 text-center">分區</th>
              <th className="p-0 text-center">分店</th>
              <th className="p-0 text-center">姓名</th>
              <th className="p-0 text-center">HIT</th>
              <th className="p-0 text-center">應Hit</th>
              <th className="p-0 text-center">達成率</th>
              <th className="p-0 text-center">等級</th>
            </tr>
          </thead>
          <tbody>{countState}</tbody>
        </table>

      </div>
    </>
  );
};


export default ContactAmountType;
import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import {TodayCounting,ContactPart,BirthdayContactPart,TodayHistory} from '../components';
import Fuse from 'fuse.js'
import { useNavigate  } from 'react-router-dom';

//function 
import loginCheck from '../function/loginCheck.js'//確認是否登入中
import getDay from '../function/getDay.js'//計算取得日期
import BirthdayContactPartSingleDay from '../function/birthdayContactPartSingleDay' 

//Icon
import searchIcon from '../icon/search-circle.svg'
import textSvg from '../icon/text.svg'
import phoneSvg from '../icon/phone-8.svg'
import makeLog from '../function/makeLog.js'
//import starIcon from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import logo from '../icon/HAPPYHAIR_w.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'

var url = ""
let env = process.env.NODE_ENV
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com"
} else {
  url = "http://localhost:8080"
}

const SingleUserHitList=(props)=> {
  const navigate=useNavigate()
  let token = sessionStorage.getItem('token')
  let param = useParams() //取得userID
  let userID = param.userID
  let nameTemp = param.name
  console.log("nameTemp", nameTemp)
  let date = sessionStorage.getItem("dateStartDate") || getMonFirstDate()

  let endDate = sessionStorage.getItem("dateEndDate") || getDay(0, "-")


  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date()
    let m = (today.getMonth() + 1).toString()
    let mon = ""
    let year = (today.getFullYear()).toString()

    if (m.length == 1) {
      mon = '0' + m;
    }else{
      mon=m
    }
    let result = year + "-" + mon + "-01"
    //console.log(result)
    return result
  }





  const [footprintState, setFootprintState] = useState("")
  const [dateStateStart, setDateStart] = useState(date)
  const [dateStateEnd, setDateEnd] = useState(endDate)
  const [nameState, setNameState] = useState("")

  function getFootprint(token, date, endDate, userID) {
        sessionStorage.setItem("dateStartDate",date)
    sessionStorage.setItem("dateEndDate",endDate)
    return new Promise((resolve, reject) => {
      let cmd = `${url}/singleUserHitList?token=${token}&date=${date}&endDate=${endDate}&userID=${userID}`

      console.log("cmd", cmd)
      fetch(cmd, {
        mode: 'cors',
      }).then(res => {
        return res.json();
      }).then(json => {

        for (let x in json){
          switch (json[x]['contactType']){
            case 'Follow':
              json[x]['contactType']="關心"
            break;
            case 'Reservation':
              json[x]['contactType']="預約"
            break;
            case 'Cycle':
              json[x]['contactType']="周期"
            break;
            case 'Refollow':
              json[x]['contactType']="再追蹤"
            break;
          }
        }
        sessionStorage.setItem('singleUserHitList', JSON.stringify(json))
        resolve('contact ok')
        //返回新客定聯數統計
      })
    })
  }



  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value)
    console.log("change:", dateStateStart)
    console.log("changeTarget:", e.target.value)
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value)
    console.log("change:", dateStateEnd)
    console.log("changeTarget:", e.target.value)
  }

  //更新查詢
  function hangdleSummit() {
    let date = document.getElementById('startDateValue').value
    let endDate = document.getElementById('endDateValue').value
    console.log("start Date", date)
    let resultArray = []
    getFootprint(token, date, endDate, userID).then(() => {
      console.log("yes")
      let data = JSON.parse(sessionStorage.getItem('singleUserHitList'))

      //整理排序 用店多排序


      data.sort((a, b) => {

        var nameA = a.userHitDate; // ignore upper and lowercase
        var nameB = b.userHitDate; // ignore upper and lowercase
        if (nameA > nameB) {
          return -1;
        }
        if (nameA < nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      })

      for (let x in data) {

        let singleCode =

       
        <tr>
        <td className="px-0 text-center">{data[x]['name']}<br/><small>{data[x]['orderID']}</small> </td>
        <td className="px-0 text-center">{data[x]['contactType']} </td>
        <td className="px-0 text-center">{data[x]['shouldHitDate'].substring(5, 12)} </td>
        <td className="px-0 text-center"><span className="text-info">{data[x]['hitType']}</span><br/><small>{data[x]['userHitDate'].substring(5, 19)}</small></td>
        {/* <td className="px-0 text-center">{data[x]['userHitDate'].substring(5, 10)}<br/>{data[x]['userHitDate'].substring(10, 19)}</td> */}
      </tr>




        resultArray.push(singleCode)
      }
      setFootprintState(resultArray)
    })

  }
  useEffect(() => {
    let userName = ""
    let resultArray = []
    getFootprint(token, date, endDate, userID).then(() => {
      console.log("yes")
      let data = JSON.parse(sessionStorage.getItem('singleUserHitList'))

      //整理排序 用店多排序

      // data.sort((a, b) => {

      //   var nameA = a.shouldHitDate; // ignore upper and lowercase
      //   var nameB = b.shouldHitDate; // ignore upper and lowercase
      //   if (nameA > nameB) {
      //     return -1;
      //   }
      //   if (nameA < nameB) {
      //     return 1;
      //   }

      //   // names must be equal
      //   return 0;
      // })


      for (let x in data) {
        userName = data[x]['userName']
        console.log('userName', userName, data[x]['name'])
        let singleCode =
         
        <tr>
        <td className="px-0 text-center">{data[x]['name']}<br/><small>{data[x]['orderID']}</small> </td>
        <td className="px-0 text-center">{data[x]['contactType']} </td>
        <td className="px-0 text-center">{data[x]['shouldHitDate'].substring(5, 12)} </td>
        <td className="px-0 text-center"><span className="text-info">{data[x]['hitType']}</span><br/><small>{data[x]['userHitDate'].substring(5, 19)}</small></td>
        {/* <td className="px-0 text-center">{data[x]['userHitDate'].substring(5, 10)}<br/>{data[x]['userHitDate'].substring(10, 19)}</td> */}
      </tr>



        resultArray.push(singleCode)
      }
      setFootprintState(resultArray)
      setNameState(userName)
    }

    )


  }, [])


  return (

    <>


      
      <h5 className="card-title text-muted text-center">{nameState} Hit記錄</h5>

        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">起始：</span>
            </div>
            <input type="date" className="form-control" id="startDateValue" placeholder="Username" value={dateStateStart} onChange={handleChangeStart} aria-label="Username" aria-describedby="basic-addon1" />

          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">終止：</span>
            </div>
            <input type="date" className="form-control" id="endDateValue" placeholder="Username" value={dateStateEnd} onChange={handleChangeEnd} aria-label="Username" aria-describedby="basic-addon1" />
          </div>

          <div className="text-center">

            <a href="#" class="btn btn-danger " style={{ width: "100%", maxWidth: "300px" }} onClick={hangdleSummit} >查詢</a>

          </div>
        </div>


        <table className="table table-hover table-condensed table-striped ">

          <thead>
            <tr>
              <th className="px-0 text-center">客戶</th>
              <th className="px-0 text-center">行為</th>
              <th className="px-0 text-center">應Hit日</th>
              <th className="px-0 text-center">完成時間</th>
            </tr>
          </thead>
          <tbody>
            {footprintState}
          </tbody>
        </table>
        <div className="text-center">
        <div  className="btn btn-danger text-center" style={{ width: "100%", maxWidth: "300px" }} onClick={()=>{
          // window.location.href="/ContactAmountType"
          navigate(-1)
        }} >Back</div>
        <br/>
        <small className="text-muted"> { userID}</small>
   
        </div>
        
      

    </>

  )

  };


export default SingleUserHitList;
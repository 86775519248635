import React ,{useState,useEffect} from 'react';
import { Link ,useParams} from 'react-router-dom';
import Fuse from 'fuse.js'

//Icon
import searchIcon from '../icon/search-circle.svg'
import textSvg from '../icon/text.svg'
import phoneSvg from '../icon/phone-8.svg'
import makeLog from '../function/makeLog.js'
//import starIcon from '../icon/star.svg'
import starIcon from '../icon/watermelon.svg'
import logo from '../icon/HAPPYHAIR_b.png';
import HomeSVG from '../icon/house.svg'
import eject from '../icon/eject.svg'
import PersonAdd from '../icon/people.svg'
import birthdayCake from '../icon/birthdaycake.svg'
import adminIcon from '../icon/admin.svg'

const Survey = (props) => {
    let param=useParams() //取得問卷編號
    let surveyID=param.surveyID
    console.log("surveyID",surveyID)
  
    return (
  <>
  
      <div className=" mt-3 p-2  my-2  rounded alert-dark ">
      <div className="card" >
        <img src="https://d5w2wy9bedceo.cloudfront.net/ee191d2f547249f78fbd7837cd315b40/album/e486c87e818045f28db3bb40ff36678c/22650d3a1a1b4ed791071fe0a99814d0.jpg" class="card-img-top" alt="..."/>
         <div className="card-body" bg-dark>
         <img src={logo} alt="logo" style={{weight:"35px",height:'35px'}} />
         <p className="card-text" style={{fontSize:"10px",color:"gray"}}>感謝 David 於2020/12/25 前來 HappyHair 市府店，由 Kiri設計師為您服務。</p>
          <a href="#" className="btn btn-primary">Go somewhere</a>
         </div>
        </div>
      </div>
      </>
    );
  };
  
  export default Survey;
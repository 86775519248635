function loginCheck(){
    let token=sessionStorage.getItem('token')
    let timeCode=sessionStorage.getItem('timeCode')
    let newTimeCode=new Date().getTime()
  
    //如果未登入 或 時間已差一小時 則強制重新登入
    if (token===null || (newTimeCode-timeCode)>3600000){
        sessionStorage.clear()
    return 500
    }else{
    return   200
    }

}

export default loginCheck;
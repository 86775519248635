
//處理history 中的消費品項變中文 
function makeHistoryServiceList(){

  
  
    let clientData=JSON.parse(sessionStorage.getItem('history'))
    let serviceData=JSON.parse(sessionStorage.getItem('service'))
    
    let history=[]
    for (let x in clientData){
    
        
        let serviceIDList=clientData[x]['serviceIDList'].split(",")
        let service = new Set(serviceIDList);//服務品項

        let serviceList="" //回寫服務品項中文名
        let clientID=clientData[x]['clientID']
        //比對服務品項
        
        
        for (let i in serviceData){
           if (serviceData[i]['gender']=='女'){
            if (service.has(serviceData[i]['serviceID'])){
                serviceList+=serviceData[i]['service']+","
               
              }
           }
               
    

        }
        let tempSingle={
            clientID:clientData[x]['clientID'], 
            name:clientData[x]['name'],
            orderDate:clientData[x]['orderDate'],
            unitName:clientData[x]['unitName'],
            unitID:clientData[x]['unitID'],
            phone:clientData[x]['phone'],
            serviceList:serviceList,
            productSale:clientData[x]['productSale'],
            note:clientData[x]['note'],
            orderID:clientData[x]['orderID'],
            serviceIDList:clientData[x]['serviceIDList'],
            birthdayYear:clientData[x]['birthdayYear'],
            birthdayMonth:clientData[x]['birthdayMonth'],
            birthdayDay:clientData[x]['birthdayDay'],
            newClient:clientData[x]['newClient']
        }
        
       history.push(tempSingle)
    }   
    sessionStorage.setItem('historyChinese',JSON.stringify(history) );

}

export default makeHistoryServiceList;
//編碼base64 
function encodeDecode(data,flag){

    //旗標 encode為加密 decodde為解密
	var Buffer = require('buffer').Buffer;
	let result=null
	switch (flag){
		case 'encode':
			result=Buffer.from(data).toString('base64')
			break;
		case 'decode':
			result=Buffer.from(data,'base64').toString()
	}
	return result
}


export default encodeDecode;
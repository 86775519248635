import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import CsvDownloader from "react-csv-downloader";
import getDay from "../function/getDay.js"; //計算取得日期

var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}

//問卷管理
const SurveySingleView = (props) => {
  const $=window.$
  let param = useParams();
  let orderID = param.orderID;
  //處理清單所有店清單
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitID = sessionStorage.getItem("unitID");
  let unitAllList = JSON.parse(sessionStorage.getItem("unitList"));
  let unintAllListArray = [];
  const [surveyUnitID,setSurveyUnitID]=useState(null)
  const [surveyData, setSurveyData] = useState({"createDate":""});
  const [replyData,setReplyData]=useState([])
  const [userData,setUserData]=useState([])
  const [memoState, setMemoState] = useState(null);
  const [replyState, setReplyState] = useState(null);
  const [orderDate,setOrderDate]=useState(null)
  const [stateOptionList, setStateOptionList] = useState([]);
  const [surveyManagerList,setSurveyManagerList]=useState([])
  const [unitManager,setUnitManager]=useState([])

  const UpdateBtn=()=>{
    let btn =(<div
      className=" ml-2 btn btn-danger "
      style={{ width: "200px" }}
      onClick={surveyUpdate}
    >
      更新
    </div>)

    let surveyManager=sessionStorage.getItem("surveyManager")
    console.log(surveyManager)
    if (surveyManager!="1"){
      btn=null
    }

    return btn
}

  for (let x in unitAllList) {
    manageUnit.map((item) => {
      if (item == unitAllList[x].unitID) {
        unintAllListArray.push(unitAllList[x]);
      }
    });
  }
  function translateService(data) {
    //翻譯服務品項
    let serviceIDList = data[0].serviceIDList;
    let gender = data[0].gender;
    let serviceData = JSON.parse(sessionStorage.getItem("service"));
    console.log(serviceData);
    let serviceIDAdrray = serviceIDList.split(",");
    let newServiceData = "";
    for (let i in serviceIDAdrray) {
      console.log(serviceIDAdrray[i]);
      for (let y in serviceData) {
        if (
          serviceIDAdrray[i] == serviceData[y].serviceID &&
          gender == serviceData[y].gender
        ) {
          newServiceData += " " + serviceData[y].service;
        }
      }
    }
    return newServiceData;
  }

  
  let token = sessionStorage.getItem("token");

  useEffect(async() => {
    let cmd = `${url}/surveySingleView?token=${token}&orderID=${orderID}`;
    console.log(cmd)
    let data = await fetch(cmd)
      .then((res) => res.json())
      .then((json) => {


        return json;
      });
      console.log(data)
      let newServiceData=translateService(data["surveyData"])
      data["surveyData"][0]["newServiceData"]=newServiceData
      setSurveyData(data["surveyData"][0])
      setReplyData(data["replyData"])
      
      let tempOrderDate=data["surveyData"][0]["orderDate"]
      if (data["surveyData"][0]["q5"]=="1"){
        data["surveyData"][0]["q5"]="願意"
      }else{
        data["surveyData"][0]["q5"]="不願意"
      }
      setOrderDate(tempOrderDate.substring(0, 4)+"-"+tempOrderDate.substring(4, 6)+"-"+tempOrderDate.substring(6, 8))

      //整理operator 名單
      //比對是否有相同名字 如果有 設定為被選，如果都沒有就新增一筆<option value="">請選擇</option>
      let add={"name":"請選擇","serveyManage":"1","selected":true,value:""}
      if (data["replyData"][0]["operator"]){
        add={"name":data["replyData"][0]["operator"],"serveyManage":"1","selected":true,value:data["replyData"][0]["operator"]}
      }
      let unitAddOtpion={"name":"請選擇","unitSelected":true,value:""}
      console.log(data["replyData"][0]["unitManager"])
      if (data["replyData"][0]["unitManager"]){
        unitAddOtpion={"name":data["replyData"][0]["unitManager"],"unitSelected":true,value:data["replyData"][0]["unitManager"]}
      }
      console.log(data["replyData"][0]["operator"])
      let tempUnitManagerList=[]
  
      
      data["userData"].map((item,index)=>{

   
        if (item.unitID!="A00"){
          console.log(item.name,data["replyData"][0]["unitManager"])
          if (item.name==data["replyData"][0]["unitManager"]){
           
            data["userData"][index]["unitSelected"]=true
            
            unitAddOtpion=null
          }
          tempUnitManagerList.push(item)
        }

        if (item.name==data["replyData"][0]["operator"]){
          data["userData"][index]["selected"]=true
          add=null
        }

        })


      if (add){
        data["userData"].push(add)
      }
        
      if (unitAddOtpion){
        tempUnitManagerList.push(unitAddOtpion)
      }
      data["userData"].sort((a, b) => {
        var nameA = a.name;
        var nameB = b.name;
        if (nameA >nameB) {
            return -1;
        }
        if (nameA < nameB) {
            return 1;
        }
        return 0;
    });

//整理店長名單
    // if (!tempUnitManagerList.find(item=>{
    //   return item["unitSelected"]==true
    // })){
    //   tempUnitManagerList.push(add={"name":"請選擇","selected":true,value:""})
    // }
    console.log(tempUnitManagerList)

    tempUnitManagerList.sort((a, b) => {
      var nameA = a.selected;
      var nameB = b.selected;
      if (nameA > nameB) {
          return 1;
      }
      if (nameA < nameB) {
          return -1;
      }
      return 0;
  });

    console.log(data["userData"])
   
    setUserData(data["userData"])
    setUnitManager(tempUnitManagerList)
    document.getElementById("managerReply").value=data["replyData"][0]["managerReply"]
    document.getElementById("clientReply").value=data["replyData"][0]["clientReply"]
    document.getElementById("memo").value=data["replyData"][0]["memo"]

  //處理回覆勾選
  let replyArray=data["replyData"][0]["reply"].split(",")
  let checkBoxArray = $("input[type=checkbox]")   
  console.log(checkBoxArray)
  replyArray.map(item=>{
    for (let x in checkBoxArray){
      console.log(checkBoxArray[x].value)
      if (checkBoxArray[x].value==item){
        checkBoxArray[x].checked = true
      }
    }
  })
  
  //處理狀態
  let stateOption=["待追蹤","追蹤中","結案"]
  let stateArray=[]
  stateOption.map(item=>{
    let temp= <option>{item}</option>
    if (data["replyData"][0]["state"]){
      if (item==data["replyData"][0]["state"]){
        temp= <option selected>{item}</option>
      }
    }
    
    stateArray.push(temp)
  })
  setStateOptionList(stateArray)

  }, []);

  function surveyUpdate(e) {
    let clientReply = document.getElementById("clientReply").value;
    let managerReply = document.getElementById("managerReply").value;
    let memo = document.getElementById("memo").value;
    let reply = []
    let operator = document.getElementById("operator").value;
    let unitManager = document.getElementById("unitManager").value;
    let state=document.getElementById("state").value
    
    let checkBoxArray = $("input[type=checkbox]")  
    for (let x in checkBoxArray){
      if ( checkBoxArray[x].checked){
        reply.push(checkBoxArray[x].value)
      }
    }
    console.log(reply)
    
    fetch(
      `${url}/surveyEdit?token=${token}&orderID=${orderID}&memo=${memo}&reply=${reply}&operator=${operator}&unitManager=${unitManager}&state=${state}&clientReply=${clientReply}&managerReply=${managerReply}`
    )
      .then((res) => res.status)
      .then((status) => {
        if (status != 200) {
          alert("更新失敗");
          return;
        }
        window.location.href = "/surveyManage";
      });
  }

  return (
    <>
      <div className="container">
        <div
          className="card   alert-dark"
          style={{ width: "100%", maxWidth: "900px" }}
        >
          <div className="card-body">
            <h5 className="card-title">顧客滿意度調查</h5>

            <div className="card mb-2">
              <div className="card-body bg-dark rounded-top text-white  p-2 ">
                客單資料區{" "}
              </div>

              <div className="card-body border-1" style={{ width: "100%" }}>
                <table className=" table table-hover table-condensed table-striped">
                  <thead clasName="py-3">
                    <tr>
                      <th className="p-0 text-center">分區</th>
                      <th className="p-0 text-center">分店</th>
                      <th className="p-0 text-center">設計師</th>
                    </tr>
                  </thead>
                  <tbody>
               
                          <tr>
                            <td className="p-1 text-center">
                              {surveyData.districtName}{" "}
                            </td>
                            <td className="p-1 text-center">
                              {surveyData.unitName}{" "}
                            </td>
                            <td className="p-1 text-center">
                              {surveyData.userName}{" "}
                            </td>
                          </tr>
                   
                  </tbody>
                </table>

                <table className="table table-hover table-condensed table-striped">
                  <thead clasName="py-3">
                    <tr>
                      <th className="p-0 text-center">消費日</th>
                      <th className="p-0 text-center">作答日期</th>

                      <th className="p-0 text-center">顧客</th>
                      <th className="p-0 text-center">性別</th>
                      <th className="p-0 text-center">手機</th>
                      <th className="p-0 text-center">項目</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                          <tr>
                            <td className="p-1 text-center">
                              {orderDate}
                            </td>
                            <td className="p-1 text-center">
                              {surveyData.createDate.substring(0,10)}{" "}
                            </td>

                            <td className="p-1 text-center">{surveyData.name} </td>
                            <td className="p-1 text-center">{surveyData.gender} </td>
                            <td className="p-1 text-center">{surveyData.phone} </td>
                            <td className="p-1 text-center">
                             {surveyData.newServiceData}
                            </td>
                          </tr>
               
                  </tbody>
                </table>

                <table className="table table-hover table-condensed table-striped">
                  <thead clasName="py-3">
                    <tr>
                      <th className="p-0 text-center">user</th>
                     
                      <th className="p-0 text-center">client</th>
                    </tr>
                  </thead>
                  <tbody>
                 
                          <tr>
                            <td className="p-1 text-center">
                             <small>{surveyData.ip}</small> 
                            </td>
                            
                            <td className="p-1 text-center">
                            <small>{surveyData.uIP}</small> 
                            </td>
                          </tr>
               
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-2">
              <div className="card-body bg-dark rounded-top text-white  p-2 ">
                滿意度作答區
              </div>

              <div className="card-body border-1" style={{ width: "100%" }}>
                <table className="table table-hover table-condensed table-striped">
                  <thead clasName="py-3">
                    <tr className="text-left">
                      <th className="p-0 text-center" width="40%">
                        題目
                      </th>
                      <th className="p-0 text-center" width="20%">
                        答作
                      </th>
                      <th className="p-0 text-center" width="40%">
                        客戶意見
                      </th>
                    </tr>
                  </thead>
                  <tbody>
          
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              1.您對店內人員的服務態度是否感到滿意？{" "}
                            </td>
                            <td className="p-1 text-center">{surveyData.q1} </td>
                            <td className="p-1 text-center">{surveyData.c1} </td>
                          </tr>
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              2.您對店內人員的專業技術是否感到滿意？{" "}
                            </td>
                            <td className="p-1 text-center">{surveyData.q2} </td>
                            <td className="p-1 text-center">{surveyData.c2} </td>
                          </tr>
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              3.整體而言，您對店內環境空間是否感到滿意？{" "}
                            </td>
                            <td className="p-1 text-center">{surveyData.q3} </td>
                            <td className="p-1 text-center">{surveyData.c3} </td>
                          </tr>
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              4.整體而言，您在店內感受的整體氛圍是否感到滿意？{" "}
                            </td>
                            <td className="p-1 text-center">{surveyData.q4} </td>
                            <td className="p-1 text-center">{surveyData.c4} </td>
                          </tr>
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              5.請問您是否願意再次蒞臨HAPPYHAIR品牌消費？{" "}
                            </td>
                            <td className="p-1 text-center">{surveyData.q5} </td>
                            <td className="p-1 text-center">{surveyData.c5} </td>
                          </tr>
                          <tr>
                            <td className="p-1 text-center">
                              {" "}
                              歡迎您分享本次的消費經驗與建議?{" "}
                            </td>
                            <td className="p-1 text-center"> </td>
                            <td className="p-1 text-center">{surveyData.q6} </td>
                          </tr>
         
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card mb-1 ">
              <div className="card-body bg-danger  rounded-top text-white border-1 p-1 ">
                客服處理
              </div>
              <div
                className="container p-2"
                style={{ maxWidth: "500pt", width: "100%" }}
              >
                <div className="input-group  border-0 mt-2 mb-1">
                  <div className="input-group-prepend " >
                    <span className=" input-group-text alert-dark  border-1 px-5">
                      回覆人員
                    </span>
                  </div>
                  <select
                    className=" border-1  form-control "
                   
                    id="operator"
                  >
             

                   {
                    
                    userData.map(item=>{
                      
                      if (item.selected==true  ){
                        return  <option value={item.value} selected>{item.name} </option>
                      }

                      if (item.surveyManager=="1" ){
                        return  <option >{item.name} </option>
                      }
                    })}
               
               
                  </select>
                </div>

                <div className="input-group  border-0 mt-2 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      店主管
                    </span>
                  </div>
     

                  <select
                    className=" border-1  form-control "
                   
                    id="unitManager"
                  >

                    {
                    unitManager.map(item=>{

                      if (item.unitSelected==true  ){
                        return  <option value={item.value} selected>{item.name} </option>
                      }

                      if (item.unitID!="A00"){
                        return  <option >{item.name} </option>
                      }
                    })}
               
                  </select>
                </div>

                <div className="input-group  border-0 mt-2 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      店主管
                      <br />
                      回覆記錄
                    </span>
                  </div>
                  <textarea
                    className=" border-1 shadow-textarea form-control"
                    rows="3"
                    id="managerReply"
                    
                   
                  ></textarea>
                </div>

                <div className="input-group  border-0 mt-2 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      顧客
                      <br />
                      回覆記錄
                    </span>
                  </div>
                  <textarea
                    className=" border-1 shadow-textarea form-control"
                    rows="3"
                    id="clientReply"
                 
                   
                  ></textarea>
                </div>

                <div className="input-group  border-0 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      備註內容
                    </span>
                  </div>
                  <textarea
                    className=" border-1 shadow-textarea form-control"
                    rows="3"
                    id="memo"
                   
                  ></textarea>
                </div>

                <div className="input-group  border-0">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      處理方式
                    </span>
                  </div>
                    <div className=" border-1  form-control"  id="result" style={{"height":"255px"}}>
                      <div className="form-group form-check  ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="reply1"
                          value="1"
                        />
                        <label className="form-check-label btn alert-danger" for="reply1" style={{"width":"30%"}}>
                          不滿意退費
                        </label>
                      </div>
                      <div className="form-group form-check  ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="reply2"
                          value="2"
                        />
                        <label className="form-check-label btn alert-danger" for="reply2" style={{"width":"30%"}}>
                          已回覆消基會
                        </label>
                      </div>
                      <div className="form-group form-check  ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="reply3"
                          value="3"
                        />
                        <label className="form-check-label btn alert-danger" for="reply3" style={{"width":"30%"}}>
                          轉店
                        </label>
                      </div>
                      <div className="form-group form-check  ">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="reply4"
                          value="4"
                        />
                        <label className="form-check-label btn alert-danger" for="reply4" style={{"width":"30%"}}>
                          其他
                        </label>
                      </div>
                    </div>

                    
                 
                </div>

                <div className="input-group  border-0 mt-1 mb-1">
                  <div className="input-group-prepend ">
                    <span className="input-group-text alert-dark  border-1 px-5">
                      狀態{" "}
                    </span>
                  </div>
                  <select
                    className=" border-1 shadow-textarea form-control"
                  
                    id="state"
                   
                  >
                    {stateOptionList.map(item=>item)}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="text-center mt-3 mb-5">
          <div
            className="btn alert-dark  border"
            style={{ width: "200px" }}
            onClick={() => {
              window.location.href = "/surveyManage";
            }}
          >
            回列表
          </div>
{/* 
          <div
            className=" ml-2 btn btn-danger "
            style={{ width: "200px" }}
            onClick={surveyUpdate}
          >
            更新
          </div> */}


          <UpdateBtn/>
        </div>
      </div>
    </>
  );
};

export default SurveySingleView;

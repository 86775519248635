
//切換是否預約 0為否 1為是
function switchReservationUrl(userID, flag) {
    var url = ""
    let env = process.env.NODE_ENV
    if (env == "production") {
        //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
        url = "https://server.happyhitnow.com"
    } else {
        url = "http://localhost:8080"
    }

    if (flag=="1"){
        sessionStorage.setItem("reservationUrl","1")
    }else{
        sessionStorage.setItem("reservationUrl","0")
    }
    let token = sessionStorage.getItem('token')

    fetch(`${url}/switchReservationUrl?userid=${userID}&reservationUrl=${flag}&token=${token}`, {
        mode: 'cors',
    })
} export default switchReservationUrl;
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  TodayCounting,
  ContactPart,
  BirthdayContactPart,
  TodayHistory,
} from "./components";
import Fuse from "fuse.js";
import CsvDownloader from "react-csv-downloader";

//function
import loginCheck from "./function/loginCheck.js"; //確認是否登入中
import getDay from "./function/getDay.js"; //計算取得日期
import BirthdayContactPartSingleDay from "./function/birthdayContactPartSingleDay";

//Icon
import searchIcon from "./icon/search-circle.svg";
import textSvg from "./icon/text.svg";
import phoneSvg from "./icon/phone-8.svg";
import makeLog from "./function/makeLog.js";
//import starIcon from './icon/star.svg'
import starIcon from "./icon/watermelon.svg";
import logo from "./icon/HAPPYHAIR_w.png";
import HomeSVG from "./icon/house.svg";
import eject from "./icon/eject.svg";
import PersonAdd from "./icon/people.svg";
import birthdayCake from "./icon/birthdaycake.svg";
import adminIcon from "./icon/admin.svg";

var url = "";
let env = process.env.NODE_ENV;
if (env == "production") {
  //url="https://hitnow2-server2-hn4c6sz7cq-de.a.run.app"
  url = "https://server.happyhitnow.com";
} else {
  url = "http://localhost:8080";
}

//Home
const Home = (props) => {
  //確認是否已登入
  let result = loginCheck();
  if (result !== 200) {
    window.location.href = "/login";
  }

  //取得已定聯Set 編碼為 orderID＋contactType
  let contactDate = JSON.parse(sessionStorage.getItem("contact"));
  const contactSet = new Set(Object.keys(contactDate));

  let clientData = JSON.parse(sessionStorage.getItem("client"));
  //統計數量

  // birthday
  const [birthdayList, setBirthdayList] = useState([]);

  // follow 關心追蹤
  const [followList, setFollowList] = useState([]);
  const [followCount, setFollowCount] = useState(0);
  // reservation 預約定聯
  const [reservationList, setReservationList] = useState([]);
  const [reservationCount, setReservationCount] = useState(0);
  //  circles 超過周期
  const [circlesList, setCirclesList] = useState([]);
  const [circlesCount, setCirclesCount] = useState(0);
  // active 流失客
  const [activeList, setActiveList] = useState([]);
  const [activeCount, setActiveCount] = useState(0);
  /* close 促成客
  const [closeList,setCloseList]=useState([])
  */
  useEffect(() => {
    let userID = sessionStorage.getItem("userID");
    makeLog(userID, "home");
    //生日名單
    let birthdayList = [];
    let followList = [];
    let fCount = 0;
    let reservationList = [];
    let rCount = 0;
    let circlesList = [];
    let cCount = 0;
    let activeList = [];
    let aCount = 0;
    let today = getDay(0, "-");
    let notActiveLimit = 3;
    let followLimit = 6;
    let reservationLimit = 6;
    let circlesLimit = 3;
    for (let x in clientData) {
      if (clientData[x]["birthdayTag"] == "yes") {
        birthdayList.push(x);
      }

      switch (clientData[x]["contactType"]) {
        case "notActive":
          //計算是否超過筆數

          if (contactSet.has(clientData[x]["orderID"] + "notActive")) {
            let contactDataKey = clientData[x]["orderID"] + "notActive";
            if (contactDate[contactDataKey]["createDate"] == today) {
              notActiveLimit -= 1;
            }
            break;
          } else {
            aCount += 1;

            if (activeList.length >= notActiveLimit) {
              break;
            } else {
              activeList.push(x);
              break;
            }
          }

        case "circles":
          if (contactSet.has(clientData[x]["orderID"] + "circles")) {
            let contactDataKey = clientData[x]["orderID"] + "circles";
            if (contactDate[contactDataKey]["createDate"] == today) {
              circlesLimit -= 1;
            }
            break;
          } else {
            cCount += 1;

            if (circlesList.length >= circlesLimit) {
              break;
            } else {
              circlesList.push(x);
              break;
            }
          }
        case "reservation":
          if (contactSet.has(clientData[x]["orderID"] + "reservation")) {
            let contactDataKey = clientData[x]["orderID"] + "reservation";
            if (contactDate[contactDataKey]["createDate"] == today) {
              reservationLimit -= 1;
            }
            break;
          } else {
            rCount += 1;

            if (reservationList.length >= reservationLimit) {
              break;
            } else {
              reservationList.push(x);
              break;
            }
          }

        /* 促成
        case "close":
          if (closeList.length>9){
            break
          }else{
            closeList.push(x)
            break
          }
       */
        case "follow":
          if (contactSet.has(clientData[x]["orderID"] + "follow")) {
            let contactDataKey = clientData[x]["orderID"] + "follow";
            if (contactDate[contactDataKey]["createDate"] == today) {
              followLimit -= 1;
            }
            break;
          } else {
            fCount += 1;

            if (followList.length >= followLimit) {
              break;
            } else {
              followList.push(x);
              break;
            }
          }
      }
    }

    setActiveList(activeList);
    setBirthdayList(birthdayList);
    setFollowList(followList);
    setReservationList(reservationList);
    setCirclesList(circlesList);
    //計算總筆數
    setFollowCount(fCount);
    setReservationCount(rCount);
    setCirclesCount(cCount);
    setActiveCount(aCount);
    //setCloseList(closeList)
  }, []);

  let countDate = getDay(-210, "-");

  //功能說明
  function Memo(props) {
    return (
      <>
        <div className="p-2  my-2  rounded ">
          <div className="toast-body ">
            <h4>
              <span className=" text-muted badge-pill badge badge-light">
                指標說明
              </span>{" "}
            </h4>

            <div
              className="alert alert-info p-1 m-1"
              style={{ fontSize: "9px" }}
            >
              <span className="ml-2">
                總客數：自 {countDate.substring(5, 12)} 來店 總客數 : 純洗客數
              </span>
            </div>
            <div
              className="alert alert-warning p-1 m-1 "
              style={{ fontSize: "9px" }}
            >
              <span className="ml-2">
                H.I.T：周 {getWeek().substring(5, 12)} : 月{" "}
                {getMonFirstDate().substring(5, 12)} 之聯絡次數
              </span>
            </div>
            <div
              className="alert alert-danger p-1 m-1"
              style={{ fontSize: "9px" }}
            >
              <span className="ml-2">
                月客數： {getMonFirstDate().substring(5, 7)} 月來店 總客數 :
                純洗客數
              </span>
            </div>
            <div
              className="alert alert-success p-1 m-1"
              style={{ fontSize: "9px" }}
            >
              <span className="ml-2 ">
                <img
                  src={starIcon}
                  className="mr-1"
                  style={{ width: "18px", height: "18px" }}
                  alt="start"
                />{" "}
                : 設計師新客戶
              </span>
            </div>
          </div>
        </div>
      </>
    );
  }

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    } else {
      mon = m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }

  function getWeek() {
    //計算本周一的日期
    let today = new Date();
    let day = today.getDay();

    let result = 0;
    switch (day) {
      case 0:
        result = -6;
        break;
      default:
        result = 1 - day;
        break;
    }

    let newData = getDay(result, "-");
    return newData;
  }

  return (
    <>
      <TodayCounting /> <br /> <br />
      <br />
      <ContactPart
        className="mt-3"
        title="關心追蹤"
        note="消費期限內關心"
        color="dark"
        bgColor="info"
        bar="25%"
        componentList={followList}
        count={followCount}
        moreType="follow"
      />
      <ContactPart
        className="mt-3"
        title="安排預約"
        note="下次消費預約"
        color="dark"
        bgColor="info"
        bar="50%"
        componentList={reservationList}
        count={reservationCount}
        moreType="reservation"
      />
      <ContactPart
        className="mt-3"
        title="超過周期"
        note="超過消費周期"
        color="dark"
        bgColor="info"
        bar="75%"
        componentList={circlesList}
        count={circlesCount}
        moreType="circles"
      />
      <ContactPart
        className="mt-3"
        title="流失客"
        note="新客超過120天，舊客180天未消費"
        color="dark"
        bgColor="info"
        bar="100%"
        componentList={activeList}
        count={activeCount}
        moreType="notActive"
      />
      <BirthdayContactPartSingleDay
        className="mt-3"
        componentList={birthdayList}
      />
      <TodayHistory />
      <Memo />
    </>
  );
};

//客戶資料顯示
const Clients = (props) => {
  var imgStyle = {
    width: "25px",
    height: "25px",
    lineHeight: "45px",
  };

  let clientData = JSON.parse(sessionStorage.getItem("client"));
  let data = []; //顯示的資料 提供給 fuse
  for (let x in clientData) {
    let objectData = clientData[x];
    data.push(objectData);
  }
  let clientList = [];
  const [listState, setList] = useState("");
  const [clientAmount, setAmout] = useState(Object.keys(clientData).length);

  let options = {
    keys: ["name", "birthday", "serviceList"],
  };

  let fuse = new Fuse(data, options);

  function handleChange(e) {
    let searchResult = fuse.search(e.target.value);
    //console.log("s.l:",searchResult.length )
    if (searchResult.length > 0) {
      for (let x = 0; x < searchResult.length; x++) {
        let clientID = searchResult[x]["item"]["clientID"];
        let togo = `/contact/${clientID}`;
        let userID = searchResult[x]["item"]["userID"];

        let singleClient = (
          <>
            <li
              className="list-group-item font-weight-lighter p-2 mb-1   "
              tag="button"
              key={userID}
            >
              <div className=" p-1">
                <Link to={togo}>
                  <div className="mb-1">
                    <span
                      className="font-weight-bold text-info"
                      style={{ fontSize: "large" }}
                    >
                      {searchResult[x]["item"]["name"]}{" "}
                    </span>{" "}
                    <span className="text-muted" style={{ fontSize: "80%" }}>
                      {" "}
                      - {searchResult[x]["item"]["birthday"]}{" "}
                    </span>{" "}
                    <GenderIcon gender={searchResult[x]["item"]["gender"]} />{" "}
                  </div>
                  <div>
                    <MakeCall phone={searchResult[x]["item"]["phone"]} />{" "}
                    <img
                      src={textSvg}
                      style={{ width: "22px", height: "22px" }}
                      alt="edit"
                    />{" "}
                    <span
                      className="text-muted   "
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      消費日：{searchResult[x]["item"]["lastDate"]}
                    </span>
                  </div>
                </Link>
              </div>
            </li>
          </>
        );

        clientList.push(singleClient);
      }

      setList(clientList);
      setAmout(searchResult.length);
    } else {
      makeList();
      setAmout(Object.keys(clientData).length);
    }
  }

  function Star(props) {
    //新客戶標示
    if (props.newClient == "1") {
      return (
        <span>
          {" "}
          <img
            className="ml-1"
            src={starIcon}
            style={{ width: "18px", height: "18px" }}
            alt="start"
          />
        </span>
      );
    } else {
      return null;
    }
  }

  function MakeCall(props) {
    //電話按鈕
    if (props.phone.length > 0) {
      let phoneCall = (
        <span className="text-right  ml-2 text-success   font-weight-lighter ">
          <a href={`tel:${props.phone}`}>
            <span
              className="ml-1 text-muted text-decoration-none  font-weight-lighter "
              style={{ fontSize: "smaller" }}
            >
              <img src={phoneSvg} style={imgStyle} alt="edit" /> {props.phone}{" "}
            </span>{" "}
          </a>
        </span>
      );
      return phoneCall;
    } else {
      return null;
    }
  }

  function GenderIcon(props) {
    if (props.gender == "女") {
      let genderDot = (
        <span
          class="  font-weight-lighter rounded-circle alert-danger "
          style={{ fontSize: "80%" }}
        >
          {props.gender}{" "}
        </span>
      );
      return genderDot;
    } else if (props.gender == "男") {
      let genderDot = (
        <span
          class="  font-weight-lighter rounded-circle alert-info "
          style={{ fontSize: "80%" }}
        >
          {props.gender}{" "}
        </span>
      );
      return genderDot;
    } else {
      return null;
    }
  }

  function makeList() {
    for (let x in clientData) {
      let toMakeContact = `/contact/${clientData[x]["clientID"]}`;
      //let im=clientData[x]['im'].replace(/,/g,"、")

      let singleClient = (
        <>
          <Link to={toMakeContact} style={{ textDecoration: "none" }}>
            <li
              className="list-group-item font-weight-lighter p-1 mb-1   "
              tag="button"
              key={x}
            >
              <div>
                <div className="ml-2 ">
                  <span className=" text-dark">{clientData[x]["name"]} </span>
                  <span className="m-1">
                    <GenderIcon gender={clientData[x]["gender"]} />{" "}
                  </span>{" "}
                  <Star newClient={clientData[x]["newClient"]} />
                  <br />
                  <span
                    className="text-muted  font-weight-light"
                    style={{ fontSize: "smaller" }}
                  >
                    {" "}
                    消費日：{clientData[x]["lastDate"].substring(5)}{" "}
                  </span>
                  <span className="text-muted ml-2" style={{ fontSize: "80%" }}>
                    {" "}
                    生日: {clientData[x]["birthday"]}{" "}
                  </span>
                </div>
                {/*
           <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
          */}
                <div
                  className="ml-2  text-muted font-weight-light"
                  style={{ fontSize: "80%" }}
                >
                  {" "}
                  {clientData[x]["serviceList"].replace(/,/g, " / ")}
                </div>
              </div>
            </li>
          </Link>
        </>
      );

      clientList.push(singleClient);
    }
    setList(clientList);
  }

  useEffect(() => {
    let userID = sessionStorage.getItem("userID");
    makeLog(userID, "clients");
    makeList();
  }, []);

  return (
    <>
      <div className="p-2  my-2  rounded ">
        <div className="toast">
          <div className="toast-header">
            <h5>客戶資料: {clientAmount} 筆</h5>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-default">
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  style={{ weight: "24px", height: "24px" }}
                />
                Search
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              onChange={handleChange}
            />
          </div>
          <div className="toast-body ">
            <ul className="list-group ">{listState}</ul>
          </div>
        </div>
      </div>
    </>
  );
};

//月生日壽星
const BirthdayClients = (props) => {
  var imgStyle = {
    width: "25px",
    height: "25px",
    lineHeight: "45px",
  };

  let nextMonth = new Date().getMonth() + 1;
  let nextTwoMoth = 0;
  if (nextMonth > 11) {
    nextTwoMoth = 1;
  } else {
    nextTwoMoth = nextMonth + 1;
  }

  let clientData = JSON.parse(sessionStorage.getItem("client"));
  let data = []; //顯示的資料 提供給 fuse
  let sortData = {};
  for (let x in clientData) {
    if (
      clientData[x]["birthday"].substring(
        clientData[x]["birthday"].length - 5,
        clientData[x]["birthday"].length - 3
      ) == nextMonth ||
      clientData[x]["birthday"].substring(
        clientData[x]["birthday"].length - 5,
        clientData[x]["birthday"].length - 3
      ) == nextTwoMoth
    ) {
      let objectData = clientData[x];
      data.push(objectData);
      let key =
        clientData[x]["birthday"].substring(
          clientData[x]["birthday"].length - 5
        ) + x;
      sortData[key] = clientData[x];
    }
  }
  let clientList = [];

  //排序

  var sortable = [];
  for (var x in data) {
    let temp = data[x]["birthday"];
    sortable.push([data[x]["clientID"], temp.substring(temp.length - 5)]);
  }

  sortable.sort(function (a, b) {
    return a[1] - b[1];
  });

  const [listState, setList] = useState("");
  const [clientAmount, setAmout] = useState(Object.keys(data).length);

  let options = {
    keys: ["name"],
  };

  let fuse = new Fuse(data, options);

  function handleChange(e) {
    let searchResult = fuse.search(e.target.value);
    //console.log("s.l:",searchResult.length )
    if (searchResult.length > 0) {
      for (let x = 0; x < searchResult.length; x++) {
        let clientID = searchResult[x]["item"]["clientID"];
        let togo = `/contact/${clientID}`;
        let userID = searchResult[x]["item"]["userID"];

        //let toEdit=`/edit/${clientID}`

        let singleClient = (
          <>
            <li
              className="list-group-item font-weight-lighter p-2 mb-1   "
              tag="button"
              key={userID}
            >
              <div className=" p-1">
                <Link to={togo}>
                  <div className="mb-1">
                    <span
                      className="font-weight-bold text-info"
                      style={{ fontSize: "large" }}
                    >
                      {searchResult[x]["item"]["name"]}{" "}
                    </span>{" "}
                    <span className="text-muted" style={{ fontSize: "80%" }}>
                      {" "}
                      - {searchResult[x]["item"]["birthday"]}{" "}
                    </span>{" "}
                    <GenderIcon gender={searchResult[x]["item"]["gender"]} />{" "}
                  </div>
                  <div>
                    <MakeCall phone={searchResult[x]["item"]["phone"]} />{" "}
                    <img
                      src={textSvg}
                      style={{ width: "22px", height: "22px" }}
                      alt="edit"
                    />{" "}
                    <span
                      className="text-muted   "
                      style={{ fontSize: "smaller" }}
                    >
                      {" "}
                      消費日：{searchResult[x]["item"]["lastDate"]}
                    </span>
                  </div>
                </Link>
              </div>
            </li>
          </>
        );

        clientList.push(singleClient);
      }

      setList(clientList);
      setAmout(searchResult.length);
    } else {
      makeList();
      setAmout(Object.keys(clientData).length);
    }
  }

  function Star(props) {
    //新客戶標示
    if (props.newClient == "1") {
      return (
        <img
          className="ml-1"
          src={starIcon}
          style={{ width: "18px", height: "18px" }}
          alt="start"
        />
      );
    } else {
      return null;
    }
  }

  function MakeCall(props) {
    //電話按鈕
    if (props.phone.length > 0) {
      let phoneCall = (
        <span className="text-right  ml-2 text-success   font-weight-lighter ">
          <a href={`tel:${props.phone}`}>
            <span
              className="ml-1 text-muted text-decoration-none  font-weight-lighter "
              style={{ fontSize: "smaller" }}
            >
              <img src={phoneSvg} style={imgStyle} alt="edit" /> {props.phone}{" "}
            </span>{" "}
          </a>
        </span>
      );
      return phoneCall;
    } else {
      return null;
    }
  }

  function GenderIcon(props) {
    if (props.gender == "女") {
      let genderDot = (
        <span
          class="  font-weight-lighter rounded-circle alert-danger "
          style={{ fontSize: "80%" }}
        >
          {props.gender}{" "}
        </span>
      );
      return genderDot;
    } else if (props.gender == "男") {
      let genderDot = (
        <span
          class="  font-weight-lighter rounded-circle alert-info "
          style={{ fontSize: "80%" }}
        >
          {props.gender}{" "}
        </span>
      );
      return genderDot;
    } else {
      return null;
    }
  }

  function makeList() {
    //取生日名單 key來排序
    let keyList = Object.keys(sortData);
    keyList.sort();

    for (let x in keyList) {
      let y = keyList[x];
      let toMakeContact = `/contact/${sortData[y]["clientID"]}`;
      //let im=clientData[x]['im'].replace(/,/g,"、")

      let singleClient = (
        <>
          <Link to={toMakeContact} style={{ textDecoration: "none" }}>
            <li
              className="list-group-item font-weight-lighter p-1 mb-1   "
              tag="button"
              key={y}
            >
              <div>
                <div className="ml-2 ">
                  <span className=" text-dark">{sortData[y]["name"]} </span>
                  <span className="m-1">
                    <GenderIcon gender={sortData[y]["gender"]} />{" "}
                  </span>{" "}
                  <Star newClient={sortData[y]["newClient"]} />
                  <br />
                  <span
                    className="text-muted  font-weight-light"
                    style={{ fontSize: "smaller" }}
                  >
                    {" "}
                    消費日：{sortData[y]["lastDate"].substring(5)}{" "}
                  </span>
                  <span className="text-muted ml-2" style={{ fontSize: "80%" }}>
                    {" "}
                    生日: {sortData[y]["birthday"]}{" "}
                  </span>
                </div>
                {/*
           <div className="ml-2  text-muted font-weight-light" style={{fontSize:"80%"}}>  {clientData[x]['serviceList'].replace(/,/g," / ")}</div>
          */}
                <div
                  className="ml-2  text-muted font-weight-light"
                  style={{ fontSize: "80%" }}
                >
                  {" "}
                  {sortData[y]["serviceList"].replace(/,/g, " / ")}
                </div>
              </div>
            </li>
          </Link>
        </>
      );

      clientList.push(singleClient);
    }
    setList(clientList);
  }

  useEffect(() => {
    let userID = sessionStorage.getItem("userID");
    makeLog(userID, "birthdayClients");
    makeList();
  }, []);

  return (
    <>
      <div className="p-2  my-2  rounded ">
        <div className="toast">
          <div className="toast-header">
            <h5>
              {nextMonth}-{nextTwoMoth}月生日客戶名單: {clientAmount} 筆
            </h5>
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="inputGroup-sizing-default">
                <img
                  src={searchIcon}
                  alt="searchIcon"
                  style={{ weight: "24px", height: "24px" }}
                />
                Search
              </span>
            </div>
            <input
              type="text"
              className="form-control"
              aria-label="Sizing example input"
              aria-describedby="inputGroup-sizing-default"
              onChange={handleChange}
            />
          </div>
          <div className="toast-body ">
            <ul className="list-group ">{listState}</ul>
          </div>
        </div>
      </div>
    </>
  );
};

//user使用軌跡
const UserActiveLog = (props) => {
  let param = useParams();
  let userID = param.userID;

  let token = sessionStorage.getItem("token");
  const [date, setDate] = useState(getDay(0, "-"));
  const [userLogState, setUserLog] = useState("");
  const [nameState, setNameState] = useState("");
  const [countState, setCount] = useState("本日無記錄");
  function handleDate(e) {
    setDate(e.target.value);
    console.log(date, e.target.value);

    Promise.all([getUserLog(e.target.value)]).then((values) => {
      json = JSON.parse(sessionStorage.getItem("tempUserLog"));
      console.log("here", json);
      setUserLog(logArray(json));
    });
  }

  function getUserLog(pickdate) {
    return new Promise((resolve, reject) => {
      fetch(
        `https://hitnow-v1.df.r.appspot.com/userActiveLog?token=${token}&userID=${userID}&date=${pickdate}`,
        {
          mode: "cors",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log("获取的结果", json);
          resolve("contact ok");
          sessionStorage.setItem("tempUserLog", JSON.stringify(json));
        });
    });
  }

  let json = "";

  function logArray(json) {
    let userLogData = [];
    for (let x in json) {
      let singleUserLog = (
        <li className="list-group-item text-muted mt-1" tag="button" key={x}>
          <div className="text-left" style={{ fontSize: "12px" }}>
            <span className=" p-1  badge  alert-info font-weight-light">
              {" "}
              {json[x]["createDate"].substring(10, 19)}{" "}
            </span>{" "}
            <span> - {json[x]["action"]} </span>
          </div>
        </li>
      );

      userLogData.push(singleUserLog);
      setNameState(json[x]["name"]);
    }
    setUserLog(userLogData);
    if (userLogData.length == 0) {
      setCount("本日無記錄");
    } else {
      setCount(`行為記錄：${userLogData.length}筆`);
    }
    return userLogData;
  }

  useEffect(() => {
    Promise.all([getUserLog(date)]).then((values) => {
      json = JSON.parse(sessionStorage.getItem("tempUserLog"));
      console.log("here", json);
      setUserLog(logArray(json));
    });
  }, []);
  return (
    <>
      <div class="card m-2 p-1">
        <div class="card-body text-center">
          <h5 class="card-title text-muted">{nameState} - 數位軌跡</h5>

          <div className="p-1 mb-3">
            查詢日期：
            <input
              type="date"
              class="board p-1"
              id="userLogDate"
              value={date}
              onChange={handleDate}
            />
          </div>
          <h6 className="text-muted ">{countState}</h6>
          <div> {userLogState}</div>
        </div>
      </div>
    </>
  );
};

//Login登入
const Login = () => {
  sessionStorage.clear();
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  function hanelePhoneChane(e) {
    setPhone(e.target.value);
  }

  function handlePassword(e) {
    setPassword(e.target.value);
  }

  function loginKitNow(e) {
    var formData = new FormData();
    formData.append("phone", phone);
    formData.append("pw", password);

    fetch(
      `https://hitnow-v1.df.r.appspot.com/login?phone=${phone}&pw=${password}`,
      {
        mode: "cors",
      }
    )
      .then((res) => {
        return res.json();
      })
      .then((json) => {
        console.log("获取的结果", json);
        makeLog(json.userID, "login");

        sessionStorage.setItem("name", json.name);
        sessionStorage.setItem("userID", json.userID);
        sessionStorage.setItem("title", json.title);
        sessionStorage.setItem("phone", json.phone);
        sessionStorage.setItem("unitID", json.unitID);
        sessionStorage.setItem("districtID", json.districtID);
        sessionStorage.setItem("level", json.level);
        sessionStorage.setItem("token", json.token);
        sessionStorage.setItem("unitManager", json.unitManager);
        sessionStorage.setItem("districtManager", json.districtManager);
        //sessionStorage.setItem('hitnowkey',json.hitnowkey);
        sessionStorage.setItem("unitName", json.unitName);
        let timeCode = new Date().getTime() + 1000 * 60 * 60;
        //let timeCode=time.setTime(time+1000*60*60)
        sessionStorage.setItem("timeCode", timeCode);
        if (json.userID == undefined) {
          setError("帳號/密碼不正確 請重新輸入");
          setPhone("");
          setPassword("");
        } else {
          window.location.href = "/init";
        }
      })
      .catch((err) => {
        console.log("请求错误", err);
        setError("帳號/密碼不正確 請重新輸入");
        setPhone("");
        setPassword("");
      });
  }

  return (
    <>
      <div role="alert" color="warning">
        <div style={{ textAlign: "center" }}>
          <h4 className="py-3">Happy In Touch ,Now</h4>
        </div>

        <hr />

        <div className="mt-3 card">
          <div className="card-body">
            <form>
              <div className="card mb-2">
                <div className="card-body alert-danger  border-1 p-2 text-center">
                  快樂麗康(股)公司
                  <br />
                  個人資料保護法維護公告
                </div>
                <div className="card-body border-1" style={{ width: "100%" }}>
                  顧客基於對品牌與店內夥伴的信任，於快樂麗康(股)公司旗下相關美髮品牌
                  〈HAPPYHAIR、GENIC、MPALACE〉所填寫之個人資料，僅供店內消費服務時使用，同時顧客的個資是受到個人資料保護法之保護，因此嚴禁夥伴在未經顧客同意下另做處理或利用，以免違反個人資料保護法。
                  <br />
                  您登入即代表同意本公告之內容，並同意善盡相關約定。
                </div>
              </div>
              <h5 className="card-title">設計師登入</h5>
              <div className="formGroup">
                <label>
                  Phone
                  <input
                    type="phone"
                    name="phone"
                    id="phone1"
                    className="form-control"
                    style={{ width: "100%" }}
                    value={phone}
                    onChange={hanelePhoneChane}
                  />
                  <small id="emailHelp" className="form-text text-muted">
                    請輸入手機門號
                  </small>
                </label>
              </div>
              <div className="formGroup">
                <label>
                  Password
                  <input
                    type="password"
                    name="password"
                    value={password}
                    style={{ width: "100%" }}
                    id="examplePassword"
                    className="form-control"
                    onChange={handlePassword}
                  />
                </label>
              </div>

              <br />
              <div className="formGroup ">
                <button
                  className="btn btn-danger"
                  type="button"
                  style={{ width: "100%" }}
                  onClick={loginKitNow}
                >
                  {" "}
                  本人同意個資保護，登入 HitNow
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="formGroup text-center">
          <h5>{error}</h5>
        </div>
      </div>
    </>
  );
};

const HeaderBar = (props) => {
  const version = "V.1.7.0 ";
  //設計師新客 watermelon

  const [name, setName] = useState("");
  useEffect(() => {
    setName(sessionStorage.getItem("name"));
  }, []);

  function handleClick(e) {
    window.location.href = "/init";
  }

  return (
    <nav className="navbar navbar-light  text-light  bg-dark">
      <img
        src={logo}
        alt="logo"
        style={{ weight: "35px", height: "35px" }}
        onClick={handleClick}
      />{" "}
      <h6>{version}</h6>
      <h5>{name}</h5>
    </nav>
  );
};

//功能列
const Navbar = () => {
  var imgStyle = {
    width: "30px",
    height: "30px",
  };

  var fontStyle = {
    fontSize: "0.5em",
  };

  //login
  let navbarLogin = (
    <nav className="navbar navbar-light text-light bg-dark ">
      <img src={logo} alt="logo" style={{ weight: "35px", height: "35px" }} />

      <h5>HitNow</h5>
    </nav>
  );
  //一般user
  let navbarContext = (
    <nav className="navbar navbar-light bg-dark ">
      <div>
        <Link className="navbar-brand" to="/" style={{ textAlign: "center" }}>
          <img src={HomeSVG} style={imgStyle} alt="Home" />
          <p className="text-light" style={fontStyle}>
            Home
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand"
          to="/clients"
          style={{ textAlign: "center" }}
        >
          <img src={PersonAdd} style={imgStyle} alt="PersonAdd" />
          <p className="text-light" style={fontStyle}>
            客戶
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand"
          to="/birthdayClients"
          style={{ textAlign: "center" }}
        >
          <img src={birthdayCake} style={{ width: "25px" }} alt="PersonAdd" />
          <p className="text-light" style={fontStyle}>
            生日
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand "
          style={{ textAlign: "center" }}
          to="/logout"
        >
          <img src={eject} style={imgStyle} alt="eject" />
          <p className="text-light" style={fontStyle}>
            登出
          </p>
        </Link>
      </div>
    </nav>
  );

  //admin
  let navbarContextAdmin = (
    <nav className="navbar navbar-light bg-dark ">
      <div>
        <Link className="navbar-brand" to="/" style={{ textAlign: "center" }}>
          <img src={HomeSVG} style={imgStyle} alt="Home" />
          <p className="text-light" style={fontStyle}>
            Home
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand"
          to="/clients"
          style={{ textAlign: "center" }}
        >
          <img src={PersonAdd} style={imgStyle} alt="PersonAdd" />
          <p className="text-light" style={fontStyle}>
            客戶
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand"
          to="/birthdayClients"
          style={{ textAlign: "center" }}
        >
          <img src={birthdayCake} style={{ width: "25px" }} alt="PersonAdd" />
          <p className="text-light" style={fontStyle}>
            生日
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand"
          to="/AdminMenu"
          style={{ textAlign: "center" }}
        >
          <img src={adminIcon} style={{ width: "25px" }} alt="PersonAdd" />
          <p className="text-light" style={fontStyle}>
            管理
          </p>
        </Link>
      </div>
      <div>
        <Link
          className="navbar-brand "
          style={{ textAlign: "center" }}
          to="/logout"
        >
          <img src={eject} style={imgStyle} alt="eject" />
          <p className="text-light" style={fontStyle}>
            登出
          </p>
        </Link>
      </div>
    </nav>
  );

  const [navContext, setNav] = useState(navbarLogin);
  useEffect(() => {
    let timeCodeNow = Date.now();
    let timeCode = sessionStorage.getItem("timeCode");
    let level = parseInt(sessionStorage.getItem("level"));
    //console.log("timecode:",timeCode)
    if (timeCode - timeCodeNow > 0) {
      //如果逾期則 登出

      if (level > 1) {
        setNav(navbarContextAdmin);
      } else {
        setNav(navbarContext);
      }
    }
  }, []);

  return navContext;
};

//新客定聯數
const NewClientHitCount = (props) => {
  let token = sessionStorage.getItem("token");
  let pickdate = getMonFirstDate();

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  //let pickdate=getMonFirstDate()

  const [countState, setCount] = useState("本日無記錄");

  let getUserHitCount = () => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://hitnow-v1.df.r.appspot.com/clientHitCount?token=${token}&date=${pickdate}&newClientTag=1`,
        {
          mode: "cors",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          sessionStorage.setItem("NewClientHitCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  };
  const [hitCount, setHitState] = useState("");
  useEffect(() => {
    let resultArray = [];
    getUserHitCount().then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("NewClientHitCount"));

      //整理排序 用店多排序
      data.sort((a, b) => {
        return a.unitID - b.unitID;
      });

      for (let x in data) {
        let singleCode = (
          <tr>
            <td>{data[x]["unitName"]}</td>
            <td>{data[x]["name"]} </td>
            <td>{data[x]["amount"]}</td>
          </tr>
        );

        resultArray.push(singleCode);
      }
      setHitState(resultArray);
    });
  }, []);

  return (
    <>
      <div class="card-body text-center">
        <h5 class="card-title text-muted"> 新客 HitNow 次數統計</h5>

        <div className="p-1 mb-3">查詢起始日期：{pickdate}</div>
        <table class="table">
          <thead>
            <tr>
              <th>分店</th>
              <th>姓名</th>
              <th>次數</th>
            </tr>
          </thead>
          <tbody>{hitCount}</tbody>
        </table>
      </div>
    </>
  );
};

//有效客定聯數
const ClientHitCount = (props) => {
  let token = sessionStorage.getItem("token");
  let pickdate = getMonFirstDate();

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  //let pickdate=getMonFirstDate()

  const [countState, setCount] = useState("本日無記錄");

  let getUserHitCount = () => {
    return new Promise((resolve, reject) => {
      fetch(
        `https://hitnow-v1.df.r.appspot.com/clientHitCount?token=${token}&date=${pickdate}`,
        {
          mode: "cors",
        }
      )
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          sessionStorage.setItem("ClientHitCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  };
  const [hitCount, setHitState] = useState("");
  useEffect(() => {
    let resultArray = [];
    getUserHitCount().then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("ClientHitCount"));

      //整理排序 用店多排序
      data.sort((a, b) => {
        return a.unitID - b.unitID;
      });

      for (let x in data) {
        let singleCode = (
          <tr>
            <td>{data[x]["unitName"]}</td>
            <td>{data[x]["name"]} </td>
            <td>{data[x]["amount"]}</td>
          </tr>
        );

        resultArray.push(singleCode);
      }
      setHitState(resultArray);
    });
  }, []);

  return (
    <>
      <div class="card-body text-center">
        <h5 class="card-title text-muted">有效客定聯數</h5>

        <div className="p-1 mb-3">新客120天內&一般客180天內</div>
        <table class="table">
          <thead>
            <tr>
              <th>分店</th>
              <th>姓名</th>
              <th>次數</th>
            </tr>
          </thead>
          <tbody>{hitCount}</tbody>
        </table>
      </div>
    </>
  );
};

//全區登入統計
const LoginCount = (props) => {
  let param = useParams();
  //let level=param.level
  //console.log("level:",level)
  //let level=sessionStorage.getItem('level')
  let unitID = sessionStorage.getItem("unitID");
  let districtID = sessionStorage.getItem("districtID");
  let token = sessionStorage.getItem("token");
  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  console.log("manageUnitListName", manageUnitListName);
  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  console.log("districtListName", districtListName);
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitList = new Set(manageUnit);
  console.log("unitList", unitList);
  //let districtManager=sessionStorage.getItem('districtManager').split(",") //管理分區清單
  //let districtManager=sessionStorage.getItem('serviceUnit').split(",") //管理分區清單
  let districtList = new Set(sessionStorage.getItem("districtList").split(","));

  let date = "";
  let endDate = "";

  if (props.date == "" || props.date == undefined) {
    date = getMonFirstDate();
  } else {
    date = props.date;
  }

  if (props.endDate == "" || props.endDate == undefined) {
    endDate = getDay(0, "-");
  } else {
    endDate = props.endDate;
  }

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }

  const [hitCount, setHitState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);

  const [unitListState, setUnitListState] = useState();
  const [districtListState, setDistrictListState] = useState();

  function getUserHitCountArg(toke, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/loginCount?token=${token}&date=${date}&endDate=${endDate}`;
      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          sessionStorage.setItem("LoginCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //更新查詢
  function hangdleSummit() {
    let date = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    arrangeResult(token, date, endDate);
  }

  //整理出最後畫面
  function arrangeResult(token, date, endDate) {
    let resultArray = [];
    let unitListForLevel = new Set(); //分店清單，快速選擇使用
    let districtListForLevel = new Set(); //分區清單，快速選擇使用
    getUserHitCountArg(token, date, endDate).then(() => {
      let data = JSON.parse(sessionStorage.getItem("LoginCount"));

      //整理排序 用店多排序
      data.sort((a, b) => {
        var nameA = a.unitName; // ignore upper and lowercase
        var nameB = b.unitName; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let url = `/footprint/${data[x]["userID"]}`;
        console.log("here");
        let singleCode = (
          <tr>
            <td>{data[x]["districtName"]}</td>
            <td>{data[x]["unitName"]}</td>
            <td>
              <a
                className="text-dark"
                style={{ textDdecoration: "none" }}
                href={url}
              >
                {data[x]["name"]}
              </a>{" "}
            </td>
            <td>{data[x]["amount"]}</td>
          </tr>
        );

        if (unitList.has(data[x]["unitID"])) {
          resultArray.push(singleCode);
          unitListForLevel.add(data[x]["unitName"]);
          districtListForLevel.add(data[x]["districtID"]);
        }
      }
      //處理分店清單
      let unitListArray = [];
      unitListArray.push(<option>全部</option>);
      //let array = Array.from(unitListForLevel)
      for (let x in manageUnitListName) {
        let singleUnit = <option>{x}</option>;
        unitListArray.push(singleUnit);
      }

      //處理分區清單

      let distictListArray = [];
      distictListArray.push(<option>全部</option>);
      //let districtNameArray = Array.from(districtListForLevel)
      //console.log("districtNameArray",districtNameArray)
      for (let x in districtListName) {
        let singleUnit = <option>{x}</option>;
        distictListArray.push(singleUnit);
      }

      setDistrictListState("");
      setDistrictListState(distictListArray);
      setUnitListState("");
      setUnitListState(unitListArray);

      setHitState(resultArray);
    });
  }

  function arrangeResultSelectUnitName(unitName) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("LoginCount"));

    data.sort((a, b) => {
      var nameA = a.unitName; // ignore upper and lowercase
      var nameB = b.unitName; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    for (let x in data) {
      let url = `/footprint/${data[x]["userID"]}`;
      let singleCode = (
        <tr>
          <td>{data[x]["districtName"]}</td>
          <td>{data[x]["unitName"]}</td>
          <td>
            <a
              className="text-dark"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}
            </a>{" "}
          </td>
          <td>{data[x]["amount"]}</td>
        </tr>
      );

      if (unitName == "全部") {
        resultArray.push(singleCode);
      } else if (data[x]["unitName"] == unitName) {
        resultArray.push(singleCode);
      }
    }

    setHitState(resultArray);
  }

  function arrangeResultSelectDistrict(districtID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("LoginCount"));

    data.sort((a, b) => {
      var nameA = a.unitName; // ignore upper and lowercase
      var nameB = b.unitName; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    for (let x in data) {
      let url = `/footprint/${data[x]["userID"]}`;
      let singleCode = (
        <tr>
          <td>{data[x]["districtName"]}</td>
          <td>{data[x]["unitName"]}</td>
          <td>
            <a
              className="text-dark"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}
            </a>{" "}
          </td>
          <td>{data[x]["amount"]}</td>
        </tr>
      );
      if (districtID == "全部" && unitList.has(data[x]["unitID"])) {
        resultArray.push(singleCode);
      } else if (
        data[x]["districtName"] == districtID &&
        unitList.has(data[x]["unitID"])
      ) {
        resultArray.push(singleCode);
      }
    }

    setHitState(resultArray);
  }

  function handleChangeUnit() {
    let selectUnitName = document.getElementById("unitNameSelect").value;
    arrangeResultSelectUnitName(selectUnitName);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    arrangeResultSelectDistrict(selectDistrict);
  }

  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
      <div className="card-body text-center">
        <h5 className="card-title text-muted">全區登入次數統計</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>
          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
            </a>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分區：
              </span>
            </div>
            <select
              class="form-control"
              id="districtSelect"
              onChange={handleChangeDistrict}
            >
              {districtListState}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分店：
              </span>
            </div>
            <select
              class="form-control"
              id="unitNameSelect"
              onChange={handleChangeUnit}
            >
              {unitListState}
            </select>
          </div>
        </div>

        <table className="table table-hover table-condensed table-striped">
          <thead>
            <tr>
              <th className="p-0 text-center">分區</th>
              <th className="p-0 text-center">分店</th>
              <th className="p-0 text-center">姓名</th>
              <th className="p-0 text-center">次數</th>
            </tr>
          </thead>
          <tbody>{hitCount}</tbody>
        </table>
      </div>
    </>
  );
};

//數位足跡
const FootPrint = (props) => {
  let token = sessionStorage.getItem("token");
  let param = useParams(); //取得userID
  let userID = param.userID;
  let nameTemp = param.name;
  console.log("nameTemp", nameTemp);
  let date = "";
  let endDate = "";
  console.log("date", props.date);

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  if (props.date == "" || props.date == undefined) {
    date = getMonFirstDate();
  } else {
    date = props.date;
  }

  if (props.endDate == "" || props.endDate == undefined) {
    endDate = getDay(0, "-");
  } else {
    endDate = props.endDate;
  }

  const [footprintState, setFootprintState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);
  const [nameState, setNameState] = useState("");

  function getFootprint(token, date, endDate, userID) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/footprint?token=${token}&date=${date}&endDate=${endDate}&userID=${userID}`;

      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          sessionStorage.setItem("footprint", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //更新查詢
  function hangdleSummit() {
    let date = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    console.log("start Date", date);
    let resultArray = [];
    getFootprint(token, date, endDate, userID).then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("footprint"));

      //整理排序 用店多排序

      data.sort((a, b) => {
        var nameA = a.createDate; // ignore upper and lowercase
        var nameB = b.createDate; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let singleCode = (
          <tr>
            <td>{data[x]["action"]}</td>
            <td>{data[x]["createDate"].substring(0, 19)} </td>
          </tr>
        );

        resultArray.push(singleCode);
      }
      setFootprintState(resultArray);
    });
  }
  useEffect(() => {
    let userName = "";
    let resultArray = [];
    getFootprint(token, date, endDate, userID).then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("footprint"));

      //整理排序 用店多排序

      data.sort((a, b) => {
        var nameA = a.createDate; // ignore upper and lowercase
        var nameB = b.createDate; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        userName = data[x]["name"];
        console.log("userName", userName, data[x]["name"]);
        let singleCode = (
          <tr>
            <td>{data[x]["action"]} </td>
            <td>{data[x]["createDate"].substring(0, 19)}</td>
          </tr>
        );

        resultArray.push(singleCode);
      }
      setFootprintState(resultArray);
      setNameState(userName);
    });
  }, []);

  return (
    <>
      <div className="card-body text-center">
        <h5 className="card-title text-muted">{nameState} 數位足跡</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
            </a>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>行為</th>
              <th>時間</th>
            </tr>
          </thead>
          <tbody>{footprintState}</tbody>
        </table>
      </div>
    </>
  );
};

//定聯種類統計
const ContactTypeCount = (props) => {
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitList = new Set(manageUnit);
  let token = sessionStorage.getItem("token");
  let level = sessionStorage.getItem("level");
  let unitID = sessionStorage.getItem("unitID");
  let districtID = sessionStorage.getItem("districtID");
  let date = "";
  let endDate = "";
  console.log("date", props.date);

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  if (props.date == "" || props.date == undefined) {
    date = getMonFirstDate();
  } else {
    date = props.date;
  }

  if (props.endDate == "" || props.endDate == undefined) {
    endDate = getDay(0, "-");
  } else {
    endDate = props.endDate;
  }

  const [countState, setcountState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);

  var roundDecimal = function (val, precision) {
    return (
      Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
      Math.pow(10, precision || 0)
    );
  };

  function getContactTypeCount(toke, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/contactTypeCount?token=${token}&date=${date}&endDate=${endDate}`;

      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          sessionStorage.setItem("ContactTypeCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //整理出畫面
  function arrangeResult(token, date, endDate) {
    console.log("start Date", date);
    let resultArray = [];
    getContactTypeCount(token, date, endDate).then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

      //整理排序 用店多排序

      data.sort((a, b) => {
        var nameA = a.contactType; // ignore upper and lowercase
        var nameB = b.contactType; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let singleCode = (
          <tr>
            <td>{data[x]["contactType"]} </td>
            <td>{data[x]["amount"]}</td>
            <td>{roundDecimal(data[x]["percent"], 2)}%</td>
          </tr>
        );

        resultArray.push(singleCode);
      }
      setcountState(resultArray);
    });
  }

  //更新查詢
  function hangdleSummit() {
    let date = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    arrangeResult(token, date, endDate);
  }
  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
      <div className="card-body text-center">
        <h5 className="card-title text-muted">HitNow類別統計</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
            </a>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>種類</th>
              <th>次數</th>
              <th>佔比</th>
            </tr>
          </thead>
          <tbody>{countState}</tbody>
        </table>
      </div>
    </>
  );
};

//定聯統計
const ContactAmountType = (props) => {
  let param = useParams();
  let level = param.level;

  let token = sessionStorage.getItem("token");

  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  console.log("manageUnit", manageUnit);

  let unitList = new Set(manageUnit);
  console.log(unitList);
  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  console.log("manageUnitListName", manageUnitListName);
  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  console.log("districtListName", districtListName);
  let districtList = sessionStorage.getItem("districtList").split(",");
  console.log(districtList);
  let date = "";
  let endDate = "";

  console.log("date", props.date);

  //csv
  const columns = [
    {
      id: "c0",
      displayName: "分區",
    },
    {
      id: "c1",
      displayName: "分店",
    },
    {
      id: "c2",
      displayName: "設計師",
    },
    {
      id: "c3",
      displayName: "HIT數",
    },
    {
      id: "c4",
      displayName: "新客",
    },
    {
      id: "c5",
      displayName: "有效",
    },
    {
      id: "c6",
      displayName: "日平均數",
    },
    {
      id: "c7",
      displayName: "應Hit數",
    },
  ];
  const [datas, setDatas] = useState();

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    } else {
      mon = m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  if (props.date == "" || props.date == undefined) {
    date = getMonFirstDate();
  } else {
    date = props.date;
  }

  if (props.endDate == "" || props.endDate == undefined) {
    endDate = getDay(0, "-");
  } else {
    endDate = props.endDate;
  }

  //let fileName=`${date}-${endDate}統計資料`
  //日期相減天數
  const DateDiff = function (sDate1, sDate2) {
    // sDate1 和 sDate2 是 2016-06-18 格式
    var oDate1 = new Date(sDate1);
    var oDate2 = new Date(sDate2);
    var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
    return iDays + 1;
  };

  const [countState, setcountState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);
  const [unitListState, setUnitListState] = useState();
  const [districtListState, setDistrictListState] = useState();

  var roundDecimal = function (val, precision) {
    return (
      Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
      Math.pow(10, precision || 0)
    );
  };

  function getContactTypeCount(token, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/contactAmountType?token=${token}&date=${date}&endDate=${endDate}`;

      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          
          return res.json();
        })
        .then((json) => {
          for (let x in json ){
            let result=Math.round(
              (parseInt(json[x]["hitAmount"], "10") /
                parseInt(json[x]["shouldAmount"], "10")) *
                100
            )
              // console.log(json[x]['name'],result)
            if (!result ){
              json[x]["result"]=0
            }else{
              json[x]["result"]=result
            }
          }
          sessionStorage.setItem("ContactTypeCount", JSON.stringify(json));
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //整理出最後畫面
  function arrangeResult(token, date, endDate) {
    let datasArray = [];
    let name = "";
    let resultArray = [];
    let unitListForLevel = new Set(); //分店清單，快速選擇使用
    let districtListForLevel = new Set(); //分區清單，快速選擇使用

    let totalDays = DateDiff(endDate, date);
    console.log(date, endDate, totalDays);
    getContactTypeCount(token, date, endDate).then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

      //整理排序 用分區排序

      data.sort((a, b) => {
        var nameA = a.name; // ignore upper and lowercase
        var nameB = b.name; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      data.sort((a, b) => {
        var nameA = a.unitName; // ignore upper and lowercase
        var nameB = b.unitName; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      data.sort((a, b) => {
        var nameA = a.result; // ignore upper and lowercase
        var nameB = b.result; // ignore upper and lowercase
        if (nameA < nameB) {
          return 1;
        }
        if (nameA > nameB) {
          return -1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let average = parseInt(data[x]["amount"], 10) / totalDays;
        // console.log(parseInt("here average", data[x]['amount'], 10))
        let url = `/singleUserHitList/${data[x]["userID"]}`;
        let tempDatas = {
          c0: data[x]["districtName"],
          c1: data[x]["unitName"],
          c2: data[x]["name"],
          c3: data[x]["amount"],
          c4: data[x]["newAmount"],
          c5: data[x]["normalAmount"],
          c6: parseFloat(average.toFixed(2)),
          c7: data[x]["shouldHitCount"],
        };
        datasArray.push(tempDatas);
        let singleCode = (
          <tr>
            <td className="p-1 text-center">{data[x]["districtName"]} </td>
            <td className="p-1 text-center">{data[x]["unitName"]} </td>
            <td className="p-1 text-center">
              <a
                className="text-info"
                style={{ textDdecoration: "none" }}
                href={url}
              >
                {data[x]["name"]}{" "}
              </a>
            </td>
            <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
            {/* <td>{data[x]['newAmount']}</td>
       <td>{data[x]['normalAmount']}</td> */}
            <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
            <td className="py-2 text-center">
            {data[x]["result"]}
              %
            </td>
          </tr>
        );

        // 加上 && data[x]['level']=="1" 避免管理者的hitnow數被統計
        if (
          data[x]["districtID"] !== "TOP" &&
          unitList.has(data[x]["unitID"])
        ) {
          resultArray.push(singleCode);
          unitListForLevel.add(data[x]["unitName"]);
          districtListForLevel.add(data[x]["districtID"]);
        }
      }

      //處理分店清單
      let unitListArray = [];
      unitListArray.push(<option>全部</option>);
      //let array = Array.from(unitListForLevel)
      for (let x in manageUnitListName) {
        let singleUnit = <option>{x}</option>;
        unitListArray.push(singleUnit);
      }

      //處理分區清單

      let distictListArray = [];
      distictListArray.push(<option>全部</option>);
      //let districtNameArray = Array.from(districtListForLevel)
      //console.log("districtNameArray",districtNameArray)
      for (let x in districtListName) {
        let singleUnit = <option>{x}</option>;
        distictListArray.push(singleUnit);
      }
      setcountState(resultArray);
      setDistrictListState("");
      setDistrictListState(distictListArray);
      setUnitListState("");
      setUnitListState(unitListArray);
      setDatas(datasArray);
    });
  }

  function arrangeResultSelectUnitName(unitName) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    let totalDays = DateDiff(endDateNew, dateNew);

    for (let x in data) {
      let url = `/singleUserHitList/${data[x]["userID"]}`;
      let average = parseInt(data[x]["amount"], 10) / totalDays;
      console.log(parseInt("here average", data[x]["amount"], 10));

      let singleCode = (
        <tr>
          <td className="p-1 text-center">{data[x]["districtName"]} </td>
          <td className="p-1 text-center">{data[x]["unitName"]} </td>
          <td className="p-1 text-center">
            <a
              className="text-info"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}{" "}
            </a>
          </td>
          <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
          {/* <td>{data[x]['newAmount']}</td>
 <td>{data[x]['normalAmount']}</td> */}
          <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
          <td className="py-2 text-center">
          {data[x]["result"]}
            %
          </td>
        </tr>
      );

      if (unitName == "全部") {
        resultArray.push(singleCode);
      } else if (data[x]["unitName"] == unitName) {
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  function arrangeResultSelectDistrict(districtID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCount"));

    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    let totalDays = DateDiff(endDateNew, dateNew);
    console.log(date, endDate, totalDays);

    for (let x in data) {
      let url = `/singleUserHitList/${data[x]["userID"]}`;
      let average = parseInt(data[x]["amount"], 10) / totalDays;
      console.log(parseInt("here average", data[x]["amount"], 10));
      let singleCode = (
        <tr>
          <td className="p-1 text-center">{data[x]["districtName"]} </td>
          <td className="p-1 text-center">{data[x]["unitName"]} </td>
          <td className="p-1 text-center">
            <a
              className="text-info"
              style={{ textDdecoration: "none" }}
              href={url}
            >
              {data[x]["name"]}{" "}
            </a>
          </td>
          <td className="py-2 text-center">{data[x]["hitAmount"]}</td>
          {/* <td>{data[x]['newAmount']}</td>
 <td>{data[x]['normalAmount']}</td> */}
          <td className="py-2 text-center">{data[x]["shouldAmount"]}</td>
          <td className="py-2 text-center">
          {data[x]["result"]}
            %
          </td>
        </tr>
      );
      if (districtID == "全部" && unitList.has(data[x]["unitID"])) {
        resultArray.push(singleCode);
      } else if (
        data[x]["districtName"] == districtID &&
        unitList.has(data[x]["unitID"])
      ) {
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  function handleChangeUnit() {
    let selectUnitName = document.getElementById("unitNameSelect").value;
    arrangeResultSelectUnitName(selectUnitName);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    arrangeResultSelectDistrict(selectDistrict);
  }
  //更新查詢
  function hangdleSummit() {
    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    //let msg="dateNew: "+dateNew+ "endDateNew: "+endDateNew
    //alert(msg)
    arrangeResult(token, dateNew, endDateNew);
  }

  const DonwloadCSV = (props) => {
    let fileName = `${dateStateStart}-${dateStateEnd}統計資料`;
    if (props.level == 4) {
      return (
        <>
          <div className="btn">
            <CsvDownloader
              className="btn btn-info"
              filename={fileName}
              separator=","
              wrapColumnChar=""
              columns={columns}
              datas={datas}
              text="統計資料下載"
            />
          </div>
        </>
      );
    } else {
      return null;
    }
  };

  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
        <div className="text-center">
      <h5 className="card-title text-muted text-center">HitNow數統計</h5>
      <div className=" p-3">
        <div className="input-group mb-3 ">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              起始：
            </span>
          </div>
          <input
            type="date"
            className="form-control"
            id="startDateValue"
            placeholder="Username"
            value={dateStateStart}
            onChange={handleChangeStart}
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>

        <div className="input-group mb-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              終止：
            </span>
          </div>
          <input
            type="date"
            className="form-control"
            id="endDateValue"
            placeholder="Username"
            value={dateStateEnd}
            onChange={handleChangeEnd}
            aria-label="Username"
            aria-describedby="basic-addon1"
          />
        </div>

        <div>
          <a
            href="#"
            class="btn btn-danger "
            style={{ width: "100%", maxWidth: "300px" }}
            onClick={hangdleSummit}
          >
            查詢
          </a>
        </div>

        <div className="input-group mb-3 mt-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              分區：
            </span>
          </div>
          <select
            class="form-control"
            id="districtSelect"
            onChange={handleChangeDistrict}
          >
            {districtListState}
          </select>
        </div>

        <div className="input-group mb-3 mt-3">
          <div className="input-group-prepend">
            <span className="input-group-text" id="basic-addon1">
              分店：
            </span>
          </div>
          <select
            class="form-control"
            id="unitNameSelect"
            onChange={handleChangeUnit}
          >
            {unitListState}
          </select>
        </div>
        <DonwloadCSV level={level} />
      </div>

      <table className="table table-hover table-condensed table-striped">
        <thead clasName="py-3">
          <tr>
            <th className="p-0 text-center">分區</th>
            <th className="p-0 text-center">分店</th>
            <th className="p-0 text-center">姓名</th>
            <th className="p-0 text-center">HIT</th>
            <th className="p-0 text-center">應Hit</th>
            <th className="p-0 text-center">達成率</th>
          </tr>
        </thead>
        <tbody>{countState}</tbody>
      </table>

      </div>
    </>
  );
};

const ContactTypeCountDetail = (props) => {
  let param = useParams();
  let level = param.level;
  console.log("level.", level);
  let token = sessionStorage.getItem("token");
  //let level=sessionStorage.getItem('level')
  let unitID = sessionStorage.getItem("unitID");
  let districtID = sessionStorage.getItem("districtID");
  //let unitManager=sessionStorage.getItem('unitManager').split(",") //管理分店清單
  let manageUnit = sessionStorage.getItem("manageUnit").split(","); //管理分店清單
  let unitList = new Set(manageUnit);
  console.log("unitList", unitList);
  let manageUnitListName = JSON.parse(
    sessionStorage.getItem("manageUnitListName")
  );
  console.log("manageUnitListName", manageUnitListName);
  let districtListName = JSON.parse(sessionStorage.getItem("districtListName"));
  console.log("districtListName", districtListName);
  let districtList = sessionStorage.getItem("districtList").split(",");
  console.log("districtList", districtList);
  let date = "";
  let endDate = "";
  console.log("date", props.date);

  function getMonFirstDate() {
    //計算本日第一天
    let today = new Date();
    let m = (today.getMonth() + 1).toString();
    let mon = "";
    let year = today.getFullYear().toString();

    if (m.length == 1) {
      mon = "0" + m;
    } else {
      mon = m;
    }
    let result = year + "-" + mon + "-01";
    //console.log(result)
    return result;
  }
  if (props.date == "" || props.date == undefined) {
    date = getMonFirstDate();
  } else {
    date = props.date;
  }

  if (props.endDate == "" || props.endDate == undefined) {
    endDate = getDay(0, "-");
  } else {
    endDate = props.endDate;
  }

  const DateDiff = function (sDate1, sDate2) {
    // sDate1 和 sDate2 是 2016-06-18 格式
    var oDate1 = new Date(sDate1);
    var oDate2 = new Date(sDate2);
    var iDays = parseInt(Math.abs(oDate1 - oDate2) / 1000 / 60 / 60 / 24); // 把相差的毫秒數轉換為天數
    return iDays + 1;
  };

  const [countState, setcountState] = useState("");
  const [dateStateStart, setDateStart] = useState(date);
  const [dateStateEnd, setDateEnd] = useState(endDate);
  const [districtListState, setDistrictListState] = useState();
  const [unitListState, setUnitListState] = useState();

  var roundDecimal = function (val, precision) {
    return (
      Math.round(Math.round(val * Math.pow(10, (precision || 0) + 1)) / 10) /
      Math.pow(10, precision || 0)
    );
  };

  function getContactTypeCount(token, date, endDate) {
    return new Promise((resolve, reject) => {
      let cmd = `${url}/contactTypeCountDetail?token=${token}&date=${date}&endDate=${endDate}`;

      console.log("cmd", cmd);
      fetch(cmd, {
        mode: "cors",
      })
        .then((res) => {
          return res.json();
        })
        .then((json) => {
          console.log(json);
          sessionStorage.setItem(
            "ContactTypeCountDetail",
            JSON.stringify(json)
          );
          resolve("contact ok");
          //返回新客定聯數統計
        });
    });
  }

  //開始日期
  function handleChangeStart(e) {
    setDateStart(e.target.value);
    console.log("change:", dateStateStart);
    console.log("changeTarget:", e.target.value);
  }

  //結束日期
  function handleChangeEnd(e) {
    setDateEnd(e.target.value);
    console.log("change:", dateStateEnd);
    console.log("changeTarget:", e.target.value);
  }

  //更新查詢
  function hangdleSummit() {
    let date = document.getElementById("startDateValue").value;
    let endDate = document.getElementById("endDateValue").value;
    arrangeResult(token, date, endDate);
  }

  function arrangeResult(token, date, endDate) {
    let resultArray = [];
    getContactTypeCount(token, date, endDate).then(() => {
      console.log("yes");
      let data = JSON.parse(sessionStorage.getItem("ContactTypeCountDetail"));

      //整理排序 用店多排序

      data.sort((a, b) => {
        var nameA = a.unitName; // ignore upper and lowercase
        var nameB = b.unitName; // ignore upper and lowercase
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }

        // names must be equal
        return 0;
      });

      for (let x in data) {
        let singleCode = (
          <tr>
            <td>{data[x]["unitName"]} </td>
            <td>{data[x]["line"]}</td>
            <td>{data[x]["linePercentage"].substring(0, 4)}%</td>
            <td>{data[x]["sms"]}</td>
            <td>{data[x]["smsPercentage"].substring(0, 4)}%</td>
            <td>{data[x]["phone"]}</td>
            <td>{data[x]["phonePercentage"].substring(0, 4)}%</td>
          </tr>
        );

        if (unitList.has(data[x]["unitID"])) {
          resultArray.push(singleCode);
        }
      }
      //處理分店清單
      let unitListArray = [];
      unitListArray.push(<option>全部</option>);
      //let array = Array.from(unitListForLevel)
      for (let x in manageUnitListName) {
        let singleUnit = <option>{x}</option>;
        unitListArray.push(singleUnit);
      }

      //處理分區清單

      let distictListArray = [];
      distictListArray.push(<option>全部</option>);
      //let districtNameArray = Array.from(districtListForLevel)
      //console.log("districtNameArray",districtNameArray)
      for (let x in districtListName) {
        let singleUnit = <option>{x}</option>;
        distictListArray.push(singleUnit);
      }
      setcountState(resultArray);
      setDistrictListState("");
      setDistrictListState(distictListArray);
      setUnitListState("");
      setUnitListState(unitListArray);
      setcountState(resultArray);
    });
  }

  function handleChangeUnit() {
    let selectUnitName = document.getElementById("unitNameSelect").value;
    arrangeResultSelectUnitName(selectUnitName);
  }

  function handleChangeDistrict() {
    let selectDistrict = document.getElementById("districtSelect").value;
    arrangeResultSelectDistrict(selectDistrict);
  }

  function arrangeResultSelectDistrict(districtID) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCountDetail"));
    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    let totalDays = DateDiff(endDateNew, dateNew);
    console.log(date, endDate, totalDays);

    for (let x in data) {
      let singleCode = (
        <tr>
          <td>{data[x]["unitName"]} </td>
          <td>{data[x]["line"]}</td>
          <td>{data[x]["linePercentage"].substring(0, 4)}%</td>
          <td>{data[x]["sms"]}</td>
          <td>{data[x]["smsPercentage"].substring(0, 4)}%</td>
          <td>{data[x]["phone"]}</td>
          <td>{data[x]["phonePercentage"].substring(0, 4)}%</td>
        </tr>
      );

      if (districtID == "全部" && unitList.has(data[x]["unitID"])) {
        resultArray.push(singleCode);
      } else if (
        data[x]["districtName"] == districtID &&
        unitList.has(data[x]["unitID"])
      ) {
        console.log("here");
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  function arrangeResultSelectUnitName(unitName) {
    let resultArray = [];
    let data = JSON.parse(sessionStorage.getItem("ContactTypeCountDetail"));

    data.sort((a, b) => {
      var nameA = a.amount; // ignore upper and lowercase
      var nameB = b.amount; // ignore upper and lowercase
      if (nameA > nameB) {
        return -1;
      }
      if (nameA < nameB) {
        return 1;
      }
      return 0;
    });

    data.sort((a, b) => {
      var nameA = a.districtID; // ignore upper and lowercase
      var nameB = b.districtID; // ignore upper and lowercase
      if (nameA < nameB) {
        return -1;
      }
      if (nameA > nameB) {
        return 1;
      }
      return 0;
    });

    let dateNew = document.getElementById("startDateValue").value;
    let endDateNew = document.getElementById("endDateValue").value;
    let totalDays = DateDiff(endDateNew, dateNew);
    console.log(date, endDate, totalDays);

    for (let x in data) {
      let singleCode = (
        <tr>
          <td>{data[x]["unitName"]} </td>
          <td>{data[x]["line"]}</td>
          <td>{data[x]["linePercentage"].substring(0, 4)}%</td>
          <td>{data[x]["sms"]}</td>
          <td>{data[x]["smsPercentage"].substring(0, 4)}%</td>
          <td>{data[x]["phone"]}</td>
          <td>{data[x]["phonePercentage"].substring(0, 4)}%</td>
        </tr>
      );

      if (districtID == "全部") {
        resultArray.push(singleCode);
      } else if (data[x]["unitName"] == unitName) {
        resultArray.push(singleCode);
      }
    }

    setcountState(resultArray);
  }

  useEffect(() => {
    arrangeResult(token, date, endDate);
  }, []);

  return (
    <>
      <div className="card-body text-center">
        <h5 className="card-title text-muted">HitNow類別統計</h5>
        <div className=" p-3">
          <div className="input-group mb-3 ">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                起始：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="startDateValue"
              placeholder="Username"
              value={dateStateStart}
              onChange={handleChangeStart}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div className="input-group mb-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                終止：
              </span>
            </div>
            <input
              type="date"
              className="form-control"
              id="endDateValue"
              placeholder="Username"
              value={dateStateEnd}
              onChange={handleChangeEnd}
              aria-label="Username"
              aria-describedby="basic-addon1"
            />
          </div>

          <div>
            <a
              href="#"
              class="btn btn-danger "
              style={{ width: "100%", maxWidth: "300px" }}
              onClick={hangdleSummit}
            >
              查詢
            </a>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分區：
              </span>
            </div>
            <select
              class="form-control"
              id="districtSelect"
              onChange={handleChangeDistrict}
            >
              {districtListState}
            </select>
          </div>

          <div className="input-group mb-3 mt-3">
            <div className="input-group-prepend">
              <span className="input-group-text" id="basic-addon1">
                分店：
              </span>
            </div>
            <select
              class="form-control"
              id="unitNameSelect"
              onChange={handleChangeUnit}
            >
              {unitListState}
            </select>
          </div>
        </div>

        <table className="table">
          <thead>
            <tr>
              <th>分店</th>
              <th>Line</th>
              <th>佔比</th>
              <th>SMS</th>
              <th>佔比</th>
              <th>Phone</th>
              <th>佔比</th>
            </tr>
          </thead>
          <tbody>{countState}</tbody>
        </table>
      </div>
    </>
  );
};

export {
  Home,
  HeaderBar,
  Navbar,
  Login,
  Clients,
  BirthdayClients,
  UserActiveLog,
  NewClientHitCount,
  ClientHitCount,
  LoginCount,
  FootPrint,
  ContactTypeCount,
  ContactAmountType,
  ContactTypeCountDetail,
};
